import { useState, useEffect, useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useTheme,
  Grid,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../../CalendarStyles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EventDetailsDialog from "../dashboard/EventDetailsDialog";

const GlobalCalendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([]);

  const API_URL = process.env.REACT_APP_API_URL;

  // ref to store the calendar instance
  const calendarRef = useRef(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/calander-event/get-event-for-userId/${Cookies.get(
          "userId"
        )}`,
        { withCredentials: true }
      );
      const eventDTOs = response.data;

      setAllEvents(eventDTOs);
      // addEventsToCalendar(eventDTOs);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to view calander events. Please contact Admin for help.");
        }
      }
      // Handle error appropriately
    }
  };

  const handleEventClick = (clickInfo) => {
    console.log(clickInfo.event.extendedProps.projectId);

    setSelectedEvent({
      title: clickInfo.event.title,
      start: clickInfo.event.startStr,
      end: clickInfo.event.endStr,
      allDay: clickInfo.event.allDay,
      projectId: clickInfo.event.extendedProps.projectId,
    });
  };

  const handleCloseDialog = () => {
    setSelectedEvent(null);
  };

  const addEventsToCalendar = (eventDTOs) => {
    const calendarApi = calendarRef.current.getApi(); // Access the calendar API

    eventDTOs.forEach((eventDTO) => {
      calendarApi.addEvent({
        id: eventDTO.eventId,
        title: eventDTO.title,
        start: eventDTO.start,
        end: eventDTO.end,
        allDay: eventDTO.allDay,
      });
    });
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className="custom-event" title={eventInfo.event.title}>
        <strong>{eventInfo.timeText}</strong>
        <span>{eventInfo.event.title}</span>
      </div>
    );
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Calendar" subtitle="Employee Calendar" />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {/* CALENDAR */}
          <Box flex="1 1 100%" ml="15px">
            <FullCalendar
              ref={calendarRef}
              timeZone="utc"
              height="75vh"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,listMonth",
              }}
              initialView="dayGridMonth"
              editable={false}
              // selectable={true}
              selectMirror={true}
              events={allEvents}
              // eventContent={renderEventContent}
              dayMaxEvents={true}
              // select={handleDateClick}
              eventClick={handleEventClick}
              eventsSet={(events) => setCurrentEvents(events)}
            />
          </Box>
        </Grid>
      </Grid>
      {selectedEvent && (
        <EventDetailsDialog
          selectedEvent={selectedEvent}
          setSelectedEvent={handleCloseDialog}
          // clientUserId = clientUserId
        />
      )}
    </Box>
  );
};

export default GlobalCalendar;
