

const ACCESS_CATEGORIES = {
    DOCUMENT_SHARING: 'document_sharing',
    CALLENDER: 'callender',
    INTERNAL_NOTES: 'internal_notes',
    CLIENT_MESSAGES: 'client_messages',
    INTERNAL_MESSAGES: 'internal_messages',
    PROJECTS: 'projects',
    INVOICES: 'invoices',
    PROJECT_UPDATES: 'project_updates',
    SELECTIONS: 'selections',
    CLIENT_TO_DOS: 'client_to_dos',
    CLIENTS: 'clients'
  };
  
  export default ACCESS_CATEGORIES;