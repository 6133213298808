import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  styled,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";

const Input = styled("input")({
  display: "none",
});

function LogoUpload({ label, id, businessId, logoType, fileInfoDTO, logoId }) {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(fileInfoDTO?.fileUrl || "");

  const [open, setOpen] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleChange = async (event) => {
    const newFile = event.target.files[0];
    if (newFile && newFile.type.startsWith("image/")) {
      setFile(newFile);
      setPreviewUrl(URL.createObjectURL(newFile));
      await uploadFile(newFile);
    }
  };

  const uploadFile = async (selectedFile) => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    const logoDTO = {
      businessId: businessId,
      logoType: logoType,
    };

    formData.append("logoDTO", JSON.stringify(logoDTO));

    try {
      const response = await axios.post(`${API_URL}/logo/upload`, formData, {
        withCredentials: true,
        withXSRFToken: true,
        headers: {},
      });

      if (response.status === 200) {
        console.log("Upload successful:", response.data);
      } else {
        throw new Error(`Upload failed with status: ${response.status}`);
      }
    } catch (error) {
      setFile(null);
      setPreviewUrl("");
      console.log(error);
      if (error.response) {
        if (error.response.status === 500 && error.response.data.message === "Maximum upload size exceeded") {
          alert("Upload failed: The file size exceeds the maximum limit of 10MB. Please select a smaller file and try again.");
        } else if(error.response.status === 413) {
          alert("Upload failed: The file size exceeds the maximum limit of 10MB. Please select a smaller file and try again.");
        } else if (error.response.status === 409){
          alert(error.response.data.message);
        } else {
          alert(error.response.data.message);
          console.error("An unexpected error occurred while uploading this logo:  ", error);
        } 
      } else {
        alert(error.response.data.message);
        console.error("An unexpected error occurred while uploading this logo:  ", error);
      }
    }
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    if (!logoId) {
      console.error("Logo ID is missing");
      return;
    }

    try {
      const response = await axios.delete(`${API_URL}/logo/delete/${logoId}`, {
        withCredentials: true,
        withXSRFToken: true,
      });

      if (response.status === 200) {
        console.log("Delete successful");
        // Handle any additional state updates or UI changes post deletion
        setFile(null);
        setPreviewUrl("");
        setOpen(false);
      } else {
        throw new Error(`Delete failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const inputId = `icon-button-file-${id}`;

  // Check if previewUrl is a blob URL or a server URL
  const isBlobUrl = previewUrl.startsWith("blob:");

  // Use the URL as-is if it's a blob URL, otherwise prefix it with API_URL
  const imageUrl = isBlobUrl ? previewUrl : `${API_URL}/${previewUrl}`;

  return (
    <Grid item xs={12} md={6}>
      <Box
        p={2}
        boxShadow={5}
        m="20px"
        style={{ border: "1px solid #000000", borderRadius: "10px" }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          style={{ marginBottom: "21px" }}
        >
          {label}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {previewUrl ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "400px",
                  width: "400px",
                  marginBottom: "10px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Preview"
                  style={{
                    maxHeight: "70%",
                    maxWidth: "70%",
                  }}
                />
              </Box>

              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleClickOpen}
              >
                Delete Image
              </Button>
            </>
          ) : (
            <label htmlFor={inputId}>
              <Input
                accept="image/*"
                id={inputId}
                type="file"
                onChange={handleChange}
              />
              <Paper
                variant="outlined"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 2,
                  cursor: "pointer",
                  height: { xs: "200px", sm: "300px", md: "400px" },
                  width: { xs: "200px", sm: "300px", md: "400px" },
                  marginBottom: "10px",
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PhotoCamera fontSize="large" />
                </IconButton>
              </Paper>
            </label>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this image?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDelete} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Grid>
  );
}

export default LogoUpload;
