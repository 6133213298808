import { useState, useEffect, useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useTheme,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../../CalendarStyles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { clientUserId } = useParams();
  const [project, setProject] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  // ref to store the calendar instance
  const calendarRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [selectedDate, setSelectedDate] = useState({
    startStr: "",
    endStr: "",
  });

  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState("");

  const [newEventStartTime, setNewEventStartTime] = useState("");
  const [newEventEndTime, setNewEventEndTime] = useState("");
  const [isAllDayEvent, setIsAllDayEvent] = useState(true);

  const [showTimeInputs, setShowTimeInputs] = useState(false);
  const [allEvents, setAllEvents] = useState([]);

  const [isCalendarInitialized, setIsCalendarInitialized] = useState(false);


  useEffect(() => {
    if (isCalendarInitialized) {
      fetchEvents();
    }
  }, [isCalendarInitialized]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getInitialView = () => {
    return windowWidth < 600 ? "listWeek" : "dayGridMonth";
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/calander-event/get-event-for-projectId/${projectId}`,
        { withCredentials: true }
      );
      const eventDTOs = response.data;
      //setAllEvents(eventDTOs);
      addEventsToCalendar(eventDTOs);
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to view calander events. Please contact Admin for help.");
        }
      }
      // Handle error appropriately
    }
  };

  // Fetch projec tdata
  const fetchProjectData = async () => {
    try {
      const response = await fetch(
        `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );
      console.log(response.data);

      const data = await response.json();
      setProject(data);
    } catch (err) {
      console.error(`Error fetching data for userId ${projectId}:`, err);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [projectId]);

  const addEventsToCalendar = (eventDTOs) => {
    const calendarApi = calendarRef.current.getApi(); // Access the calendar API
    calendarApi.removeAllEvents(); // Clear existing events
    console.log(
      "Current FullCalendar timeZone:",
      calendarRef.current.getApi().getOption("timeZone")
    );

    eventDTOs.forEach((eventDTO) => {
      console.log("Adding event:", eventDTO);

      calendarApi.addEvent({
        id: eventDTO.eventId,
        title: eventDTO.title,
        start: eventDTO.start,
        end: eventDTO.end,
        allDay: eventDTO.allDay,
      });
    });
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className="custom-event" title={eventInfo.event.title}>
        <strong>{eventInfo.timeText}</strong>
        <span>{eventInfo.event.title}</span>

      </div>
    );
  };

  const handleDateClick = async (selected) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CALLENDER)){
      alert("You are not authorized to create Calander Events. Please contact Admin for help.");
      return;
    }

    setSelectedDate(selected);
    setNewEventStartTime(selected.startStr);
    setNewEventEndTime(selected.endStr);
    setShowTimeInputs(!selected.allDay);
    setIsEventDialogOpen(true);
    console.log(selected);
  };

  const handleEventCreation = async () => {
    const calendarApi = selectedDate.view.calendar;
    calendarApi.unselect();

    let event;

    if (newEventTitle) {
      const isAllDay = !showTimeInputs;

      const event = {
        title: newEventTitle,
        start: isAllDay
          ? formatDateToISO(selectedDate.startStr)
          : `${newEventStartTime}:00Z`,
        end: isAllDay
          ? formatDateToISO(selectedDate.endStr)
          : `${newEventEndTime}:00Z`,
        allDay: isAllDay,
        employeeId: Cookies.get("userId"),
        projectId: projectId,
        businessId: Cookies.get("businessId"),
      };

      calendarApi.addEvent({
        id: `${selectedDate.startStr}-${newEventTitle}`,
        title: newEventTitle,
        start: event.start,
        end: event.end,
        allDay: event.allDay,
      });

      try {
        // Post the form data to the backend to create a new document
        await axios.post(`${API_URL}/calander-event/create`, event, {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "Content-Type": "application/json",
          },
        });
      } catch (error) {
        console.error("Error creating event:", error);
        if (error.response) {
          if (error.response.status === 403){
            alert("You are not authorized to create calander events. Please contact Admin for help.");
          }
        }
      }
    }

    setIsEventDialogOpen(false);
    setNewEventTitle("");
    setNewEventStartTime("");
    setNewEventEndTime("");
    setShowTimeInputs(false);
  };

  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString(); // Adjust the format as needed
  };

  const formatDateToISO = (dateStr) => {
    return `${dateStr}T12:00:00Z`; // Append 'T00:00:00Z' to make it an ISO 8601 format
  };

  const adjustEndDateForDisplay = (startStr, endStr, allDay) => {
    const startDate = new Date(startStr);
    const endDate = new Date(endStr);

    if (allDay) {
      // Subtract one day from the end date
      endDate.setDate(endDate.getDate() - 1);
    }

    return endDate.toISOString().split("T")[0];
  };

  // Navigate back to project page
  const navigateBack = () => {
    navigate(`/users/${clientUserId}/${projectId}`);
  };

  const handleEventClick = async (selected) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CALLENDER)){
      alert("You are not authorized to delete Calander Events. Please contact Admin for help.");
      return;
    }

    if (
      window.confirm(
        `Are you sure you want to delete the event '${selected.event.title}'`
      )
    ) {
      try {
        const respose = await axios.delete(
          `${API_URL}/calander-event/delete/${selected.event.id}`,
          {
            withCredentials: true,
            withXSRFToken: true,
          }
        );

        selected.event.remove();
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403){
            alert("You are not authorized to delete calander events. Please contact Admin for help.");
          }
        } else{
          window.confirm("Error deleting event:", error);
        }
      }
    }
  };

  const navigateTo = (path) => () => {
    console.log("Navigating to:", path); // Debugging log
    navigate(path);
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
          {/* Clickable navigation links */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}`)}
          >
            Projects
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}/${projectId}`)}
          >
            {project.address}
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          <Typography variant="h6" component="span" fontWeight="bold">
            Calendar
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {/* CALENDAR */}
          <Box flex="1 1 100%" m="20px">
            <FullCalendar
              ref={calendarRef}
              timeZone="utc"
              height="75vh"
              // timeZone="local"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,listMonth",
              }}
              initialView="dayGridMonth"
              editable={false}
              selectable={true}
              selectMirror={true}
              datesSet={() => setIsCalendarInitialized(true)} // Set calendar initialization state
              //events={allEvents}
              eventContent={renderEventContent}
              dayMaxEvents={true}
              select={handleDateClick}
              eventClick={handleEventClick}
              eventsSet={(events) => setCurrentEvents(events)}
              longPressDelay={20} // lower value for quicker response
              eventLongPressDelay={20}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Event Creation Dialog */}
      <Dialog
        open={isEventDialogOpen}
        onClose={() => setIsEventDialogOpen(false)}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          {selectedDate && selectedDate.startStr && (
            <Typography variant="body1" gutterBottom>
              <strong>Selected Start Date:</strong> {selectedDate.startStr}
            </Typography>
          )}

          {selectedDate && selectedDate.endStr && (
            <Typography variant="body1" gutterBottom>
              <strong> Selected End Date: </strong>{" "}
              {adjustEndDateForDisplay(
                selectedDate.startStr,
                selectedDate.endStr,
                selectedDate.allDay
              )}
            </Typography>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Event Title"
            type="text"
            fullWidth
            value={newEventTitle}
            onChange={(e) => setNewEventTitle(e.target.value)}
            sx={{ input: { fontSize: "16px" } }}
          />
          {showTimeInputs && (
            <>
              <TextField
                margin="dense"
                label="Start Time"
                type="datetime-local"
                fullWidth
                value={newEventStartTime}
                onChange={(e) => setNewEventStartTime(e.target.value)}
                sx={{ input: { fontSize: "16px" } }}
              />
              <TextField
                margin="dense"
                label="End Time"
                type="datetime-local"
                fullWidth
                value={newEventEndTime}
                onChange={(e) => setNewEventEndTime(e.target.value)}
                sx={{ input: { fontSize: "16px" } }}
              />
            </>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={showTimeInputs}
                onChange={() => setShowTimeInputs(!showTimeInputs)}
              />
            }
            label="Add Time"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEventDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleEventCreation}>Create Event</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Calendar;
