import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import CancelIcon from "@mui/icons-material/Cancel";
import Cookies from "js-cookie";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function ConversationDialogBox({
  open,
  handleClose,
  handleConfirm,
  employees,
  selectedEmployees,
  setSelectedEmployees,
  conversationName,
  setConversationName,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const currentUserId = Cookies.get("userId");
  const canConfirm =
    conversationName.trim() !== "" && selectedEmployees.length > 0;

  const handleConfirmClick = () => {
    if (canConfirm) {
      handleConfirm(conversationName, selectedEmployees);
      handleClose();
    } else {
      alert(
        "Please enter a conversation name and select at least one employee."
      );
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            return; // Ignore closes that aren't explicit
          }
          handleClose();
        }}
        fullScreen={isMobile} // Use full screen for mobile devices
      >
        <DialogTitle>Add new conversation?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="conversationName"
            label="Conversation Name"
            type="text"
            fullWidth
            variant="outlined"
            value={conversationName}
            onChange={(e) => setConversationName(e.target.value)}
            required
            sx={{ m: 1, width: isMobile ? "100%" : 500 }}
          />
          <FormControl
            sx={{ m: 1, width: isMobile ? "100%" : 500, zIndex: 10 }}
          >
            {" "}
            <InputLabel id="select-employee-label">Select Employees</InputLabel>
            <Select
              multiple
              value={selectedEmployees}
              onChange={(e) => setSelectedEmployees(e.target.value)}
              input={<OutlinedInput label="Select Employees" />}
              renderValue={(selected) => (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.map((value) => (
                    <Chip
                      key={value.userId}
                      label={`${value.firstName} ${value.lastName}`}
                      onDelete={() =>
                        setSelectedEmployees(
                          selectedEmployees.filter(
                            (item) => item.userId !== value.userId
                          )
                        )
                      }
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}
                </Stack>
              )}
            >
              {employees
                .filter((emp) => emp.userId !== currentUserId)
                .map((employee) => (
                  <MenuItem key={employee.userId} value={employee}>
                    {`${employee.firstName} ${employee.lastName}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <DialogContentText>
            Confirm you want to create a new conversation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmClick} disabled={!canConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default React.memo(ConversationDialogBox);
