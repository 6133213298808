import {
  Box,
  Button,
  ButtonGroup,
  Card,
  InputLabel,
  TextField,
  Typography,
  CardMedia,
  Container,
  Paper,
  Grid,
  Divider,
  List,
  ListItem,
  IconButton,
  FormControl,
  Input,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogActions,
  OutlinedInput,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import Cookies from "js-cookie";
import styles from "./invoice.module.css";
import CircularProgress from "@mui/material/CircularProgress";

const ViewContractorInvoiceForm = ({ project, onClose, selectedInvId }) => {
  const [invoiceName, setInvoiceName] = useState("New Invoice");
  const [issuedDate, setIssuedDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [amountDue, setAmountDue] = useState("");
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const { clientUserId } = useParams();
  const { projectId } = useParams();

  const [items, setItems] = useState([
    { description: "", rate: 0, quantity: 0, discount: 0 },
  ]);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountOpen, setDiscountOpen] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [tax, setTax] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [taxOpen, setTaxOpen] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAfterTax, setTotalAfterTax] = useState(0);
  const [invoiceData, setInvoiceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const pdfRef = useRef();

  const calculateTotal = () => {
    const totalAmount = items.reduce((acc, item) => {
      const itemTotal = item.rate * item.quantity * (1 - item.discount / 100);
      return acc + itemTotal;
    }, 0);
    setTotal(totalAmount);
  };

  const calculateDiscountedTotal = () => {
    return total - (total * discountValue) / 100;
  };

  const calculateAmountDue = () => {
    const discountedTotal = calculateDiscountedTotal();
    const calculatedTaxAmount = (discountedTotal * tax) / 100;
    return discountedTotal + calculatedTaxAmount - amountPaid;
  };

  const handleInvoiceNameChange = (event) => {
    setInvoiceName(event.target.value);
  };

  const handleImageUrl = (url) => {
    return `${API_URL}/${url}`;
  };

  const handleGetSingleInvoice = async (invoiceId) => {
    try {
      const response = await axios.get(
        `${API_URL}/project-invoice/get?projectId=${projectId}&invoiceId=${invoiceId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      setInvoiceData(data[0]);
      setFormFieldsFromInvoiceData(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const setFormFieldsFromInvoiceData = (data) => {
    setInvoiceName(data.invoiceName);
    setIssuedDate(data.dateOfIssue);
    setDueDate(data.dueDate);
    setInvoiceNumber(data.invoiceNo);
    setAmountDue(data.amountDue);
    setDiscount(data.discountPercent);
    setTax(data.taxPercent);
    setAmountPaid(data.amountPaid);

    setItems(
      data.invoiceLineItemResponseDto.map((item) => ({
        description: item.description,
        rate: item.rate,
        quantity: item.quantity,
        discount: item.discountPercent,
      }))
    );
  };

  const downloadAsPDF = async () => {
    let mobileView = false;
    let defaultWidth = window.innerWidth;
    if (window.innerWidth <= 790) {
      setIsLoading(true);
      mobileView = true;
      document
        .querySelector("meta[name=viewport]")
        .setAttribute("content", "width=1200");
    }
    const pdf = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "landscape",
    });

    const invoiceForm = document.getElementById("invoice-form");
    invoiceForm.classList.add("pdf-download");

    if (invoiceForm) {
      const canvas = await html2canvas(invoiceForm, {
        scale: 2,
        logging: true,
        useCORS: true,
        allowTaint: true,
      });

      const imageData = canvas.toDataURL("image/png");

      pdf.addImage(imageData, "PNG", 0, 0, 297, 210);

      pdf.save("invoice.pdf");
      if (mobileView) {
        setIsLoading(false);
        document
          .querySelector("meta[name=viewport]")
          .setAttribute("content", `width=${defaultWidth}}`);
      }
    }
  };

  const handleChangeInvoiceStatus = async (status) => {
    try {
      const invoiceId = invoiceData.invoiceId;
      const response = await axios.put(
        `${API_URL}/project-invoice/update-status?invoiceId=${invoiceId}&projectId=${projectId}&status=${status}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      const result = await response.data;
      console.log(result);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenConfirmModal = () => {
    setIsConfirmOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmOpen(false);
  };

  const handleConfirmModal = () => {
    handleChangeInvoiceStatus("PAID");
    handleCloseConfirmModal();
  };

  useEffect(() => {
    handleGetSingleInvoice(selectedInvId);
  }, [selectedInvId]);

  useEffect(() => {
    calculateTotal();
  }, [items]);

  useEffect(() => {
    calculateDiscountedTotal();
    const calculatedTaxAmount = (total * tax) / 100;
    setTaxAmount(calculatedTaxAmount);
    setTotalAfterTax(total + calculatedTaxAmount);
    setAmountDue(calculateAmountDue());
  }, [items, discountValue, tax, amountPaid]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Box sx={{ margin: "0 20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "28px",
          flexWrap: "wrap",
        }}
      >
        <TextField
          id="invoice-name"
          label=""
          value={invoiceName}
          onChange={handleInvoiceNameChange}
          variant="standard"
          inputProps={{
            readOnly: true,
          }}
          sx={{
            marginRight: "8px",
            "& input": {
              fontSize: "28px",
            },
            "& .MuiInputLabel-outlined": {
              fontSize: "28px",
              display: "none",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
            marginBottom: "5px",
          }}
          onFocus={() => setIsTextFieldFocused(true)}
          onBlur={() => setIsTextFieldFocused(false)}
        />
        <Box sx={{ display: "flex", gap: "10px", height: "80%" }}>
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              boxShadow:
                "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
            }}
            onClick={downloadAsPDF}
          >
            Download
          </Button>
          {invoiceData.status === "SENT" && (
            <>
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  boxShadow:
                    "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
                }}
                onClick={() => {
                  handleOpenConfirmModal();
                }}
              >
                Paid
              </Button>
              <Dialog open={isConfirmOpen} onClose={handleCloseConfirmModal}>
                <DialogTitle fontSize={"18px"} fontWeight={"500"}>
                  Are you sure you want to change this invoice to paid?
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={handleCloseConfirmModal}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmModal}
                    variant="contained"
                    color="primary"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  boxShadow:
                    "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
                }}
                onClick={() => {
                  handleChangeInvoiceStatus("DRAFT");
                }}
              >
                Unshare
              </Button>
            </>
          )}
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              boxShadow:
                "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{ marginTop: "16px", width: "100%", display: "flex" }}
          id="invoice-form"
          ref={pdfRef}
        >
          <Box width={"100%"}>
            <Paper elevation={3} style={{ padding: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      width: "80%",
                      height: "100%",
                    }}
                    className={styles.grid_invoice_logo}
                  >
                    {invoiceData?.businessLogoUrl ? (
                      <CardMedia
                        component="img"
                        alt="Business Logo"
                        // height="80"
                        image={handleImageUrl(invoiceData.businessLogoUrl)}
                        maxWidth="200px !important"
                        height="150px"
                        className={styles.invoice_logo_fit}
                      />
                    ) : (
                      <Typography variant="h5" fontSize="20px" fontWeight="600">
                        {invoiceData?.businessName}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sm={0}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                  className={styles.grid_invoice_head}
                >
                  <Box>
                    <Typography
                      variant="h5"
                      textTransform="capitalize"
                      fontSize="16px"
                    >
                      {invoiceData?.businessName}
                    </Typography>
                    <Typography variant="body2" fontSize="16px">
                      {invoiceData?.businessPhone}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={styles.break_word}
                      fontSize="16px"
                    >
                      {invoiceData?.businessAddress}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid
                  item
                  sx={{ flex: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className={`${styles.grid_invoice_subhead} ${styles.res_invoice_header_titles}`}
                >
                  <Box>
                    <Typography
                      variant="h5"
                      textTransform="capitalize"
                      fontSize="16px"
                    >
                      {invoiceData?.businessName}
                    </Typography>
                    <Typography variant="body2" fontSize="16px">
                      {invoiceData?.businessPhone}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={styles.break_word}
                      fontSize="16px"
                    >
                      {invoiceData?.businessAddress}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: "18px", color: "#666", fontWeight: 600 }}
                  >
                    Billed To
                  </Typography>
                  <Typography
                    variant="h5"
                    textTransform="capitalize"
                    fontSize="16px"
                    fontWeight="600"
                  >
                    {invoiceData?.clientName}
                  </Typography>
                  <Typography variant="body1" fontSize="16px" fontWeight="600">
                    {invoiceData?.clientPhone}
                  </Typography>
                  <Typography variant="body3" fontSize="16px" fontWeight="600">
                    {invoiceData?.clientAddress}
                  </Typography>
                </Grid>

                <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: "18px", color: "#666", fontWeight: 600 }}
                  >
                    Date of Issue
                  </Typography>
                  <TextField
                    type="date"
                    value={issuedDate}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      style: { border: "none", fontSize: "17px" },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
                    }}
                    onChange={(e) => setIssuedDate(e.target.value)}
                    className={styles.res_invoice_head_date}
                  />
                </Grid>

                <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: "18px", color: "#666", fontWeight: 600 }}
                  >
                    Due Date
                  </Typography>
                  <TextField
                    type="date"
                    value={dueDate}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      style: { border: "none", fontSize: "17px" },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
                    }}
                    onChange={(e) => setDueDate(e.target.value)}
                    className={styles.res_invoice_head_date}
                  />
                </Grid>

                <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: "18px", color: "#666", fontWeight: 600 }}
                  >
                    Invoice Number
                  </Typography>
                  <TextField
                    type="text"
                    value={invoiceNumber}
                    variant="standard"
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                    InputProps={{
                      readOnly: true,
                      style: { border: "none", fontSize: "18px" },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                    }}
                  />
                </Grid>

                <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: "18px", color: "#666", fontWeight: 600 }}
                  >
                    Amount Due
                  </Typography>
                  <TextField
                    type="text"
                    value={`$ ${invoiceData?.amountDue}`}
                    variant="standard"
                    onChange={(e) => setAmountDue(e.target.value)}
                    InputProps={{
                      readOnly: true,
                      style: {
                        border: "none",
                        fontSize: "24px",
                        fontWeight: 600,
                      },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                    }}
                    className={styles.pdf_amount_due}
                  />
                </Grid>
              </Grid>
              <Divider
                style={{
                  margin: "20px 0 0 0",
                  padding: "1px",
                  backgroundColor: "#838383",
                }}
              />
              {/* List of Items */}
              <TableContainer
                component={Paper}
                sx={{
                  margin: "20px 0",
                  boxShadow:
                    "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
                }}
              >
                <Table>
                  <TableHead className={styles.res_table_head}>
                    <TableRow>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Description
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Rate
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Quantity
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Discount (%)
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item, index) => (
                      <TableRow key={index} className={styles.res_table_row}>
                        <TableCell>
                          <label>Description</label>
                          <TextField
                            type="text"
                            value={item.description}
                            InputProps={{ readOnly: true }}
                            fullWidth
                            className={styles.borderless_input}
                          />
                        </TableCell>
                        <TableCell>
                          <label>Rate</label>
                          <TextField
                            type="number"
                            value={item.rate}
                            InputProps={{ readOnly: true }}
                            className={styles.borderless_input}
                          />
                        </TableCell>
                        <TableCell>
                          <label>Quantity</label>
                          <TextField
                            type="number"
                            value={item.quantity}
                            InputProps={{ readOnly: true }}
                            className={styles.borderless_input}
                          />
                        </TableCell>
                        <TableCell>
                          <label>Discount (%)</label>
                          <TextField
                            type="number"
                            value={item.discount}
                            InputProps={{ readOnly: true }}
                            className={styles.borderless_input}
                          />
                        </TableCell>
                        <TableCell className={styles.res_line_total} >
                          <label>Total</label>
                          <TextField
                            type="text"
                            value={`$ ${(
                              item.rate *
                              item.quantity *
                              (1 - item.discount / 100)
                            ).toFixed(2)}`}
                            InputProps={{ readOnly: true }}
                            className={styles.borderless_input}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Subtotal */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}></Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="body1"
                    display={"flex"}
                    alignSelf={"center"}
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Subtotal:
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <TextField
                    type="text"
                    value={`$ ${total?.toFixed(2)}`}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: { border: "none", fontSize: "16px", fontWeight: "600" },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
                    }}
                  />
                </Grid>
              </Grid>

              {/* <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid
                item
                xs={6}
                md={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Typography
                  variant="body1"
                  display={"flex"}
                  alignSelf={"center"}
                  sx={{ fontSize: "16px" }}
                >
                  Discount ({`${invoiceData?.discountPercent} %`}):
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  type="text"
                  value={`$ ${invoiceData?.discountAmount}`}
                  onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)}
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: { border: "none", fontSize: "16px" },
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                    "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
                  }}
                />
              </Grid>
            </Grid> */}

              <Grid container spacing={2}>
                <Grid item xs={12} md={8}></Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="body1"
                    display={"flex"}
                    alignSelf={"center"}
                    sx={{ fontSize: "16px" }}
                  >
                    Amount Saved:
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <TextField
                    type="text"
                    value={`$ ${invoiceData?.totalAmountSaved
                        ? invoiceData?.totalAmountSaved
                        : "0.00"
                      }`}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: { border: "none", fontSize: "16px" },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems={"center"} alignSelf={"center"}>
                <Grid item xs={12} md={8}></Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"end"}
                >
                  <Typography
                    variant="body1"
                    display={"flex"}
                    alignSelf={"center"}
                    sx={{ fontSize: "16px" }}
                  >
                    Tax ({`${tax} %`}):
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2} alignItems={"center"} alignSelf={"center"}>
                  <TextField
                    type="text"
                    value={`$ ${invoiceData?.taxAmount}`}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: { border: "none", fontSize: "16px" },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
                    }}
                  />
                </Grid>
              </Grid>

              {/* <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={6} md={2} display="flex" justifyContent={"end"}>
                <Typography
                  variant="body1"
                  display="flex"
                  alignSelf="center"
                  sx={{ fontSize: "16px" }}
                >
                  Tax amount:
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  type="text"
                  value={`$ ${invoiceData?.taxAmount}`}
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: { border: "none", fontSize: "16px" },
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                    "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
                  }}
                />
              </Grid>
            </Grid> */}

              <Grid container spacing={2}>
                <Grid item xs={12} md={8}></Grid>
                <Grid item xs={12} md={4}>
                  <Divider style={{ margin: "6px 0" }} />
                </Grid>
              </Grid>

              {/* Total Amount */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}></Grid>
                <Grid item xs={6} md={2} display="flex" justifyContent={"end"}
                  alignItems={"center"} alignSelf={"center"}
                >
                  <Typography
                    variant="body1"
                    display="flex"
                    alignSelf="center"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Total Amount:
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2} alignItems={"center"} alignSelf={"center"}>
                  <TextField
                    type="text"
                    value={`$ ${invoiceData.totalAmount}`}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: { border: "none", fontSize: "16px", fontWeight: "600" },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" }, alignSelf: "center", verticalAlign: "middle"
                    }}
                  />
                </Grid>
              </Grid>

              {/* Amount Paid */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}></Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="body1"
                    display={"flex"}
                    alignSelf={"center"}
                    sx={{ fontSize: "16px" }}
                  >
                    Amount Paid:
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2} alignItems={"center"} alignSelf={"center"}>
                  <TextField
                    type="number"
                    value={amountPaid}
                    onChange={(e) =>
                      setAmountPaid(parseFloat(e.target.value) || 0)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      readOnly: true,
                      style: { border: "none", fontSize: "16px" },
                    }}
                    variant="standard"
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
                    }}
                    placeholder="Amount Paid"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={8}></Grid>
                <Grid item xs={12} md={4}>
                  <Divider style={{ margin: "6px 0" }} />
                </Grid>
              </Grid>

              {/* Amount Due */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}></Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="body1"
                    display={"flex"}
                    alignSelf={"center"}
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Amount Due:
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <TextField
                    type="text"
                    value={`$ ${invoiceData?.amountDue}`}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: { border: "none", fontSize: "16px", fontWeight: "600" },
                    }}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ViewContractorInvoiceForm;
