import React, { useState, useEffect, useInsertionEffect } from "react";

import { useTheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";

const LoginForm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const [logos, setLogos] = useState({
    mainLogo: null,
    sidebarLogo: null,
    emailLogo: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleInputChange = (event) => {
    if (event.target.name === "username") {
      setUsername(event.target.value);
    } else if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchLogosWithToken = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");

      if (token) {
        try {
          const response = await fetch(
            `${API_URL}/logo/get-logos?businessId=${token}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
                // If needed: 'X-XSRF-TOKEN': cookie,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();

          const logosMap = data.reduce(
            (acc, logo) => {
              acc[logo.logoType] = logo;
              return acc;
            },
            { mainLogo: null, sidebarLogo: null, emailLogo: null }
          );

          setLogos(logosMap);
        } catch (error) {
          console.error("Failed to fetch logos:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.log("no token");
      }
    };

    fetchLogosWithToken();
  }, [API_URL]);

  useInsertionEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    const fetchData = async () => {
      const temp = await fetch(`${API_URL}/welcome`, {
        method: "GET",
        credentials: "include",
      });
    };
    fetchData();
  }, []);
  const navigateToForgotPassword = () => {
    navigate("/ForgotPassword");
  };
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      let formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);

      const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        credentials: "include",
        body: formData,
        headers: {
          Accept: "application/json",
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
      });
      if (response.ok) {
        //todo: see we we can get the redirect specifically url from the backend
        //const redirectUrl = response.headers.get('Location');

        //check to see what type of user

        const authResponse = await fetch(`${API_URL}/is-authenticated`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const authStatus = await authResponse.text();

        if (authStatus === "isEmployee" || authStatus === "isEmployeeAdmin") {
          navigate("/Dashboard");
        } else if (authStatus === "isClient") {
          navigate("/ClientProjectList");
        } else {
          setError("Ops. Invalid username or password");
        }
      } else {
        setError("Invalid username or password");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("An error occurred");
    }
  };

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Grid container sx={{ height: "100%", width: "100%" }}>
      {/* Left half of the screen (unchanged) */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          background: `linear-gradient(rgba(11, 21, 55, 0.75), rgba(11, 21, 55, 0.75)), url('Moonlight-Connect-login-background.svg') #0B1537`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          m: 0,
          p: 0,
        }}
      >
        <Box
          component="img"
          sx={{
            height: isMobile ? "25vh" : isTablet ? "30vh" : "70vh",
            width: isMobile ? "45vh" : isTablet ? "30vh" : "70vh",
          }}
          src="/whitetext.svg"
        />
      </Grid>

      {/* Right half of the screen */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: theme.spacing(2),
          background: "#F3F3F3",
          position: "relative",
        }}
      >
        {logos.mainLogo && (
          <Box
            component="img"
            sx={{
              position: "relative",
              maxWidth: "30%",
              maxHeight: "30%",
              mb: "10px",
              zIndex: 0,
            }}
            src={`${API_URL}/${logos.mainLogo.fileInfoDTO.fileUrl}`}
            alt="Main Logo"
          />
        )}
        {/* Wrap the Typography in a Box to center it while aligning text left */}
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: 2,
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 700, // Bold
              textAlign: "left",
              width: "100%",
            }}
          >
            SIGN IN
          </Typography>
        </Box>

        {error && (
          <Typography
            color="error"
            sx={{
              mb: 2,
              width: "100%",
              maxWidth: "400px",
              textAlign: "left",
            }}
          >
            {error}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "400px", // Constrain form width
          }}
        >
          <TextField
            label="Username"
            type="text"
            name="username"
            value={username}
            onChange={handleInputChange}
            margin="normal"
            fullWidth
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: 5,

              input: { fontSize: '16px' },
            }}
          />
          <TextField
            label="Password"
            type="password"
            name="password"
            value={password}
            onChange={handleInputChange}
            margin="normal"
            fullWidth
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: 5,

              input: { fontSize: '16px' },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              background: "#FBBB1D",
              height: "60px",
              mt: 3,
              mb: 2,
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 700, // Bold
              fontSize: 25,
              borderRadius: "10px",
              boxShadow: 5,
            }}
            fullWidth
          >
            SIGN IN
          </Button>
        </Box>

        {/* Center the "Forgot password?" button while aligning text left within a constrained width */}
        <Box sx={{ textAlign: "left", width: "100%", maxWidth: "400px" }}>
          <Button
            sx={{
              textTransform: "none",
              textAlign: "left",
              justifyContent: "flex-start",
              color: "#545454",
            }}
            onClick={navigateToForgotPassword}
          >
            Forgot password?
          </Button>
        </Box>
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};

export default LoginForm;
