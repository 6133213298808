import React, { useState, useEffect, useInsertionEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  useTheme,
  FormControl,
  Button,
  Grid,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { tokens } from "../../theme";

import Cookies from "js-cookie";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const Message = ({ projectId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const API_URL = process.env.REACT_APP_API_URL;
  // using context
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [page, setPage] = useState(0);
  const clientId = Cookies.get("userId");
  const MessagesRef = useRef(messages);
  const messagesEndRef = useRef(null);
  const [scroll, setScroll] = useState(true);
  const [notificationCounts, setNotificationCounts] = useState({});
  const [notification, setNotification] = useState(null);

  // Determine the active status of the employee in the current conversation
  const currentConversation = conversations.find(
    (conv) => conv.conversationId === conversationId
  );
  const employeeActive = currentConversation?.participantDTOs.find(
    (participant) => participant.userDTO.userId !== clientId
  )?.userDTO.active;

  // Get Notification Info
  useEffect(() => {
    async function fetchNotificationData() {
      try {
        const response = await fetch(
          `${API_URL}/client-notification/get-notifications-for-project/${projectId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Network response was not ok ${response.statusText}`);
        }

        const data = await response.json();
        const newCounts = {};

        data.forEach((notification) => {
          if (
            notification.eventType === "EVENT_TYPE_1" &&
            !notification.viewed
          ) {
            const { eventId } = notification; // eventId is the conversationId for these notifications
            newCounts[eventId] = (newCounts[eventId] || 0) + 1;
          }
        });

        setNotificationCounts(newCounts);
      } catch (err) {
        console.error(`Error fetching data for projectId: ${projectId} `, err);
        // setLoading(false);
      }
    }
    fetchNotificationData();
  }, [projectId]);
  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handlePagination = async () => {
    setScroll(false);
    setPage(page + 1);
    await GetMessages(conversationId, page + 1);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const HandleSwitchConversation = (convId) => {
    setMessages([]);
    setPage(0);
    setConversationId(convId);
    // Check if there are notifications to clear for this conversation
    if (notificationCounts[convId] && notificationCounts[convId] > 0) {
      const newCounts = { ...notificationCounts, [convId]: 0 }; // Reset count for this conversation
      setNotificationCounts(newCounts); // Update the state to reflect this
    }
    GetMessages(convId, 0);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "" || selectedImage) {
      const messageDTO = {
        messageId: "",
        conversationId: conversationId,
        senderId: clientId,
        messageText: newMessage,
        sentAt: new Date().toISOString(),
        isRead: false,
      };

      //setSelectedImage(null);

      const sendResponse = await fetch(`${API_URL}/clientMessages/send`, {
        method: "post",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
        body: JSON.stringify(messageDTO),
      });
      const sentMsgData = await sendResponse.json();

      setMessages([...messages, sentMsgData]);
      setNewMessage("");
    }
    scrollToBottom();
  };

  function msgUnion(newMsgList) {
    const combined = [...MessagesRef.current, ...newMsgList];
    const uniqueMsgMap = new Map();

    combined.forEach((msg) => uniqueMsgMap.set(msg.messageId, msg));

    return Array.from(uniqueMsgMap.values());
  }

  //gets all the messages for a given chat
  async function GetMessages(convId, page) {
    const msgResponse = await fetch(
      `${API_URL}/clientMessages/getMessages?conversationId=${convId}&page=${page}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      }
    );

    // Check if the response status is 200-OK
    if (msgResponse.ok && msgResponse.status === 200) {
      const msgData = await msgResponse.json();

      const msgList = msgUnion(msgData.content);

      const sortedMessages = msgList.sort((a, b) => {
        return new Date(a.sentAt) - new Date(b.sentAt);
      });

      setMessages(sortedMessages);
    } else {
      // Handle non-200 responses
      console.error("HTTP Error:", msgResponse.status);
    }
  }

  // getting conversations, messages, and project
  useEffect(() => {
    //todo: add api call to get projectOwner name

    async function fetchData() {
      // getting list of all conversations
      const allChatsResponse = await fetch(
        `${API_URL}/clientMessages/AllChatsWithClient?clientId=${clientId}&projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (allChatsResponse.ok && allChatsResponse.status === 200) {
        const chatsData = await allChatsResponse.json();
        if (chatsData.length > 0) {
          const sortedConvo = chatsData.sort((a, b) => {
            const dateA = a.lastMessageTimeStamp
              ? new Date(a.lastMessageTimeStamp)
              : new Date(0);
            const dateB = b.lastMessageTimeStamp
              ? new Date(b.lastMessageTimeStamp)
              : new Date(0);

            return dateB - dateA; // Descending order: newer dates first
          });

          setConversations(sortedConvo);
          setConversationId(sortedConvo[0].conversationId);

          // getting messages from a conversation
          const msgResponse = await fetch(
            `${API_URL}/clientMessages/getMessages?conversationId=${chatsData[0].conversationId}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
              },
            }
          );
          const msgData = await msgResponse.json();

          const sortedMessages = msgData.content.sort((a, b) => {
            return new Date(a.sentAt) - new Date(b.sentAt);
          });
          setMessages(sortedMessages);
        }
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    MessagesRef.current = messages;
  }, [messages]);

  useInsertionEffect(() => {
    // Set an interval to fetch messages every 30 seconds
    const intervalId = setInterval(() => {
      GetMessages(conversationId, 0);
    }, 30000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [conversationId]);

  const scrollToBottom = () => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    if (scroll) {
      scrollToBottom();
    } else {
      setScroll(true);
    }
  }, [messages]);

  // Handle case where project data is not yet loaded
  if (conversations === null) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        backgroundColor: colors.primary[400],
        borderRadius: 1,
        boxShadow: 3,
        mt: "5px",
        mb: "5px",
      }}
    >
      <Grid container spacing={3}>
        {/* <Box display="flex" gap="10px" marginRight="25px"> */}
        {/* left box displays list of conversations */}
        <Grid item xs={12} md={2}>
          <Box
            sx={{
              p: "5px",
              borderRadius: "8px",
              backgroundColor: "#ccc6c6",
              display: "flex",
              flexDirection: "column",
              columnGap: "5px",
              rowGap: "5px",
              maxHeight: "300px",
              minHeight: "300px",
              overflowY: "auto",
            }}
          >
            <List>
              {conversations.length > 0 ? (
                conversations.map((conversation) => (
                  <ListItem
                    key={conversation.conversationId}
                    button
                    selected={conversation.conversationId === conversationId}
                    onClick={() =>
                      HandleSwitchConversation(conversation.conversationId)
                    }
                    sx={{
                      "&:hover": {
                        backgroundColor: colors.hoverColor, // Your hover color
                      },
                      backgroundColor:
                        conversation.conversationId === conversationId
                          ? colors.selectedColor // Your selected color
                          : "inherit",
                    }}
                  >
                    <ListItemText
                      primary={
                        conversation.participantDTOs[0].userDTO.role ===
                          "employee" ||
                        conversation.participantDTOs[0].userDTO.role ===
                          "employee_admin" ||
                        !conversation.participantDTOs[0].userDTO.role
                          ? !conversation.participantDTOs[0].userDTO.active
                            ? "Deleted User"
                            : conversation.participantDTOs[0].userDTO.firstName
                          : !conversation.participantDTOs[1].userDTO.active
                          ? "Deleted User"
                          : conversation.participantDTOs[1].userDTO.firstName
                      }
                    />

                    {notificationCounts[conversation.conversationId] > 0 && (
                      <Box
                        component="span"
                        sx={{
                          display: "inline-block",
                          ml: 1,
                          width: 10,
                          height: 10,
                          bgcolor: "red",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </ListItem>
                ))
              ) : (
                <Typography variant="body1">No chats available.</Typography>
              )}
            </List>
          </Box>
        </Grid>

        {/* Right box for displaying messages */}
        <Grid item xs={12} md={10}>
          <Box
            sx={{
              p: "20px",
              borderRadius: "8px",
              backgroundColor: "#ccc6c6",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              maxHeight: "300px", // Set a max height for scroll
              minHeight: "300px",
              overflowY: "auto", // Enable scrolling on Y-axis
              width: "100%",
            }}
          >
            <Typography
              variant="body1"
              color="#3366CC"
              onClick={handlePagination}
            >
              click to load older messages.
            </Typography>
            {messages.length > 0 ? (
              messages.map((message) => (
                <Box
                  key={message.messageId} // Ensure key is unique
                  backgroundColor={
                    message.senderId === clientId
                      ? colors.primary[300]
                      : colors.blueAccent[300]
                  }
                  borderRadius="8px"
                  padding="10px"
                  maxWidth="80%"
                  alignSelf={
                    message.senderId === clientId ? "flex-end" : "flex-start"
                  }
                >
                  <Typography
                    variant="body1"
                    color="white"
                    sx={{ wordWrap: "break-word" }}
                  >
                    {message.messageText}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: "gray", fontSize: "0.75rem", mt: 1 }}
                  >
                    {new Date(message.sentAt).toLocaleString()}{" "}
                    {/* Formatted timestamp */}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body1">No messages available.</Typography>
            )}
            <Box ref={messagesEndRef} />
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}

      <Box display="flex" gap="10px" mt="20px" pb="20px" marginRight="25px">
        <TextField
          value={newMessage}
          onChange={handleNewMessageChange}
          label="New Message"
          variant="outlined"
          focused
          fullWidth
          multiline
          maxRows={4}
          inputProps={{
            style: {
              overflow: "auto",
              fontSize: "16px",
            },
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey && employeeActive) {
              handleSendMessage();
              e.preventDefault();
            }
          }}
        />
        {/*
        <label htmlFor="image-upload">
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <IconButton
            color="primary"
            component="span"
            style={{ color: colors.blueAccent[500] }}
          >
            <PhotoCameraIcon />
          </IconButton>
        </label>
        */}

        <IconButton
          color="primary"
          onClick={handleSendMessage}
          style={{ color: colors.blueAccent[500] }}
          disabled={!employeeActive}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Message;
