import axios from "axios";
import Cookies from "js-cookie";
import ACCESS_CATEGORIES from "../constants/AccessCatagories"


const checkWriteAccess = async (permissionCategory) => {
    const API_URL = process.env.REACT_APP_API_URL;
  

    try {
      const response = await axios.get(`${API_URL}/access-control/check-permission`, {
        params: {
          userId: Cookies.get("userId"),
          category: permissionCategory, 
          accessType: "write"
        },
        withCredentials: true,
      });
  
      return response.data;
    } catch (error) {
      console.log("Unable to check access for: " + permissionCategory);
    }
  };

  export default checkWriteAccess;