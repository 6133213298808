import {
  Box,
  Typography,
  IconButton,
  useTheme,
  Button,
  TextField,
  Grid,
  Tab,
  Tabs,
  Snackbar,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { tokens } from "../../theme";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Header from "../../components/Header";
import Cookies from "js-cookie";
import axios from "axios"; // Step 1: Import axios
import SelectionPopupForm from "./SelectionForm";
import SelectionsComponent from "./SelectionComponent";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// import noImage from "/noImage.svg";

let currentFinishingId = 0;
const API_URL = process.env.REACT_APP_API_URL;

const AddFinishingPage = ({
  finishings,
  onAdd,
  onDelete,
  onSend,
  setOpen,
  selectionData,
  onReload,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [newFinishing, setNewFinishing] = useState({
    selectionId: currentFinishingId,
    name: "",
    description: "",
    partNumber: "",
    cost: "",
    imageFile: null, // This will hold the file object
    clientConfirmed: false,
  });

  const handleNewFinishingChange = (event, key) => {
    setNewFinishing({ ...newFinishing, [key]: event.target.value });
  };

  const handleAddNewFinishing = () => {
    if (newFinishing.imageFile instanceof Blob) {
      const newFinishingWithImage = {
        ...newFinishing,
        image: URL.createObjectURL(newFinishing.imageFile), // Create the Blob URL for the new image
      };
      onAdd(newFinishingWithImage);
      URL.revokeObjectURL(newFinishing.image); // Revoke the Blob URL
      currentFinishingId += 1;
      setNewFinishing({
        selectionId: currentFinishingId,
        name: "",
        description: "",
        partNumber: "",
        cost: "",
        imageFile: null,
        clientConfirmed: false,
      });
    } else {
      // Handle the case where newFinishing.imageFile is not a Blob
      console.error("Invalid image file type");
      // You can show an error message, log the error, or take other appropriate action
    }
  };

  const handleSendForApproval = () => {
    onSend();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setNewFinishing({ ...newFinishing, imageFile: file }); // Store the file in the state
    } else {
      console.error("Invalid file type. Please upload an image.");
      // Handle non-image files
    }
  };
  const handleReload = async () => {
    await onReload();
  };
  return (
    <Grid container spacing={2}>
      {/* Row with the number of finishings ready to send */}

      <SelectionsComponent data={selectionData} onReload={handleReload} />

      <Grid container spacing={2}>
        {finishings.map((finishing) => (
          <Grid item key={finishing.selectionId} xs={12} sm={6} md={4}>
            <Box
              maxHeight={500}
              boxShadow={2}
              p={2}
              bgcolor="background.paper"
              height="100%"
              display="flex"
              flexDirection="column"
              overflow="auto"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Typography variant="h3">{finishing.name}</Typography>
                <IconButton onClick={() => onDelete(finishing.selectionId)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box
                component="img"
                src={finishing.image}
                alt={finishing.name}
                sx={{
                  maxHeight: "200px",
                  maxWidth: "100%",
                  objectFit: "contain",
                  marginBottom: "auto",
                }}
              />
              <Typography variant="h4" mb={2}>
                {finishing.description}
              </Typography>
              <Typography
                variant="h5"
                mb={2}
              >{`Part Number: ${finishing.partNumber}`}</Typography>
              <Typography
                variant="h6"
                mb={2}
              >{`Cost: ${finishing.cost}`}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const AwaitingFinishingPage = ({ selectionData, onReload }) => {
  const handleReload = () => {
    onReload();
  };
  return (
    <Grid container spacing={2}>
      <SelectionsComponent
        data={selectionData}
        forRequests={true}
        onReload={handleReload}
      />
    </Grid>
  );
};

const ConfirmedFinishingPage = ({ selectionData }) => {
  return (
    <Grid container spacing={2}>
      <SelectionsComponent data={selectionData} isConfirmed={true} />
    </Grid>
  );
};

const FinishingsPage = () => {
  const { clientUserId } = useParams();
  const { projectId } = useParams();
  const [tabValue, setTabValue] = useState(0);

  const [finishings, setFinishings] = useState([]);
  const [addFinishings, setAddFinishings] = useState([]);
  const [awaitingFinishings, setAwaitingFinishings] = useState([]);
  const [confirmedFinishings, setConfirmedFinishings] = useState([]);
  const [open, setOpen] = useState(false);
  // trying to add project name at the top
  const [project, setProject] = useState(null);
  const [clientUser, setClientUser] = useState([]);
  const [selectionData, setSelectionData] = useState([]);
  //used to naviage back a page

  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const API_URL = process.env.REACT_APP_API_URL;

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openPDF, setOpenPDF] = useState(false);

  //Get Project Info
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );
        const data = await response.json();
        setProject(data);
      } catch (err) {
        console.error(`Error fetching data for userId ${projectId}:`, err);
      }
    };
    fetchProjectData();
  }, [projectId]);

  //Get Client User Info
  useEffect(() => {
    async function fetchData() {
      try {
        // Then use it in your fetch call:
        const response = await fetch(
          `${API_URL}/client/get-single-user?userId=${clientUserId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        const data = await response.json();
        setClientUser(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, [clientUserId]);

  const fetchFinishings = async () => {
    try {
      const response = await fetch(
        `${API_URL}/selections/get-selections/${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      if(response.ok) {
        const finishingsData = await response.json();

        const awaitingFinishings = finishingsData.filter(
          (finishing) => !finishing.clientConfirmation
        );
        const confirmedFinishings = finishingsData.filter(
          (finishing) => finishing.clientConfirmation
        );
  
        setAwaitingFinishings(awaitingFinishings);
        setConfirmedFinishings(confirmedFinishings);
      } else{
        if(response.status === 403){
          alert("You are not authorized to view selections. Please contact Admin for help.");
        } else {
          throw new Error("Network response was not ok");
        }

      }
    } catch (error) {
      console.error("Error fetching finishings data:", error);
    }
  };

  const fetchSelections = async () => {
    try {
      const response = await fetch(
        `${API_URL}/selections/get-all?projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      if (response.ok) {
        const selectionsData = await response.json();
        console.log(selectionsData);
        setSelectionData(selectionsData);
      } else {
        if(response.status === 403){
          alert("You are not authorized to view selections. Please contact Admin for help.");
        } else {
          throw new Error("Network response was not ok");
        }
      }


    } catch (error) {
      console.error("Error fetching finishings data:", error);
    }
  };

  const handleReload = async () => {
    await fetchSelections();
  };

  useEffect(() => {
    // fetchFinishings();
    fetchSelections();
  }, [projectId]);

  const handleAdd = (newFinishing) => {
    setAddFinishings((prev) => [...prev, newFinishing]);
  };

  const handleDelete = async (finishingId) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.SELECTIONS)){
      alert("You are not authorized to create or modify selections. Please contact Admin for help.");
      return;
    }
    
    setAddFinishings((prev) =>
      prev.filter((finishing) => finishing.selectionId !== finishingId)
    );
  };

  const handleOpenSelectionsForm = async () => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.SELECTIONS)){
      alert("You are not authorized to create selections. Please contact Admin for help.");
      return;
    }

    setOpen(true);
  };

  const handleSend = async () => {
    for (let finishing of addFinishings) {
      let formData = new FormData();

      // Prepare the selectionsDTO object without the imageFile and image properties
      let selectionsDTO = { ...finishing };
      delete selectionsDTO.image; // Remove the Blob URL
      delete selectionsDTO.imageFile; // Remove the Blob file reference

      // Add finishing details to formData
      // formData.append("selectionsDTO", JSON.stringify(selectionsDTO));

      formData.append(
        "selectionsDTO",
        JSON.stringify({
          name: selectionsDTO.name,
          description: selectionsDTO.description,
          partNumber: selectionsDTO.partNumber,
          cost: selectionsDTO.cost,
          clientConfirmation: selectionsDTO.clientConfirmed,
          userId: clientUserId,
          projectId: projectId,
          businessId: Cookies.get("businessId"),
        })
      );
      //
      // Add the image file to formData if it exists
      if (finishing.imageFile instanceof Blob) {
        formData.append("files", finishing.imageFile);
      }

      try {
        const response = await axios.post(
          `${API_URL}/selections/create`,
          formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        // Handle response here if needed
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403){
            alert("You are not authorized to add selections. Please contact Admin for help.");
          }
        }
        console.error("Error creating selection:", error);
      }
    }

    // Update state after all items have been processed
    setAwaitingFinishings((prev) => [...prev, ...addFinishings]);
    setAddFinishings([]);
    // fetchFinishings();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigateTo = (path) => () => {
    console.log("Navigating to:", path); // Debugging log
    navigate(path);
  };

  //Helper function to convert image URL to Base64
  function fetchImageAndAddToPDF(pdf, imageUrl, x, y, width, height) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(imageUrl, {
          method: "GET",
          credentials: "include", // Ensures cookies are included in the request
        });

        if (!response.ok)
          throw new Error(`Failed to fetch image: ${response.statusText}`);

        const imageBlob = await response.blob();
        const reader = new FileReader();

        reader.onload = () => {
          const base64Image = reader.result;
          pdf.addImage(base64Image, "JPEG", x, y, width, height); // Adjust format and dimensions as needed
          resolve(); // Resolve the promise after the image has been added
        };

        reader.onerror = () => {
          reject(new Error(`Failed to read image blob`));
        };

        reader.readAsDataURL(imageBlob);
      } catch (error) {
        console.error("Error adding image to PDF:", error);
        reject(error); // Reject the promise on error
      }
    });
  }

  const [logos, setLogos] = useState({
    mainLogo: null,
    sidebarLogo: null,
    emailLogo: null,
  });

  //get logos
  useEffect(() => {
    async function fetchLogo() {
      try {
        const response = await fetch(
          `${API_URL}/logo/get-logos?businessId=${Cookies.get("businessId")}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              // If needed: 'X-XSRF-TOKEN': cookie,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const logosMap = data.reduce(
          (acc, logo) => {
            acc[logo.logoType] = logo;
            return acc;
          },
          { mainLogo: null, sidebarLogo: null, emailLogo: null }
        );

        console.log(logosMap);

        setLogos(logosMap);
      } catch (error) {
        console.error("Failed to fetch logos:", error);
      } finally {
      }
    }

    fetchLogo();
  }, []);

  const generatePDF = async (selectionData) => {
    setSnackbarMessage("Downloading Selections");
    setOpenPDF(true);

    const doc = new jsPDF();
    doc.setFontSize(10); // Set default font size
    doc.setFillColor(11, 21, 55); // RGB for red
    doc.rect(0, 0, 210, 10, "F");

    // Optional: Define a header
    console.log(doc.getFontList());
    // doc.setFont("zapfdingbats");
    doc.setFontSize(16);
    doc.text("Confirmed Selections", 105, 50, null, null, "center"); // Centered title

    doc.setFontSize(12); // Slightly larger font for titles
    doc.setFont(undefined, "bold"); // Bold font for titles
    doc.text(
      `Client: ${clientUser.firstName} ${clientUser.lastName}`,
      200,
      20,
      null,
      null,
      "right"
    );

    doc.text(`Address: ${project.address}`, 200, 25, null, null, "right");

    const now = new Date();
    const dateString = now.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    doc.text(`Date: ${dateString}`, 200, 30, null, null, "right");

    if (logos.emailLogo) {
      const logoURL = `${API_URL}/${logos.emailLogo.fileInfoDTO.fileUrl}`;
      await fetchImageAndAddToPDF(doc, logoURL, 10, 10, 50, 40, "left");
    }
    doc.setFontSize(10); // Revert to default font size for the rest of the content

    doc.line(10, 60, 200, 60); // Draw a horizontal line at y = 55

    let yPosition = 70; // Start below the header
    let imagePosition = 70;

    for (let selection of selectionData) {
      if (
        selection.status === "APPROVED" &&
        selection.selectionResponseDtoList.length > 0
      ) {
        const firstSelection = selection.selectionResponseDtoList[0];

        // Ensure there's enough space for this section; otherwise, add a new page
        if (yPosition >= 250) {
          // Approximation, adjust based on your content
          doc.addPage();
          yPosition = 10; // Reset position for the new page
          imagePosition = 10;
        }

        // Title
        doc.setFontSize(12); // Slightly larger font for titles
        doc.setFont(undefined, "bold"); // Bold font for titles
        doc.text(`Room: ${selection.room}`, 100, yPosition);
        doc.setFont(undefined, "normal"); // Revert to normal font
        yPosition += 6;
        doc.text(`Title: ${selection.title}`, 100, yPosition);
        doc.setFontSize(10); // Revert to default font size

        // Description
        yPosition += 6; // Small vertical space before the description

        const pageWidth = doc.internal.pageSize.width / 2; // Get the page width
        const margin = 10; // Define a margin
        const maxWidth = pageWidth - margin * 2; // Calculate maximum text width

        // Split the text into lines considering the maximum width
        const descriptionLines = doc.splitTextToSize(
          firstSelection.description,
          maxWidth
        );

        // Draw the text starting from 'yPosition', it will automatically wrap
        doc.text(descriptionLines, 100, yPosition);

        // Calculate the increased height, assuming 10 as the line height, adjust as per your requirement
        const addedHeight = descriptionLines.length * 5;

        // Adjust yPosition for subsequent content
        yPosition += addedHeight;
        // doc.text(`Description: ${firstSelection.description}`, 10, yPosition);

        // Image
        if (firstSelection.fileUrl) {
          // Small vetical space before the image
          const imageUrl = `${process.env.REACT_APP_API_URL}/${firstSelection.fileUrl}`;

          // Await the image addition asynchronously
          await fetchImageAndAddToPDF(doc, imageUrl, 10, imagePosition, 40, 40);
          imagePosition += 50; // Increment yPosition by image height + some padding
          yPosition = imagePosition;
        } else {
          await fetchImageAndAddToPDF(
            doc,
            `${window.location.origin}/public/noImage.jpeg`,
            10,
            imagePosition,
            40,
            40
          );

          // If no image, add some space before the next section
          imagePosition += 50;
          yPosition = imagePosition;
        }
      }
    }
    // setSnackbarMessage("Selections successfully downloaded");

    doc.save("selections.pdf");
    setSnackbarMessage("Selections successfully downloaded");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenPDF(false);
  };

  // Handle case where project data is not yet loaded
  if (!project) {
    return <div>Loading...</div>;
  }
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
          {/* Clickable navigation links */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}`)}
          >
            Projects
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}/${projectId}`)}
          >
            {project.address}
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          <Typography variant="h6" component="span" fontWeight="bold">
            Selections
          </Typography>
        </Box>
      </Box>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              marginBottom: "20px", // Adjust the value as needed
            }}
          >
            <Tab
              label={`Pending (${
                selectionData.filter(
                  (item) =>
                    item.status === "WAIT_CLIENT_SELECTION" &&
                    item.selectionResponseDtoList.some(
                      (selection) => selection.selectionStatus === "NONE"
                    )
                ).length +
                selectionData.filter(
                  (item) => item.status === "WAIT_CONTRACTOR_SELECTION"
                ).length
              })`}
            />
            <Tab
              label={`Requests(${
                selectionData.filter(
                  (item) =>
                    item.status === "WAIT_CONTRACTOR_APPROVAL" &&
                    item.selectionResponseDtoList.some(
                      (selection) => selection.selectionStatus === "NONE"
                    )
                ).length
              })`}
            />
            <Tab
              label={`Confirmed (${
                selectionData.filter((item) => item.status === "APPROVED")
                  .length
              })`}
            />
          </Tabs>
          {tabValue === 0 ? (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mr: { md: "30px" } }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenSelectionsForm()}
                sx={{
                  //backgroundColor: "rgba(30, 152, 215, 1)",
                  backgroundColor: "#0b1537",

                  "&:hover": {
                    backgroundColor: "#ffffff",
                    color: "#0b1537",
                    border: 0.5,
                    borderColor: "#0b1537",
                  },
                }}
              >
                <strong>Add Selection</strong>
              </Button>
            </Box>
          ) : null}
          {tabValue === 2 ? (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mr: { md: "30px" } }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const filteredSelectionData = selectionData
                    .map((item) => ({
                      ...item,
                      selectionResponseDtoList:
                        item.selectionResponseDtoList.filter(
                          (selection) =>
                            selection.selectionStatus === "CONFIRMED"
                        ),
                    }))
                    .filter(
                      (item) =>
                        item.status === "APPROVED" &&
                        item.selectionResponseDtoList.length > 0
                    );

                  generatePDF(filteredSelectionData);
                }}
                sx={{
                  backgroundColor: "#0b1537",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    color: "#0b1537",
                    border: 0.5,
                    borderColor: "#0b1537",
                  },
                }}
              >
                <strong>Download PDF</strong>
              </Button>
            </Box>
          ) : null}
        </Box>

        <Box m={4}>
          {tabValue === 0 && (
            <AddFinishingPage
              finishings={addFinishings}
              onAdd={handleAdd}
              onDelete={handleDelete}
              onSend={handleSend}
              setOpen={setOpen}
              selectionData={selectionData.filter(
                (item) =>
                  item.status !== "APPROVED" &&
                  item.status !== "WAIT_CONTRACTOR_APPROVAL"
              )}
              onReload={handleReload}
            />
          )}
          {tabValue === 1 && (
            <AwaitingFinishingPage
              finishings={awaitingFinishings}
              selectionData={selectionData.filter(
                (item) =>
                  item.status === "WAIT_CONTRACTOR_APPROVAL" &&
                  item.selectionResponseDtoList.some(
                    (selection) => selection.selectionStatus === "NONE"
                  )
              )}
              onReload={handleReload}
              // onApprove={handleApprove}
            />
          )}
          {tabValue === 2 && (
            <ConfirmedFinishingPage
              finishings={confirmedFinishings}
              selectionData={selectionData
                .map((item) => ({
                  ...item,
                  selectionResponseDtoList:
                    item.selectionResponseDtoList.filter(
                      (selection) => selection.selectionStatus === "CONFIRMED"
                    ),
                }))
                .filter(
                  (item) =>
                    item.status === "APPROVED" &&
                    item.selectionResponseDtoList.length > 0
                )}
            />
          )}
          {open && (
            <SelectionPopupForm
              isOpen={open}
              setOpen={setOpen}
              fetchSelections={fetchSelections}
            />
          )}
          <Snackbar
            open={openPDF}
            autoHideDuration={6000}
            onClose={handleClose}
            message={snackbarMessage}
            action={
              <React.Fragment>
                {/* <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
              </Button> */}
              </React.Fragment>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FinishingsPage;
