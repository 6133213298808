import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MessageIcon from "@mui/icons-material/Message";
import EngineeringIcon from "@mui/icons-material/Engineering";
import Cookies from "js-cookie";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  notificationCount,
  isCollapsed,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      {!isCollapsed && (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>{title}</Typography>
            {notificationCount > 0 && (
              <Box
                sx={{
                  ml: 1,
                  backgroundColor: "red",
                  color: "white",
                  padding: "2px 5px",
                  borderRadius: "10px",
                  fontSize: "0.2em",
                }}
              >
                <Typography>{notificationCount}</Typography>
              </Box>
            )}
          </div>
        </>
      )}
      <Link to={to} />
      {/* Ensure this doesn't affect layout */}
    </MenuItem>
  );
};

const Sidebar = ({ isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");

  const [user, setUser] = useState([]);
  const [business, setBusiness] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const userId = Cookies.get("userId");

  const [internalMessageCount, setInternalMessageCount] = useState(0);
  const [clientMessageCount, setClientMessageCount] = useState(0);

  const [logos, setLogos] = useState({
    mainLogo: null,
    sidebarLogo: null,
    emailLogo: null,
  });

  //get logos
  useEffect(() => {
    async function fetchLogo() {
      try {
        const response = await fetch(
          `${API_URL}/logo/get-logos?businessId=${Cookies.get("businessId")}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              // If needed: 'X-XSRF-TOKEN': cookie,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const logosMap = data.reduce(
          (acc, logo) => {
            acc[logo.logoType] = logo;
            return acc;
          },
          { mainLogo: null, sidebarLogo: null, emailLogo: null }
        );

        setLogos(logosMap);
      } catch (error) {
        console.error("Failed to fetch logos:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchLogo();
  }, []);

  // Function to fetch the number of internal message notifications
  async function fetchInternalMessageNotifications() {
    try {
      const response = await fetch(
        `${API_URL}/notification/get-number-of-internal-message-notifications/${userId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }

      const data = await response.json(); // Parse the response as JSON
      const count = parseInt(data, 10); // Assuming the entire JSON is just the number
      setInternalMessageCount(count);
    } catch (err) {
      console.error(`Error fetching internal message notifications:`, err);
    }
  }

  // Function to fetch the number of client message notifications
  async function fetchClientMessageNotifications() {
    try {
      const response = await fetch(
        `${API_URL}/notification/get-number-of-client-message-notifications/${userId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }

      const data = await response.json(); // Parse the response as JSON
      const count = parseInt(data, 10); // Assuming the entire JSON is just the number
      setClientMessageCount(count);
    } catch (err) {
      console.error(`Error fetching client message notifications:`, err);
    }
  }

  useEffect(() => {
    fetchInternalMessageNotifications(); // Initial fetch when component mounts
    fetchClientMessageNotifications(); // Initial fetch when component mounts

    const interval = setInterval(() => {
      fetchInternalMessageNotifications(); // Periodic fetch for internal messages
      fetchClientMessageNotifications(); // Periodic fetch for client messages
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  //Get Client User Info
  useEffect(() => {
    async function fetchData() {
      try {
        // Then use it in your fetch call:
        const response = await fetch(
          `${API_URL}/client/get-single-user?userId=${Cookies.get("userId")}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        const data = await response.json();
        setUser(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, []);

  //Get business User Info
  useEffect(() => {
    async function fetchBusiness() {
      try {
        // Then use it in your fetch call:
        const response = await fetch(
          `${API_URL}/business/getBusiness?businessId=${Cookies.get(
            "businessId"
          )}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        const data = await response.json();
        setBusiness(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchBusiness();
  }, []);

  // Function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else return "";
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `#0B1537 !important`, // Ensuring the background color is applied
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          color: "#FFFFFF !important", // Ensuring text color is white
          display: "flex",
          alignItems: "center",
          padding: isCollapsed ? "10px 5px" : "5px 10px", // Adjust padding based on collapsed state
        },
        "& .pro-inner-item:hover": {
          color: "#FBBB1D !important", // Color for hover state
        },
        "& .pro-menu-item.active, & .pro-menu-item.active .pro-inner-item": {
          color: "#FBBB1D !important", // Active item color
        },
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        style={{
          width: isCollapsed ? "80px" : "200px",
          minWidth: isCollapsed ? "80px" : "200px",
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          {/* <Box
            display="flex"
            justifyContent={isCollapsed ? "center" : "flex-end"} // Dynamically adjust alignment
            width="100%" // Ensure it spans the full width of the sidebar
            sx={{ padding: "10px" }} // Apply padding as needed
          >
            <IconButton
              onClick={() => setIsCollapsed(!isCollapsed)}
              sx={{ color: "white" }}
            >
              <MenuOutlinedIcon />
            </IconButton>
          </Box>  */}
          {/* Conditionally render business name and other text elements */}
          {!isCollapsed && (
            <Box sx={{ textAlign: "center", mt: "10px" }}>
              {logos.sidebarLogo ? (
                <img
                  src={`${API_URL}/${logos.sidebarLogo.fileInfoDTO.fileUrl}`}
                  alt="Sidebar Logo"
                  style={{
                    position: "relative",
                    maxWidth: "80%",
                    maxHeight: "80%",
                    maxHeight: "150px",
                    zIndex: 10,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color="#FFFFFF"
                  sx={{
                    textAlign: "center",
                    wordBreak: "break-word",
                    padding: "10px",
                  }}
                >
                  {capitalizeFirstLetter(business.businessName)}
                </Typography>
              )}
            </Box>
          )}

          {/* Menu Items */}
          <Menu iconShape="square">
            <Item
              title="Dashboard"
              to="/Dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              isCollapsed={isCollapsed}
            />

            <Item
              title="Add New Client"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              isCollapsed={isCollapsed}
            />
            <Item
              title="Clients"
              to="/users"
              icon={<EngineeringIcon />}
              selected={selected}
              setSelected={setSelected}
              isCollapsed={isCollapsed}
            />

            <Item
              title="Messages"
              to="/message"
              icon={<MessageIcon />}
              selected={selected}
              setSelected={setSelected}
              isCollapsed={isCollapsed}
              notificationCount={clientMessageCount}
            />
            <Item
              title="Team Chat"
              to="/internalMessage"
              icon={<MessageIcon />}
              selected={selected}
              setSelected={setSelected}
              isCollapsed={isCollapsed}
              notificationCount={internalMessageCount}
            />

            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              isCollapsed={isCollapsed}
            />
          </Menu>
          {/* <Box
              display="flex"
              justifyContent={isCollapsed ? "center" : "flex-end"} // Dynamically adjust alignment
              width="100%" // Ensure it spans the full width of the sidebar
              sx={{ padding: "10px" }} // Apply padding as needed
            > */}
          {/* <Box textAlign="center" marginTop="auto">
            <IconButton
              onClick={() => setIsCollapsed(!isCollapsed)}
              sx={{ color: "white" }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>

            {!isCollapsed && (
              <Box textAlign="center" marginTop="auto">
                <Typography
                  variant="h4"
                  color="#FFFFFF"
                  sx={{ m: "10px 0 10px 0", wordBreak: "break-word" }}
                >
                  {user.firstName} {user.lastName}
                </Typography>
              </Box>
            )}
          </Box> */}

          <Box
            sx={{
              display: "flex", // Use flexbox to lay out children in a row
              justifyContent: "center", // Center children horizontally in the container
              alignItems: "center", // Align items vertically in the center
              marginTop: "auto",
              width: "100%", // Ensure the Box takes full width to allow centering
            }}
          >
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
            >
              {!isCollapsed && (
                <Typography
                  variant="h4"
                  color="#FFFFFF"
                  sx={{ textAlign: "right" }}
                >
                  {capitalizeFirstLetter(user.firstName || "")}{" "}
                  {capitalizeFirstLetter(
                    user.lastName && user.lastName[0] ? user.lastName[0] : ""
                  )}
                </Typography>
              )}
            </Box>

            <IconButton
              onClick={() => setIsCollapsed(!isCollapsed)}
              sx={{ color: "white", mx: 2 }} // Use margin on x-axis for spacing
            >
              {isCollapsed ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
            </IconButton>
          </Box>
        </Box>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
