import React, { useState, useEffect, useInsertionEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  useTheme,
  Grid,
  useMediaQuery,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { tokens } from "../../theme";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AllMessages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [clientName, setClientName] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;
  // using context
  const [project, setProject] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [bottom, setBottom] = useState(true);
  const [page, setPage] = useState(0);
  const employee = Cookies.get("userId");
  const messagesEndRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [searchTerm, setSearchTerm] = useState(""); // State to manage search term
  const [notificationCounts, setNotificationCounts] = useState({});

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handlePagination = async () => {
    setBottom(false);
    setPage(page + 1);
    GetMessages(conversationId, page + 1);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const HandleSwitchConversation = (convId) => {
    setMessages([]);
    setPage(0);
    setConversationId(convId);
    // Check if there are notifications to clear for this conversation
    if (notificationCounts[convId] && notificationCounts[convId] > 0) {
      const newCounts = { ...notificationCounts, [convId]: 0 }; // Reset count for this conversation
      setNotificationCounts(newCounts); // Update the state to reflect this
    }
    GetMessages(convId, 0);
    findClientName();
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "" || selectedImage) {
      const messageDTO = {
        messageId: "",
        conversationId: conversationId,
        senderId: employee,
        messageText: newMessage,
        sentAt: new Date().toISOString(),
        isRead: false,
      };

      //setSelectedImage(null);

      // check to see if employee is sending msg on the correct chat
      const specificConversation = conversations.find(
        (conversation) => conversation.conversationId === conversationId
      );

      if (!specificConversation) {
        return;
      }

      const userIds = specificConversation.participantDTOs.map(
        (participant) => participant.userDTO.userId
      );

      if (userIds.some((userId) => userId === employee)) {
        const sendResponse = await fetch(`${API_URL}/messages/send`, {
          method: "post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
          body: JSON.stringify(messageDTO),
        });

        if (sendResponse.ok && sendResponse.status === 200) {
          const sentMsgData = await sendResponse.json();

          setMessages([...messages, sentMsgData]);
          setNewMessage("");
          // todo: figure out if we still need this since we call scrollToBottom later on
          if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
          }
        } else {
          if (sendResponse.status === 403) {
            alert("You are not authorized to send messages to client. Please contact Admin for help.");
          } else {
            alert("Failed to send the message.");
          }
          console.error("HTTP Error:", sendResponse.status);
        }
      } else {
        alert("You are not a member of this chat");
      }
    }
    scrollToBottom();
  };

  function findClientName() {
    if (conversations.length > 0) {
      // Find the conversation with conversationId
      const conversation = conversations.find(
        (conv) => conv.conversationId === conversationId
      );
      let name = " ";
      if (conversation && conversation.participantDTOs.length > 0) {
        const client = conversation.participantDTOs.find(
          (participant) => participant.userDTO.role === "client"
        );
        name = client
          ? `${client.userDTO.firstName} ${client.userDTO.lastName}`
          : " ";
        setClientName(name);
      }
    }
  }

  function msgUnion(newMsgList) {
    const combined = [...MessagesRef.current, ...newMsgList];
    const uniqueMsgMap = new Map();

    combined.forEach((msg) => uniqueMsgMap.set(msg.messageId, msg));

    return Array.from(uniqueMsgMap.values());
  }

  //gets all the messages for a given chat
  async function GetMessages(convId, page) {
    const msgResponse = await fetch(
      `${API_URL}/messages/getMessages?conversationId=${convId}&userId=${employee}&page=${page}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      }
    );

    // Check if the response status is 200-OK
    if (msgResponse.ok && msgResponse.status === 200) {
      const msgData = await msgResponse.json();

      const msgList = msgUnion(msgData.content);

      const sortedMessages = msgList.sort((a, b) => {
        return new Date(a.sentAt) - new Date(b.sentAt);
      });

      setMessages(sortedMessages);
    } else {
      // Handle non-200 responses
      if (msgResponse.status === 403) {
        alert("You are not authorized to read client messages. Please contact Admin for help.");
      }
      console.error("HTTP Error:", msgResponse.status);
    }
  }

  // getting conversations, messages
  useEffect(() => {
    //todo: add api call to get projectOwner name

    async function fetchData() {
      // getting list of all conversations
      const allChatsResponse = await fetch(
        `${API_URL}/messages/AllChats?employeeId=${employee}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );

      // check to see if we have chats for this employee
      if (allChatsResponse.ok && allChatsResponse.status === 200) {
        const chatsData = await allChatsResponse.json();

        if (chatsData.length > 0) {
          const sortedConvo = chatsData.sort((a, b) => {
            const dateA = a.lastMessageTimeStamp
              ? new Date(a.lastMessageTimeStamp)
              : new Date(0);
            const dateB = b.lastMessageTimeStamp
              ? new Date(b.lastMessageTimeStamp)
              : new Date(0);

            return dateB - dateA; // Descending order: newer dates first
          });

          setConversations(sortedConvo);
          setConversationId(sortedConvo[0].conversationId);

          // getting messages from a conversation
          const msgResponse = await fetch(
            `${API_URL}/messages/getMessages?conversationId=${chatsData[0].conversationId}&userId=${employee}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
              },
            }
          );

          if(msgResponse.ok && msgResponse.status === 200){
            const msgData = await msgResponse.json();

            const sortedMessages = msgData.content.sort((a, b) => {
              return new Date(a.sentAt) - new Date(b.sentAt);
            });
            setMessages(sortedMessages);
            findClientName();
          } else {
            if (msgResponse.status === 403) {
              alert("You are not authorized read client messages. Please contact Admin for help.");
            }
          }
        }
      } else {
          if (allChatsResponse.status === 403) {
            alert("You are not authorized read client messages. Please contact Admin for help.");
          }
      }
    } 

    fetchData();
  }, []);

  // Get Notification Info
  useEffect(() => {
    async function fetchNotificationData() {
      const response = await fetch(
        `${API_URL}/notification/get-notifications-for-user/${employee}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }

      const data = await response.json();
      const newCounts = {};

      data.forEach((notification) => {
        if (notification.eventType === "EVENT_TYPE_7" && !notification.viewed) {
          const { eventId } = notification; // eventId is the conversationId for these notifications
          newCounts[eventId] = (newCounts[eventId] || 0) + 1;
        }
      });

      setNotificationCounts(newCounts);
    }

    fetchNotificationData();
  }, [employee, API_URL]);

  const MessagesRef = useRef(messages);

  useEffect(() => {
    MessagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    findClientName();
  }, [conversationId, conversations]);

  useInsertionEffect(() => {
    // Set an interval to fetch messages every 30 seconds
    const intervalId = setInterval(() => {
      GetMessages(conversationId, 0);
    }, 30000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [conversationId]);

  const scrollToBottom = () => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    if (bottom) {
      scrollToBottom();
    } else {
      setBottom(true);
    }
  }, [messages]);

  // // Filter users based on search term
  // const filteredUsers = users.filter((user) =>
  //   `${user.firstName} ${user.lastName}`
  //     .toLowerCase()
  //     .includes(searchTerm.toLowerCase())
  // );

  const filterConversationsByClientName = (conversations, searchTerm) => {
    const term = searchTerm.toLowerCase();
    return conversations.filter((conversation) =>
      conversation.participantDTOs.some(
        (participant) =>
          participant.userDTO.role.toLowerCase() === "client" &&
          `${participant.userDTO.firstName} ${participant.userDTO.lastName}`
            .toLowerCase()
            .includes(term)
      )
    );
  };

  const filteredConversations = filterConversationsByClientName(
    conversations,
    searchTerm
  );

  // Handle case where conversation data is not yet loaded
  if (conversations === null) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      ></Box>
      {/* Search Input */}
      <TextField
        label="Search Messages"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ m: 1, width: "300px" }}
      />
      {!isMobile && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginRight="25px"
        >
          <Typography variant="h4" fontWeight="600" mb="20px">
            Messages from {clientName}
          </Typography>
        </Box>
      )}
      <Grid container spacing={3}>
        {/* <Box display="flex" gap="10px" marginRight="25px"> */}
        {/* left box displays list of conversations */}
        <Grid item xs={12} md={2}>
          <Box
            sx={{
              p: "5px",
              borderRadius: "8px",
              backgroundColor: colors.primary[400],
              display: "flex",
              flexDirection: "column",
              columnGap: "5px",
              rowGap: "5px",
              height: isMobile ? "300px" : "68vh",
              minHeight: "300px",
              overflowY: "auto",
            }}
          >
            <List>
              {conversations.length > 0 ? (
                filteredConversations.map((conversation) => (
                  <ListItem
                    key={conversation.conversationId}
                    button
                    selected={conversation.conversationId === conversationId}
                    onClick={() =>
                      HandleSwitchConversation(conversation.conversationId)
                    }
                    sx={{
                      "&:hover": {
                        backgroundColor: colors.hoverColor, // Your hover color
                      },
                      backgroundColor:
                        conversation.conversationId === conversationId
                          ? colors.selectedColor // Your selected color
                          : "inherit",
                    }}
                  >
                    {/* <ListItemText
                      primary={
                        conversation.participantDTOs[0].userDTO.role ===
                        "client"
                          ? `${conversation.participantDTOs[0].userDTO.firstName}  ${conversation.participantDTOs[0].userDTO.lastName}`
                          : `${conversation.participantDTOs[0].userDTO.firstName}  ${conversation.participantDTOs[0].userDTO.lastName}`
                      }
                    /> */}
                    <ListItemText
                      primary={
                        conversation.participantDTOs.find(
                          (participant) =>
                            participant.userDTO.role.toLowerCase() === "client"
                        )
                          ? `${
                              conversation.participantDTOs.find(
                                (participant) =>
                                  participant.userDTO.role.toLowerCase() ===
                                  "client"
                              ).userDTO.firstName
                            } ${
                              conversation.participantDTOs.find(
                                (participant) =>
                                  participant.userDTO.role.toLowerCase() ===
                                  "client"
                              ).userDTO.lastName
                            }`
                          : `${conversation.participantDTOs[0].userDTO.firstName} ${conversation.participantDTOs[0].userDTO.lastName}`
                      }
                    />

                    {notificationCounts[conversation.conversationId] > 0 && (
                      <Box
                        component="span"
                        sx={{
                          display: "inline-block",
                          ml: 1,
                          width: 10,
                          height: 10,
                          bgcolor: "red",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </ListItem>
                ))
              ) : (
                <Typography variant="body1">No chats available.</Typography>
              )}
            </List>
          </Box>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="600" mb="0px">
              Messages from {clientName}
            </Typography>
          </Grid>
        )}

        {/* Right box displays all message of given conversation */}
        <Grid item xs={12} md={10}>
          <Box
            sx={{
              p: "20px",
              borderRadius: "8px",
              backgroundColor: colors.primary[400],
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: isMobile ? "300px" : "68vh",
              minHeight: "300px",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <Typography
              variant="body1"
              color="#3366CC"
              onClick={handlePagination}
            >
              click to load older messages.
            </Typography>
            {messages.length > 0 ? (
              messages.map((message) => (
                <Box
                  key={message.messageId}
                  backgroundColor={
                    message.senderId === employee
                      ? colors.primary[300]
                      : colors.blueAccent[300]
                  }
                  borderRadius="8px"
                  padding="10px"
                  maxWidth="80%"
                  alignSelf={
                    message.senderId === employee ? "flex-end" : "flex-start"
                  } // Updated positioning
                >
                  {
                    // commented out the logic the could be used to display images for now
                    //message.type === "text" ?
                    //(
                    /*
                : 
                (
                  <img
                    src={message.content}
                    alt="Sent Image"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )
                */
                  }
                  <Typography
                    variant="body1"
                    color="white"
                    sx={{ wordWrap: "break-word" }}
                  >
                    {message.messageText}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: "gray", fontSize: "0.75rem", mt: 1 }}
                  >
                    {new Date(message.sentAt).toLocaleString()}{" "}
                    {/* Formatted timestamp */}
                  </Typography>
                  <div ref={messagesEndRef} />
                </Box>
              ))
            ) : (
              <Typography variant="body1">No messages available.</Typography>
            )}
          </Box>
        </Grid>
        {/* </Box> */}
      </Grid>

      <Box display="flex" gap="10px" mt="20px" marginRight="25px">
        <TextField
          value={newMessage}
          onChange={handleNewMessageChange}
          label="New Message"
          variant="outlined"
          focused
          fullWidth
          multiline
          maxRows={4}
          inputProps={{
            style: {
              overflow: "auto",
              fontSize: "16px",
            },
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
              e.preventDefault();
            }
          }}
        />
        {/*
        <label htmlFor="image-upload">
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <IconButton
            color="primary"
            component="span"
            style={{ color: colors.blueAccent[500] }}
          >
            <PhotoCameraIcon />
          </IconButton>
        </label>
        */}
        <IconButton
          color="primary"
          onClick={handleSendMessage}
          style={{ color: colors.blueAccent[500] }}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AllMessages;
