import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
    const [userName, setUserName] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const API_URL = process.env.REACT_APP_API_URL;


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/ForgotPassword/request?userName=${userName}`, 
            {
                withCredentials: true,
                headers: {
                  'X-XSRF-TOKEN': Cookies.get("XSRF-TOKEN")
                },
            }
        );
            setMessage(response.data);
        } catch (error) {
            setMessage('Error sending password reset request');
        }
        setUserName("");
    };

    const navigateToLogin = () =>{
        navigate("/");
    };

    return (
        <Box sx={{ maxWidth: 300, mx: "auto", my: 4 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mx: "auto", mb: 2 }}>
                <Typography variant="h5" mb={2}>Forgot Password</Typography>
                <TextField
                    label="userName"
                    type="userName"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{ input: { fontSize: '16px' } }}
                />
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mb: 2 }} fullWidth>
                    Request Password Reset
                </Button>
                {message && <Typography color="textSecondary" mt={2}>{message}</Typography>}
            </Box>
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mb: 2 }} onClick={navigateToLogin} fullWidth>
                BACK TO LOGIN
            </Button>
        </Box>
    );
}

export default ForgotPassword;