import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  Divider,
  Grid,
} from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NoteIcon from "@mui/icons-material/Note";
import HandymanIcon from "@mui/icons-material/Handyman";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { mockClientData } from "../../data/mockClientData.js";
import Message from "../message/Message";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { LensTwoTone } from "@mui/icons-material";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const ClientDashboard = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [business, setBusiness] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [notification, setNotification] = useState(null);
  const [eventTypeCounts, setEventTypeCounts] = useState({});
  const [error, setError] = useState(null);
  const [employee, setEmployee] = useState([]);
  const [isProjectLoaded, setIsProjectLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let ownerId;

  const API_URL = process.env.REACT_APP_API_URL;
  console.log("dash pid: " + projectId);

  const navigateToTasks = () => {
    navigate(`/ClientTodos/` + projectId);
  };

  const navigateToFinishings = () => {
    navigate(`/ClientFinishings/` + projectId);
  };

  const navigateToDocument = () => {
    navigate(`/ClientDocSharing/` + projectId);
  };

  const navigateToUpdates = () => {
    navigate(`/ClientUpdates/` + projectId);
  };

  const navigateToInvoices = () => {
    navigate(`/ClientInvoices/` + projectId)
  }

  useEffect(() => {
    //todo: add api call to get projectOwner name
    async function APICalls() {
      const busiInfoResponse = await fetch(
        `${API_URL}/client-business/businessInfo`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );
      const busData = await busiInfoResponse.json();
      setBusiness(busData);
      setIsLoading(false);
    }
    APICalls();
  }, [projectId]);

  useEffect(() => {
    async function fetchProjectData() {
      const projInfoResponse = await fetch(
        `${API_URL}/projectInfo/projectId?projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );
      const projData = await projInfoResponse.json();

      setProject(projData);
      setIsProjectLoaded(true);
      // ownerId = project.ownerId;
    }
    fetchProjectData();
  }, [projectId]);

  // // Use another useEffect or useMemo for dependent code
  // useEffect(() => {
  //   if (project) {
  //     ownerId = project.ownerId;
  //     // Code that depends on 'project' state
  //   }
  // }, [project]);

  //Get Client User Info
  useEffect(() => {
    async function fetchData() {
      try {
        // Then use it in your fetch call:
        const response = await fetch(
          `${API_URL}/clientPortal-user/get-single-user?userId=${project.ownerId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        const data = await response.json();
        setEmployee(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    if (isProjectLoaded) {
      fetchData();
    }
  }, [isProjectLoaded, project]);

  // Get Notification Info
  useEffect(() => {
    async function fetchNotificationData() {
      try {
        const response = await fetch(
          `${API_URL}/client-notification/get-notifications-for-project/${projectId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Network response was not ok ${response.statusText}`);
        }

        const data = await response.json();
        setNotification(data);
        // setLoading(false);

        const eventTypeCounts = {};

        for (let i = 0; i < data.length; i++) {
          const notification = data[i];
          const eventType = notification.eventType;

          // Initialize count for this event type to 0 if it's the first occurrence
          if (!eventTypeCounts[eventType]) {
            eventTypeCounts[eventType] = 0;
          }

          // Increment count for this event type
          eventTypeCounts[eventType] += 1;
        }

        setEventTypeCounts(eventTypeCounts);

        console.log(eventTypeCounts);
      } catch (err) {
        console.error(`Error fetching data for projectId: ${projectId} `, err);
        setError(err);
        // setLoading(false);
      }
    }
    fetchNotificationData();
  }, [projectId]);

  function renderNotificationCount(EVENT_TYPE) {
    if (eventTypeCounts[EVENT_TYPE] > 0) {
      return (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "red",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {eventTypeCounts[EVENT_TYPE]}
        </div>
      );
    }
    return null;
  }

  const selectionCount = () => {

    let count = 0;
    if (eventTypeCounts["EVENT_TYPE_2"] > 0 ||  eventTypeCounts["EVENT_TYPE_12"] > 0 || eventTypeCounts["EVENT_TYPE_11"] > 0) {
      if (eventTypeCounts["EVENT_TYPE_2"] > 0) {
        count += eventTypeCounts["EVENT_TYPE_2"];
      }
      if (eventTypeCounts["EVENT_TYPE_12"] > 0) {
        count += eventTypeCounts["EVENT_TYPE_12"];
      }
      if (eventTypeCounts["EVENT_TYPE_11"] > 0) {
        count += eventTypeCounts["EVENT_TYPE_11"];
      }
      return (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "red",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {count}
        </div>
      );
    }
    return null;

  } 

  // Handle case where project data is not yet loaded
  if (!project) {
    return <div>Loading...</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={project.address} />
      </Box>
      {/* GRID & CHARTS */}
      <Grid container spacing={5}>
        {/* ROW 1 */}
        <Grid item xs={12} >
          <Card
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              backgroundColor: colors.primary[400],
              color: colors.grey[50],
              borderRadius: 1,
              p: 3,
              width: "100%",
              boxShadow: 3,
            }}
          >
            <CardContent>
              <Typography variant="h5" fontWeight="bold">
                Project Details
              </Typography>
              <Divider />
              <Typography variant="body1" sx={{ mt: 2 }}>
                <Box component="span" fontWeight="bold">
                  Address:{" "}
                </Box>
                {project.address}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <Box component="span" fontWeight="bold">
                  Start date:{" "}
                </Box>
                {project.startDate}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <Box component="span" fontWeight="bold">
                  End date:{" "}
                </Box>
                {project.endDate}
              </Typography>
            </CardContent>
            <Divider orientation="vertical" flexItem />
            <CardContent>
              {business && (
                <Typography variant="h5" fontWeight="bold">
                  {business.businessName} Contact Info
                </Typography>
              )}
              <Divider />
              <Typography variant="body1" sx={{ mt: 2 }}>
                <Box component="span" fontWeight="bold">
                  Project Coordinators Name:{" "}
                </Box>
                {employee.firstName} {employee.lastName}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <Box component="span" fontWeight="bold">
                  Business Contact number:{" "}
                </Box>
                {business.phoneNumber}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <Box component="span" fontWeight="bold">
                  Business Contact email:{" "}
                </Box>
                {business.email}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* ROW 2 */}
        {/* ROW 2 */}
        <Grid item xs={12}>
            <Message projectId={projectId} />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            p="30px"
            onClick={navigateToTasks}
            sx={{ cursor: "pointer", borderRadius: "5px" }}
          >
            {/* Wrapping Container */}
            <Box
              position="relative"
              backgroundColor={colors.primary[400]}
              p="15px"
              width="100%"
              height="100%"
            >
              {/* Notification Count */}
              {renderNotificationCount("EVENT_TYPE_3")}
              <Typography variant="h5" fontWeight="600">
                Assigned Tasks
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                <FormatListBulletedIcon
                  size="125"
                  sx={{ width: "150px", height: "150px" }}
                />
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: "15px" }}
                ></Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            p="30px"
            onClick={navigateToFinishings}
            sx={{ cursor: "pointer", borderRadius: "5px" }}
          >
            {/* Wrapping Container */}
            <Box
              position="relative"
              backgroundColor={colors.primary[400]}
              p="15px"
              width="100%"
              height="100%"
            >
              {/* Notification Count */}
              {selectionCount()}
              <Typography variant="h5" fontWeight="600">
                Selections
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                <HandymanIcon
                  size="125"
                  sx={{ width: "150px", height: "150px" }}
                />
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: "15px" }}
                ></Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            p="30px"
            onClick={navigateToUpdates}
            sx={{ cursor: "pointer", borderRadius: "5px" }}
          >
            {/* Wrapping Container */}
            <Box
              position="relative"
              backgroundColor={colors.primary[400]}
              p="15px"
              width="100%"
              height="100%"
            >
              {/* Notification Count */}
              {renderNotificationCount("EVENT_TYPE_5")}
              <Typography variant="h5" fontWeight="600">
                Updates
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                <NotificationsActiveIcon
                  size="125"
                  sx={{ width: "150px", height: "150px" }}
                />
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: "15px" }}
                ></Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            p="30px"
            onClick={navigateToDocument}
            sx={{ cursor: "pointer", borderRadius: "5px" }}
          >
            {/* Wrapping Container */}
            <Box
              position="relative"
              backgroundColor={colors.primary[400]}
              p="15px"
              width="100%"
              height="100%"
            >
              {/* Notification Count */}
              {renderNotificationCount("EVENT_TYPE_4")}
              <Typography variant="h5" fontWeight="600">
                Documents
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                <DocumentScannerIcon
                  size="125"
                  sx={{ width: "150px", height: "150px" }}
                />
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: "15px" }}
                ></Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            p="30px"
            onClick={navigateToInvoices}
            sx={{ cursor: "pointer", borderRadius: "5px" }}
          >
            {/* Wrapping Container */}
            <Box
              position="relative"
              backgroundColor={colors.primary[400]}
              p="15px"
              width="100%"
              height="100%"
            >
              {/* Notification Count */}
              {renderNotificationCount("EVENT_TYPE_16")}
              <Typography variant="h5" fontWeight="600">
                Invoices
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                <ReceiptLongIcon
                  size="125"
                  sx={{ width: "150px", height: "150px" }}
                />
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: "15px" }}
                ></Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default ClientDashboard;
