import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { tokens } from "../../theme";
import { useTheme } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import DialogActions from "@mui/material/DialogActions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import Cookies from "js-cookie";
import ButtonTypography from "../../generic/ButtonTypography";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";



const NotesPage = () => {
  // Variables from hooks
  const { clientUserId } = useParams();
  const { projectId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  // States
  const [project, setProject] = useState(null);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [editMode, setEditMode] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const fileInputRef = useRef(null); // Ref for the file input

  const colors = tokens(theme.palette.mode);

  const API_URL = process.env.REACT_APP_API_URL;

  //Get Project Info
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );
        const data = await response.json();
        setProject(data);
      } catch (err) {
        console.error(`Error fetching data for userId ${projectId}:`, err);
      }
    };
    fetchProjectData();
  }, [projectId]);

  // method to get project notes
  // its a method because so we can use it at different points to refresh the updates list
  const fetchInternalNotes = async () => {
    try {
      const response = await axios.get(`${API_URL}/internalNotes/get-notes/${projectId}`, {
        withCredentials: true,
        withXSRFToken: true,
        headers: {
          Accept: "application/json",
          //'X-XSRF-TOKEN': cookie
        },
      });
  
      const data = response.data;

      const projectNotes = Array.isArray(data)
        ? data
            .map((note) => ({ ...note }))
            .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort notes by created date, newest first
        : [];

      if (projectNotes.length > 0) {
        setSelectedNote(projectNotes[0]);
      } else {
        setSelectedNote(null);
      }

      setNotes(projectNotes);
    } catch (error) {
      console.error("Failed to fetch project notes:", error);
      // Handle error appropriately here
      if (error.response ) {
        if (error.response.status === 403){
          alert("You are not authorized to read internal Notes. Please contact Admin for help.");
        }
      }
    }
  };

  //get all notes using the fetch notes
  useEffect(() => {
    fetchInternalNotes();
  }, [projectId]);

  //Get Client User Info
  useEffect(() => {
    async function fetchData() {
      try {
        // Then use it in your fetch call:
        const response = await fetch(
          `${API_URL}/client/get-single-user?userId=${clientUserId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        const data = await response.json();
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, [clientUserId]);

  // Handle setting the selected note in case notes are empty or the selected note doesn't exist
  useEffect(() => {
    if (notes.length === 0) {
      setSelectedNote(null);
    } else if (
      !selectedNote ||
      !notes.find((note) => note.internalNoteId === selectedNote.internalNoteId)
    ) {
      setSelectedNote(notes[0]);
    }
  }, [notes]);

  // Update a specific note field and persist the changes in the state
  const handleNoteUpdate = (field, value) => {
    if (selectedNote.internalNoteId !== editMode) return;
    const updatedNote = { ...selectedNote, [field]: value };
    setNotes(
      notes.map((note) =>
        note.internalNoteId === selectedNote.internalNoteId ? updatedNote : note
      )
    );
    setSelectedNote(updatedNote);
  };

  // Create a new note
  const handleNewNote = async () => { 
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INTERNAL_NOTES)){
      alert("You are not authorized to create or modify internal notes. Please contact Admin for help.");
      return;
    }

    const newNote = {
      internalNoteId: Date.now(),
      projectId: project.projectId,
      title: "",
      description: "",
      images: [],
      createdDate: new Date().toISOString().split("T")[0],
    };
    setNotes([newNote, ...notes]);
    setSelectedNote(newNote);
    setEditMode(newNote.internalNoteId);
  };

  const handleFileUpload = (event) => {
    if (!event || !event.target || !event.target.files) return;
    const files = Array.from(event.target.files);
    if (files.length === 0) return;
  
    // Assuming you're handling image URLs directly for display purposes
    const newImages = files.map((file) => URL.createObjectURL(file));
  
    // Ensure selectedNote and selectedNote.images are defined
    const existingImages = selectedNote?.images ? selectedNote.images : [];
  
    const updatedImages = [...existingImages, ...newImages];
  
    // Create a new note object for the update to trigger a re-render
    const updatedNote = { ...selectedNote, images: updatedImages };
  
    // Update the notes array with the updated note
    const updatedNotes = notes.map((note) => 
      note.internalNoteId === selectedNote.internalNoteId ? updatedNote : note
    );
  
    // Update the state with the new notes array and updated selectedNote
    setNotes(updatedNotes);
    setSelectedNote(updatedNote);
  };

  // Handle click on an image
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  // Function to select a note
  const handleSelectNote = (note) => {
    setSelectedNote(note);
    setCarouselIndex(0);
  };

  // Function to handle switching to edit mode
  const handleEditMode = async () => {
    //DONT HARDCODE
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INTERNAL_NOTES)){
      alert("You are not authorized to create or modify internal notes. Please contact Admin for help.");
      return;
    }
    setEditMode(selectedNote.internalNoteId);
  };

  // Function to handle submission and exit the edit mode
  const handleSubmit = async (selectedNote) => {
    try {
      // 1. Gather all the files to be uploaded from selectedNote.images
      let files = [];

      if (selectedNote.images) {
        const filePromises = selectedNote.images.map((imageUrl) =>
          fetch(imageUrl)
            .then((res) => res.blob())
            .then(
              (blob) =>
                new File(
                  [blob],
                  imageUrl.substring(imageUrl.lastIndexOf("/") + 1)
                )
            )
        );

        files = await Promise.all(filePromises);
      }
      // 2. Constructing ProjectUpdateDTO

      const internalNoteDTO = {
        userId: clientUserId,
        projectId: projectId,
        businessId: Cookies.get("businessId"),
        date: selectedNote.createdDate,
        title: selectedNote.title,
        description: selectedNote.description,
        fileInfoDTOs: selectedNote.fileInfoDTOs,
        internalNoteId: selectedNote.internalNoteId,
      };

      // 3. Create a new FormData instance
      const formData = new FormData();

      // 4. Append each file and the ProjectUpdateDTO to the FormData instance
      if (files && Array.isArray(files) && files.length > 0) {
        files.forEach((file) => {
          formData.append("files", file);
        });
      }
      formData.append("internalNoteDTO", JSON.stringify(internalNoteDTO));

      let response;

      // Fetch internal note for the given projectId to check if internal note already exists
      const checkUrl = `${API_URL}/internalNotes/get-notes/${projectId}`;

      const checkResponse = await axios.get(checkUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          //'X-XSRF-TOKEN': cookie
        },
      });

      const exists = checkResponse.data.some(
        (item) => item.internalNoteId === selectedNote.internalNoteId
      );

      // Assuming the backend returns an empty list if no updates exist
      if (exists) {
        // Modify existing update

        const modifyUrl = `${API_URL}/internalNotes/modify`;
        response = await axios.put(modifyUrl, formData, {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        // Create new update
        const createUrl = `${API_URL}/internalNotes/create`;
        response = await axios.post(createUrl, formData, {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (response.status === 200) {
        console.log(
          exists
            ? "Internal Note modified successfully"
            : "Internal Note created successfully"
        );
        setEditMode(null);
      }

      fetchInternalNotes();

      // not sure why but we fetched instead of just adding the returned value to the notes list
      // this makes us loose track of what not we just saved on the UI
      // hack to make sure we remain on the same note after hitting save
      if(response.status === 200){
        setSelectedNote(response.data);
      }

    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to create or modify internal notes. Please contact Admin for help.");
        } else if (error.response.status === 500 && error.response.data.message === "Maximum upload size exceeded") {
          alert("Upload failed: The file size exceeds the maximum limit of 10MB. Please select a smaller file and try again.");
        } else if(error.response.status === 413) {
          alert("Upload failed: The file size exceeds the maximum limit of 10MB. Please select a smaller file and try again.");
        } else if (error.response.status === 409){
          alert(error.response.data.message);
        } else {
          alert(error.response.data.message);
          console.error("An unexpected error occurred when creating this note:  ", error);
          alert("An unexpected error occurred while creating this note");

        } 
      } else {
        alert(error.response.data.message);
        console.error("An unexpected error occurred when creating this note:  ", error);
        alert("An unexpected error occurred while creating this note");
      }
    }
  };

  // Function to format date
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  };

  // Function to handle delete action
  const handleDelete = async (note) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INTERNAL_NOTES)){
      alert("You are not authorized to delete internal notes. Please contact Admin for help.");
      return;
    }

    setNoteToDelete(note);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      // Close the confirmation dialog
      setDeleteDialogOpen(false);

      if(typeof noteToDelete.internalNoteId === 'number') {
        // since id is a number and not a uuid string, this means it has never been saved
        // dropping from notes list
        const newNotes = notes.filter(
          (n) => n.internalNoteId !== noteToDelete.internalNoteId
        );
        setNotes(newNotes);
        return;
      }

      // Make DELETE request to your backend
      await axios.delete(
        `${API_URL}/internalNotes/delete/${noteToDelete.internalNoteId}`,
        {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      // Remove the note from the local state (or refetch all notes)
      const newNotes = notes.filter(
        (n) => n.internalNoteId !== noteToDelete.internalNoteId
      );
      setNotes(newNotes);
    } catch (error) {
      console.error("Failed to delete project update:", error);
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to delete internal Notes. Please contact Admin for help.");
        }
      }

    }
  };

  // Function to handle the cancellation of delete action
  const handleCancelDelete = () => {
    setNoteToDelete(null);
    setDeleteDialogOpen(false);
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  // Function to render the image carousel
  const renderImageCarousel = () => {
    let totalImages = selectedNote?.fileInfoDTOs?.length || 0;
    let imageUrls = []
    if(totalImages > 0){
      imageUrls = selectedNote.fileInfoDTOs.map(fileInfo => `${API_URL}/${fileInfo.fileUrl}`);
    }
    
    if ( selectedNote?.images?.length > 0) {
      totalImages += selectedNote?.images?.length;
      imageUrls = [...imageUrls, ...selectedNote.images];
    }
    const isPrevDisabled = carouselIndex <= 0;
    const isNextDisabled = carouselIndex >= totalImages - 1;

    const handlePrevClick = () => {
      if (!isPrevDisabled) {
        setCarouselIndex((prevIndex) => prevIndex - 1);
      }
    };

    const handleNextClick = () => {
      if (!isNextDisabled) {
        setCarouselIndex((prevIndex) => prevIndex + 1);
      }
    };

    const handleDeleteImage = async (imageIndex) => {
      // Check if fileInfoDTO exists and has the fileId property for deletion
      const fileInfoDTO = selectedNote.fileInfoDTOs && selectedNote.fileInfoDTOs.length > imageIndex ? selectedNote.fileInfoDTOs[imageIndex] : null;
      if (fileInfoDTO && fileInfoDTO.fileId) {
        try {
          // Call the delete API only if fileInfoDTO.fileId exists
          await axios.delete(`${API_URL}/internalNotes/delete-fileInfo/${fileInfoDTO.fileId}`, {
            withCredentials: true,
            withXSRFToken: true,
            headers: {
              Accept: "application/json",
            },
          });
    
          // Filter out the deleted fileInfoDTO and update state accordingly
          const updatedFileInfoDTOs = selectedNote.fileInfoDTOs.filter((_, idx) => idx !== imageIndex);
          const updatedNote = { ...selectedNote, fileInfoDTOs: updatedFileInfoDTOs };
    
          setNotes(notes.map(note => note.internalNoteId === selectedNote.internalNoteId ? updatedNote : note));
          setSelectedNote(updatedNote);
        } catch (error) {
          console.error("Error deleting fileInfo and file:", error);
          // Handle error appropriately here
          if (error.response) {
            if (error.response.status === 403){
              alert("You are not authorized to modify internal Notes. Please contact Admin for help.");
            }
          }
        }
      } else {
        // If fileId does not exist, remove the image locally from selectedNote.images
        console.log("FileId does not exist, removing image locally from images.");
        if (selectedNote.images && selectedNote.images.length > imageIndex) {
          const updatedImages = selectedNote.images.filter((_, idx) => idx !== imageIndex);
          const updatedNote = { ...selectedNote, images: updatedImages };
    
          setNotes(notes.map(note => note.internalNoteId === selectedNote.internalNoteId ? updatedNote : note));
          setSelectedNote(updatedNote);
        }
      }
    };
    
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 1,
          mb: 0.5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={handlePrevClick}
            disabled={isPrevDisabled}
            sx={{
              opacity: isPrevDisabled ? 0.3 : 1,
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            }}
            aria-label="Previous image"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Box sx={{ display: "flex", overflow: "hidden", maxWidth: "80%", height: "200px", position: "relative" }}>
            {imageUrls[carouselIndex] && (
              <>
                <img
                  src={imageUrls[carouselIndex]}
                  alt={`Attachment ${carouselIndex}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    height: "auto",
                    objectFit: "contain",
                    cursor: "pointer",
                    margin: "auto",
                  }}
                  onClick={() =>
                    handleImageClick(imageUrls[carouselIndex])
                  }
                />
                {selectedNote && selectedNote.internalNoteId === editMode && (
                  <>
                  <IconButton
                    onClick={() => handleDeleteImage(carouselIndex)}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(255,255,255,0.8)",
                      '&:hover': {
                        backgroundColor: "rgba(255,255,255,1)",
                      },
                    }}
                    size="small"
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                  </>
                )}
              </>
            )}
          </Box>
          <IconButton
            onClick={handleNextClick}
            disabled={isNextDisabled}
            sx={{
              opacity: isNextDisabled ? 0.3 : 1,
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            }}
            aria-label="Next image"
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center",}}>
          {Array.from({ length: totalImages }, (_, index) => (
            <div
              key={index}
              style={{
                height: "10px",
                width: "10px",
                backgroundColor:
                  index === carouselIndex ? "black" : "lightgray",
                borderRadius: "50%",
                margin: "5px",
              }}
            />
          ))}
        </Box>
      </Box>
    );
  };

  const navigateTo = (path) => () => {
    navigate(path);
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
          {/* Clickable navigation links */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}`)}
          >
            Projects
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}/${projectId}`)}
          >
            {project.address}
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          <Typography variant="h6" component="span" fontWeight="bold">
            Internal Notes
          </Typography>
        </Box>
      </Box>
      <Box mx="20px" sx={{ backgroundColor: colors.MoonGrey[200], boxShadow:3, borderRadius:1, minHeight:"90vh" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "0.25em" }}>
          <Button
            onClick={handleNewNote}
            sx={{
              mt: "15px",
              ml: "10px",
              backgroundColor: colors.MoonYellow[100],
              color: colors.white[500],
              ':hover': { // Hover state styles
                backgroundColor: colors.white[500], // Hover state background color
                color: colors.MoonYellow[100], // Change text color on hover (optional)
                border: 0.5,
                borderColor: colors.MoonYellow[100],
              },
              padding: "5px 25px",
              borderRadius: 1,
              boxShadow: "(3px 3px 5px 2px)",
              textTransform: "none",
              fontWeight: "bold",
              fontSize: "12px",
              letterSpacing: "0.5px",
            }}
          >
            <ButtonTypography > <AddIcon sx={{mr: "8px"}}/>  NEW NOTE </ButtonTypography>
          </Button>
        </Box>
        <Grid container spacing={1} sx={{mt:".1px"}}>
          <Grid item xs={12} md={4}>
            <Paper
              style={{ maxHeight: "50vh", overflow: "auto" }}
              sx={{ mx: "10px" }}
            >
              {notes.map((note, index) => (
                <MenuItem
                  key={note.internalNoteId}
                  selected={
                    selectedNote &&
                    note.internalNoteId === selectedNote.internalNoteId
                  }
                  onClick={() => handleSelectNote(note)}
                >
                  <ListItemAvatar>
                    {note.fileInfoDTOs && note.fileInfoDTOs.length > 0 ? (
                      <Avatar
                        variant="rounded"
                        src={`${API_URL}/${note.fileInfoDTOs[0].fileUrl}`}
                        alt={note.title}
                      />
                    ) : (
                      // Render some default content if fileInfoDTOs is null or empty
                      <Avatar variant="rounded">N/A</Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={note.title}
                    primaryTypographyProps={{
                      style: {
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      },
                    }}
                    secondary={`Created: ${formatDate(note.date)}`}
                  />
                  {selectedNote &&
                    note.internalNoteId === selectedNote.internalNoteId && (
                      <Box>
                        <IconButton
                          onClick={() => handleEditMode(note.internalNoteId)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(note)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                </MenuItem>
              ))}
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={8} sx={{ ml:window.innerWidth <=768?"10px":null,px: "10px" }}>
            {selectedNote && selectedNote.internalNoteId === editMode && window.innerWidth <=768 &&(
              <Box sx={{ 
                //marginTop: "1em",
                //marginBottom: "1em",
                my: "10px",
                display: 'flex',
                justifyContent: 'space-between',
                }}>
                <Button
                  //variant="contained"
                  onClick={triggerFileInput}
                  sx={{
                    backgroundColor: colors.white[500],
                    padding: "5px 25px",
                    borderRadius: 1,
                    border: 0.5,
                    borderColor: colors.MoonGrey[100], 
                    boxShadow: "(3px 3px 5px 2px)",
                    textTransform: "none",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                >
                  <ButtonTypography sx={{color:colors.MoonBlue[100]}}>UPLOAD IMAGES</ButtonTypography>
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  multiple
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                />

                <Button
                  //variant="contained"
                  //color=colors.white[500]
                  onClick={() => {
                    if (selectedNote) handleSubmit(selectedNote);
                  }}
                  sx={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    backgroundColor: colors.MoonGreen[100],
                    color: colors.white[500],
                    ':hover': { // Hover state styles
                      backgroundColor: colors.white[500], // Hover state background color
                      color: colors.MoonGreen[100], // Change text color on hover (optional)
                      border: 0.5,
                      borderColor: colors.MoonGreen[100],
                    },
                    padding: "5px 25px",
                    borderRadius: 1,
                    boxShadow: "3px 3px 5px 2px rgba(139,195,74, .3)",
                    textTransform: "none",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                >
                  <ButtonTypography >SAVE</ButtonTypography>
                </Button>
              </Box>  
            )}
            {selectedNote && (
              <>
                <TextField
                  id="title"
                  label="Title"
                  multiline
                  maxRows={1}
                  variant="outlined"
                  value={selectedNote.title}
                  fullWidth
                  sx={{ boxShadow:3, }}
                  onChange={(event) => handleNoteUpdate("title", event.target.value)}
                  InputProps={{
                    readOnly: !(selectedNote.internalNoteId == editMode), // Make the field read-only when not in edit mode
                    style: {
                      pointerEvents: !(selectedNote.internalNoteId == editMode) ? 'none' : 'auto',
                      backgroundColor: (selectedNote.internalNoteId == editMode) ? colors.white[500] : colors.MoonGrey[200], // Change background color when in read mode
                      color: (selectedNote.internalNoteId == editMode) ? 'inherit' : colors.MoonBlue[100], // Optionally change text color in read mode to indicate it's not editable
                      fontSize: "16px",
                    },
                  }}
                />
                <TextField
                  id="description"
                  label="Description"
                  multiline
                  rows={10}
                  variant="outlined"
                  value={selectedNote.description}
                  fullWidth
                  sx={{ mt:1, boxShadow:3 }}
                  onChange={(event) => handleNoteUpdate("description", event.target.value)}
                  InputProps={{
                    readOnly: !(selectedNote.internalNoteId == editMode), // Make the field read-only when not in edit mode
                    style: {
                      pointerEvents: !(selectedNote.internalNoteId == editMode) ? 'none' : 'auto',
                      backgroundColor: (selectedNote.internalNoteId == editMode) ? colors.white[500] : colors.MoonGrey[200], // Change background color when in read mode
                      color: (selectedNote.internalNoteId == editMode) ? 'inherit' : colors.MoonBlue[100], // Optionally change text color in read mode to indicate it's not editable
                      fontSize: "16px",
                    },
                  }}
                />
                {(selectedNote && selectedNote.images && selectedNote.images.length > 0 ||((selectedNote?.fileInfoDTOs?.length || 0) > 0)) && renderImageCarousel()}
                {selectedNote && selectedNote.internalNoteId === editMode && window.innerWidth >768 &&(
                  <Box sx={{ 
                    //marginTop: "1em",
                    //marginBottom: "1em",
                    mt: "15px",
                    display: 'flex',
                    justifyContent: 'space-between',
                    }}>
                    <Button
                      //variant="contained"
                      onClick={triggerFileInput}
                      sx={{
                        backgroundColor: colors.white[500],
                        padding: "5px 25px",
                        borderRadius: 1,
                        border: 0.5,
                        borderColor: colors.MoonGrey[100], 
                        boxShadow: "(3px 3px 5px 2px)",
                        textTransform: "none",
                        fontWeight: "bold",
                        letterSpacing: "0.5px",
                      }}
                    >
                      <ButtonTypography sx={{color:colors.MoonBlue[100]}}>UPLOAD IMAGES</ButtonTypography>
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      multiple
                      onChange={handleFileUpload}
                      style={{ display: 'none' }}
                    />

                    <Button
                      //variant="contained"
                      //color=colors.white[500]
                      onClick={() => {
                        if (selectedNote) handleSubmit(selectedNote);
                      }}
                      sx={{
                        marginRight: "10px",
                        marginLeft: "10px",
                        backgroundColor: colors.MoonGreen[100],
                        color: colors.white[500],
                        ':hover': { // Hover state styles
                          backgroundColor: colors.white[500], // Hover state background color
                          color: colors.MoonGreen[100], // Change text color on hover (optional)
                          border: 0.5,
                          borderColor: colors.MoonGreen[100],
                        },
                        padding: "5px 25px",
                        borderRadius: 1,
                        boxShadow: "3px 3px 5px 2px rgba(139,195,74, .3)",
                        textTransform: "none",
                        fontWeight: "bold",
                        letterSpacing: "0.5px",
                      }}
                    >
                      <ButtonTypography >SAVE</ButtonTypography>
                    </Button>
                  </Box>
                )}
                <Dialog  open={open} onClose={() => setOpen(false)}>
                  <DialogContent
                    sx={{
                      // Optional: Define a max height for the dialog content area to ensure it fits well on screen
                      display: 'flex', // Use flexbox for centering
                      justifyContent: 'center', // Center horizontally
                      alignItems: 'center', // Center vertically
                      overflow: 'auto', // Use 'auto' to allow scrolling if the image is too big
                      height: 'auto', // Let the content dictate the height
                      maxHeight: '100vh', 
                    }}
                  >
                    {selectedImage && (
                      <img
                        src={`${selectedImage}`}
                        alt="Enlarged"
                        style={{
                          maxWidth: '100%',
                          maxHeight: "50vh",
                          objectFit: "contain",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                    )}
                  </DialogContent>
                </Dialog>
                {/* Delete Dialog */}
                <Dialog open={deleteDialogOpen} onClose={setDeleteDialogOpen}>
                  <DialogTitle>
                    {"Are you sure you want to delete this note?"}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={handleConfirmDelete}
                      color="primary"
                      autoFocus
                    >
                      Yes, Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NotesPage;
