import { Navigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";


const API_URL = process.env.REACT_APP_API_URL;

async function isAuthenticated() {
  const response = await fetch(`${API_URL}/is-authenticated`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.text();
  return data;
}

const AdminRoute = ({ children, redirectToIfAdmin }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState("NotAuthenticated");
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const authStatus = await isAuthenticated();
      console.log("auth status: ", authStatus);
      setAuthenticated(authStatus);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // we can eventually replace this with a spinner or some loading component
  }

  if (authenticated === "isEmployeeAdmin" && redirectToIfAdmin) {
    //authenticated admin and redirecting
    return <Navigate to={redirectToIfAdmin} state={{ from: location }} />;
  } else if (authenticated === "isEmployeeAdmin") {
    return children; // Render the desired route
  } else if (authenticated === "isEmployee" && redirectToIfAdmin) {
    // in this case we were given a redirection address.. but since we are not admin, we are supposed to just go to the child
    return children;
  } else {
    // not authenticated - render login page
    return <Navigate to="/Dashboard" state={{ from: location }} />;
  }
};

export default AdminRoute;
