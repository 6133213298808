import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { tokens } from "../../theme";
import { useTheme } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

import Cookies from "js-cookie";

function ProjectList() {
  const { clientUserId } = useParams();
  const [projects, setProjects] = useState([]);
  const [open, setOpen] = useState(false); // State to control dialog visibility
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const API_URL = process.env.REACT_APP_API_URL;
  const [profileData, setProfileData] = useState({
    projectAddress: "",
    startDate: "",
    clientId: "",
  });
  const [loading, setLoading] = useState(true);
  const [showActiveOnly, setShowActiveOnly] = useState(false);

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        `${API_URL}/project/get-projects-for-client?clientUserId=${clientUserId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );
      

      if(response.ok && response.status == 200) {
        const data = await response.json();
        setProjects(data);
      } else {
        alert("You are not authorized to view projects. Please contact Admin for help.");
      }

    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    {
      fetchProjects();
    }
  }, [clientUserId]);

  const handleOpen = async () => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.PROJECTS)){
      alert("You are not authorized to add a new project. Please contact Admin for help.");
      return;
    }

    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [name]: value,
    }));
  };

  const handleShowActiveOnlyChange = (event) => {
    setShowActiveOnly(event.target.checked);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const projectDTO = {
        address: profileData.projectAddress,
        startDate: profileData.startDate,
        clientId: clientUserId,
        businessId: Cookies.get("businessId"),
      };
      let response;
      const createProjectURL = `${API_URL}/project/createProject`;
      response = await axios.post(createProjectURL, projectDTO, {
        withCredentials: true,
        withXSRFToken: true,
        headers: {
          Accept: "application/json",
          //'X-XSRF-TOKEN': cookie
        },
      });

      if (response.status === 200) {
        console.log("Project created successfully");
      }
      handleClose();
      fetchProjects();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          alert("You are not authorized to add a new project. Please contact Admin for help.");
        } else if (error.response.data) {
          console.error("Error from server:", error.response.data);
        }
      } else {
        console.error("Error sending request:", error.message);
      }
    }
  };

  // Function to navigate back
  const navigateBack = () => {
    navigate(`/users`);
  };

  const navigateTo = (path) => () => {
    console.log("Navigating to:", path); // Debugging log
    navigate(path);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  //TODO add delete feature. currently there isnt a endpoint for delete projects
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
          {/* Clickable navigation links */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography variant="h6" component="span" fontWeight="bold">
            Projects
          </Typography>
        </Box>
      </Box>
      <Box m="20px" bgcolor={colors.primary[400]} p={3} borderRadius="10px">
        {/* Wrap both the toggle and the button in a single Box */}
        <Box
          display="flex"
          justifyContent="space-between" // Keeps items spaced out, but you can adjust this for alignment
          alignItems="center"
          sx={{
            width: "100%",
            mb: "5px",
            zIndex: 100,
            position: "relative",
            p: 3,
            // bgcolor: colors.primary[400],
          }}
        >
          {/* Button to add a project, aligned to the left */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            sx={{ m: 1 }}
          >
            Add Project
          </Button>

          {/* Toggle switch, will be aligned to the right */}
          <FormControlLabel
            control={
              <Switch
                checked={showActiveOnly}
                onChange={handleShowActiveOnlyChange}
                name="showActiveOnly"
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green", // Thumb color when checked
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green", // Track color when checked
                  },
                }}
              />
            }
            label="Only Show Active Projects"
          />
        </Box>
        <Paper elevation={2}>
          <List>
            {projects
              .filter((project) => !showActiveOnly || project.status)
              .map((project) => (
                <ListItem
                  button
                  component={RouterLink}
                  to={`/users/${project.clientId}/${project.projectId}`}
                  key={project.projectId}
                  sx={{
                    backgroundColor: colors.white,
                    mb: 1,
                    borderRadius: "10px",
                    px: { xs: 1, sm: 2 },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ListItemIcon>
                    <AccountCircleIcon
                      sx={{
                        color: colors.primary[500],
                        fontSize: { xs: "24px", sm: "30px" },
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={project.address}
                    primaryTypographyProps={{
                      fontSize: { xs: "16px", sm: "18px" },
                    }}
                  />
                  {/* Status Indicator */}
                  <Box
                    sx={{
                      ml: "auto", // Push it to the far right
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      backgroundColor: project.status ? "green" : "grey",
                    }}
                  />
                </ListItem>
              ))}
          </List>
        </Paper>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>Add New Project</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                margin="dense"
                id="project-address"
                label="Project Address"
                type="text"
                fullWidth
                variant="outlined"
                name="projectAddress"
                value={profileData.projectAddress}
                onChange={handleInputChange}
                sx={{ input: { fontSize: "16px" } }}
              />
              <TextField
                margin="dense"
                id="start-date"
                label="Start Date"
                type="date"
                fullWidth
                variant="outlined"
                name="startDate"
                value={profileData.startDate}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ input: { fontSize: "16px" } }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
}

export default ProjectList;
