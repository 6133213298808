import {
  Box,
  Typography,
  IconButton,
  useTheme,
  Button,
  TextField,
  Grid,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useEffect, useInsertionEffect } from "react";
import axios from "axios"; // Step 1: Import axios
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import ConfirmationDialog from "../../generic/ConfirmationDialog";
import ClearIcon from "@mui/icons-material/Clear";

//Import Icons
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

const ToDoPage = () => {
  // Extract projectId from the URL parameters
  const { projectId } = useParams();
  const { clientUserId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeListId, setActiveListId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const [message, setMessage] = useState("");

  // State for the project name
  const [project, setProject] = useState(null);

  // stores the cookie
  const [cookie, setCookie] = useState("");

  // this will get the XSRF-TOKE from the cookie once the page is first loaded
  useInsertionEffect(() => {
    console.log("running");
    const fetchData = async () => {
      const temp = await fetch(`${API_URL}/welcome`, {
        method: "GET",
        credentials: "include",
      });
      //const temp = await fetch('/api/login', {method: 'POST', credentials: 'include'});
      getCookie("XSRF-TOKEN");
    };
    fetchData();
  }, []);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    console.log("trying");
    if (parts.length === 2) {
      const newCookie = parts.pop().split(";").shift();
      setCookie(newCookie);
    }
  };

  //used to naviage back a page
  const navigate = useNavigate();

  // Fetch and apply the theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Initial state for the new list name, new task, and to-do lists
  const [newListName, setNewListName] = useState("");
  const [newTask, setNewTask] = useState({
    taskId: null,
    taskDescription: "",
    taskDueDate: "",
    listId: null,
    status: null,
  });
  const [todoLists, setTodoLists] = useState([]);

  const API_URL = process.env.REACT_APP_API_URL;

  //Get any todo lists for specific project
  useEffect(() => {
    async function fetchTODOData() {
      // Fetch the to-do lists for the given client user ID
      try {
        const response = await fetch(
          `${API_URL}/to-do-list/user-lists?projectId=${projectId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );



        if(response.ok){
          let updatedTodoLists = [];
          const data = await response.json();
  
          if (Array.isArray(data)) {
            updatedTodoLists = data.map((list) => ({
              ...list,
              editMode: false, // Add an editMode property to each list
              isLive: true, // Add an isLive property to each list
            }));
          }
          setTodoLists(updatedTodoLists); // If data is not an array, this will set an empty array

        } else{
          if (response.status === 403) {
            alert("You are not authorized to view to-do lists. Please contact Admin for help.");
          } 
        }
      } catch (error) {
        console.error(
          "There was a problem with the fetch operation:",
          error.message
        );
      }
    }
    fetchTODOData();
  }, [clientUserId]);

  //Get Project Info
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
          {
            method: "GET",
            credentials: "include",
            withXSRFToken: true,
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );
        const data = await response.json();
        setProject(data);
      } catch (err) {
        console.error(`Error fetching data for userId ${projectId}:`, err);
      }
    };
    fetchProjectData();
  }, [projectId]);

  // Event handler for updating the new list name state
  const handleNewListNameChange = (event) => {
    setNewListName(event.target.value);
  };

  // Event handler for adding a new list
  const handleAddNewList = async () => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CLIENT_TO_DOS)){
      alert("You are not authorized to create to-do lists. Please contact Admin for help.");
      return;
    }

    const newList = {
      toDoListId: "",
      clientId: clientUserId,
      listName: newListName,
      taskList: [],
      editMode: true, // Set the editMode to true for the new list
      isLive: false, // Set isLive to false for the new list
    };
    setTodoLists([newList, ...todoLists]); // Add the new list to the state
    setNewListName(""); // Clear the new list name field
  };

  // Event handler for deleting a list
  const handleDeleteList = async () => {
    if (!activeListId) return;

    try {
      // Proceed with the deletion as before, using listToDelete
      const response = await axios.delete(`${API_URL}/to-do-list/delete-list`, {
        params: { toDoListId: activeListId },
        withCredentials: true,
        withXSRFToken: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setTodoLists(
          todoLists.filter((list) => list.toDoListId !== activeListId)
        );
      } else {
        alert("Failed to delete the list from the backend.");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to delete to-do list. Please contact Admin for help.");
        } else {
          alert("An error occurred while trying to delete the list.");
        }
      } else{
        alert("An error occurred while trying to delete the list.");
      }
      console.error("Error deleting the list:", error);
    }
  };

  // Event handler for updating the new task state
  const handleNewTaskChange = (event, key, listId) => {
    setNewTask((prevState) => ({
      ...prevState,
      [key]: event.target.value,
      listId: listId,
    }));
  };

  // Event handler for adding a new task
  const handleAddNewTask = (listId) => {
    if (todoLists.find((list) => list.toDoListId === listId).editMode) {
      const updatedLists = todoLists.map((list) => {
        if (list.toDoListId === listId) {
          console.log(list);
          return {
            ...list,
            taskList: [
              ...list.taskList,
              {
                //temp task id
                taskId: "",
                taskDescription: newTask.taskDescription,
                status: false,
                taskDueDate: newTask.taskDueDate,
              },
            ],
          };
        }

        return list;
      });
      setTodoLists(updatedLists);
      setNewTask({ listId: null, text: "", dueDate: "" });
    }
  };

  const handleDeleteTask = async (listId, taskId) => {
    const targetList = todoLists.find((list) => list.toDoListId === listId);
    if (targetList && targetList.editMode && !isLoading) {
      setIsLoading(true); // Set loading state to true at the start

      try {
        const response = await axios.delete(
          `${API_URL}/to-do-list/delete-task`,
          {
            params: { toDoListTaskId: taskId },
            withCredentials: true,
            withXSRFToken: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          // Check for successful response
          console.log("Task deleted successfully", response);

          // Update the local state only on successful deletion
          const updatedLists = todoLists.map((list) => {
            if (list.toDoListId === listId) {
              return {
                ...list,
                taskList: list.taskList.filter(
                  (task) => task.taskId !== taskId
                ),
              };
            }
            return list;
          });

          setTodoLists(updatedLists);
        } else {
          console.error("Failed to delete task: ", response);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403){
            alert("You are not authorized to delete to-do list. Please contact Admin for help.");
          } else {
            alert("An error occurred while trying to delete the list.");
          }
        } else{
          alert("An error occurred while trying to delete the list.");
        }
        console.error("Error deleting the list:", error);
      } finally {
        setIsLoading(false); // Reset loading state at the end
      }
    }
  };

  // Event handler for toggling the edit mode of a list
  const handleEditModeChange = async () => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CLIENT_TO_DOS)){
      alert("You are not authorized to edit to-do lists. Please contact Admin for help.");
      return;
    }

    const updatedLists = todoLists.map((list) => {
      if (list.toDoListId === activeListId) {
        return {
          ...list,
          editMode: !list.editMode,
        };
      }
      return list;
    });
    setTodoLists(updatedLists);
  };

  // Event handler for submitting a list
  async function handleSubmitList(listId, listName, tasks) {
    try {
      const data = {
        userId: clientUserId,
        projectId: projectId,
        listName: listName,
        toDoListId: listId,
        taskList: tasks.map((task) => ({
          taskDescription: task.taskDescription,
          taskDueDate: task.taskDueDate,
          status: task.status !== null ? task.status : false,
        })),
      };

      // Step 1: Check if the list exists

      // First request to check if the list exists
      const existsResponse = await fetch(
        `${API_URL}/to-do-list/exists?toDoListId=${listId}&userId=${clientUserId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      if(existsResponse.ok){
        const existsData = await existsResponse.json();
        console.log(existsData);
  
        // Determine the URL and request data based on the existence of the list
        let url, requestData;
        let response;
        if (existsData.exists) {
          url = `${API_URL}/to-do-list/modify`;
          requestData = {
            ...data,
            toDoListId: existsData.listId,
          };
          try{
            response = await axios.put(url, data, {
              withCredentials: true,
              withXSRFToken: true,
              headers: {
                "Content-Type": "application/json",
              },
            });
          } catch (error){
            if (error.response) {
              if (error.response.status === 403){
                alert("You are not authorized to modify to-do list. Please contact Admin for help.");
              }
            }
          }
          

        } else {
          url = `${API_URL}/to-do-list/create`;
          requestData = data;
          try {
            response = await axios.post(url, data, {
              withCredentials: true,
              withXSRFToken: true,
              headers: {
                "Content-Type": "application/json",
              },
            });

          } catch(error){
            if (error.response) {
              if (error.response.status === 403){
                alert("You are not authorized to create to-do list. Please contact Admin for help.");
              }
            }
          }
        }
  
        // Check if the response is okay, throw an error if not
  
        const responseData = await response.data;
  
        console.log(responseData);
        const updatedId = responseData.toDoListId;
        const updatedLists = todoLists.map((list) => {
          if (list.toDoListId === listId) {
            return {
              ...responseData,
              editMode: false,
              isLive: true,
            };
          }
          return list;
        });
        setTodoLists(updatedLists);

      } else{
        if (existsResponse.status === 403) {
          alert("You are not authorized to view the to-do list. Please contact Admin for help.");
        } 
      }


    } catch (error) {
      console.error(error);
    }
  }

  const navigateTo = (path) => () => {
    console.log("Navigating to:", path); // Debugging log
    navigate(path);
  };

  const handleCancel = (listId) => {
    // Resetting inputs to their initial state
    setNewListName("");
    setNewTask({
      taskId: null,
      taskDescription: "",
      taskDueDate: "",
      listId: null,
      status: null,
    });

    // Updated logic to handle lists based on the presence of listId
    const updatedLists = todoLists.reduce((acc, list) => {
      // Case 1: If the current list's listId is an empty string, drop it
      if (list.toDoListId === "" && listId === "") {
        // Don't push to accumulator, effectively dropping the list
        return acc;
      }

      // Case 2: If the listId exists (not an empty string), check if we are operating on the current list
      if (list.toDoListId === listId) {
        // Drop tasks with ids that are empty strings and set editMode to false
        const filteredTasks = list.taskList.filter(
          (task) => task.taskId !== ""
        );
        acc.push({ ...list, taskList: filteredTasks, editMode: false });
      } else {
        // For all other lists, just keep them as they are
        acc.push(list);
      }

      return acc;
    }, []);

    setTodoLists(updatedLists);
  };

  // menu for editing/deleting a list
  const handleMenuClick = (event, listId) => {
    setAnchorEl(event.currentTarget);
    setActiveListId(listId); // Remember which list's menu is active
  };

  // menu for editing/deleting a list
  const handleMenuClose = () => {
    setAnchorEl(null);
    //setActiveListId(null);
  };

  //generic
  const openDialogForListDelete = async() => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CLIENT_TO_DOS)){
      alert("You are not authorized to delete to-do lists. Please contact Admin for help.");
      return;
    }

    setIsDialogOpen(true);
    setConfirmAction(() => () => handleDeleteList());
    setMessage("?Are you sure you want to delete this list?");
  };

  // generic - Similar setup can be done for task deletion
  const openDialogForTaskDelete = (listId, taskId) => {
    setIsDialogOpen(true);
    setConfirmAction(() => () => handleDeleteTask(listId, taskId));
    setMessage("Are you sure you want to delete this task?");
  };

  const isAnyListInEditMode = todoLists.some((list) => list.editMode);

  // Handle case where project data is not yet loaded
  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
          {/* Clickable navigation links */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}`)}
          >
            Projects
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}/${projectId}`)}
          >
            {project.address}
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          <Typography variant="h6" component="span" fontWeight="bold">
            Tasks
          </Typography>
        </Box>
      </Box>

      <Box m="20px">
        <Box display="flex" gap="20px" mb="20px">
          <TextField
            value={newListName}
            onChange={handleNewListNameChange}
            label="New List Name"
            sx={{ input: { fontSize: "16px" } }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddNewList}
            disabled={isAnyListInEditMode}
            style={{
              backgroundColor: colors.MoonBlue[100],
              color: isAnyListInEditMode ? colors.grey[500] : "#ffffff",
            }}
          >
            Add New List
          </Button>
        </Box>

        <Grid container spacing={2}>
          {todoLists.map((list) => (
            <Grid item xs={12} sm={6} md={4} key={list.toDoListId}>
              <Box
                p="20px"
                backgroundColor={colors.primary[400]}
                borderRadius={1}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb="15px"
                >
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" fontWeight="600" ml="10px">
                      {list.listName}
                    </Typography>
                    {/*!list.editMode && (
                      <LiveTvIcon
                        sx={{
                          color: colors.redAccent[500],
                          fontSize: "26px",
                          ml: "10px",
                        }}
                      />
                      )*/}
                  </Box>
                  <Box>
                    {list.editMode ? (
                      <></>
                    ) : (
                      <>
                        <IconButton
                          onClick={(event) =>
                            handleMenuClick(event, list.toDoListId)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={() => {
                              handleEditModeChange();
                              handleMenuClose();
                            }}
                          >
                            <EditIcon />
                            &nbsp;Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              openDialogForListDelete();
                              handleMenuClose();
                            }}
                          >
                            <DeleteIcon />
                            &nbsp;Delete
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" gap="10px">
                  {(list.taskList || []).map((task) => (
                    <Box
                      key={task.taskId}
                      display="flex"
                      alignItems={
                        task.taskDescription.length > 50
                          ? "flex-start"
                          : "center"
                      }
                    >
                      <IconButton size="small" disabled={!list.editMode}>
                        {task.status ? (
                          <CheckCircleIcon
                            sx={{ color: colors.MoonGreen[100] }}
                          />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )}
                      </IconButton>
                      <Typography variant="body1" width="70%">
                        {task.taskDescription}
                      </Typography>
                      <Typography variant="body2" ml="auto">
                        {task.taskDueDate}
                      </Typography>
                      {list.editMode && (
                        <IconButton
                          onClick={() =>
                            openDialogForTaskDelete(
                              list.toDoListId,
                              task.taskId
                            )
                          }
                        >
                          <ClearIcon sx={{ color: colors.redAccent[500] }} />
                        </IconButton>
                      )}
                    </Box>
                  ))}
                </Box>

                {list.editMode && (
                  <Grid
                    container
                    spacing={2}
                    mt="20px"
                    width="100%"
                    style={{ color: colors.blueAccent[500] }}
                  >
                    {/* Task Description Input */}
                    <Grid item xs={12} sm={8} md={6}>
                      <TextField
                        fullWidth
                        sx={{ input: { fontSize: "16px" } }}
                        value={
                          newTask.listId === list.toDoListId &&
                          newTask.taskDescription
                            ? newTask.taskDescription
                            : ""
                        }
                        onChange={(e) =>
                          handleNewTaskChange(
                            e,
                            "taskDescription",
                            list.toDoListId
                          )
                        }
                        label="New Task"
                      />
                    </Grid>

                    {/* Task Due Date Input */}
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        fullWidth
                        sx={{ input: { fontSize: "16px" } }}
                        type="date"
                        value={
                          newTask.listId === list.toDoListId &&
                          newTask.taskDescription
                            ? newTask.taskDueDate
                            : ""
                        }
                        onChange={(e) =>
                          handleNewTaskChange(e, "taskDueDate", list.toDoListId)
                        }
                        label=""
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    {/* Add Task Button */}
                    <Grid item xs={12} sm={12} md={1}>
                      <Box sx={{ pr: 1 }}>
                        <Button
                          //variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => handleAddNewTask(list.toDoListId)}
                          disabled={
                            !newTask.taskDescription || !newTask.taskDueDate
                          }
                          sx={{
                            backgroundColor: "#ffffff",
                            color: colors.grey[100],
                          }}
                        >
                          Add Task
                        </Button>
                      </Box>
                    </Grid>

                    {/* Submit List Button */}
                    <Grid item xs={12} container spacing={2} width="100%">
                      <Grid item xs={6} width="100%">
                        <Button
                          //variant="contained"
                          //color="primary"
                          fullWidth
                          onClick={() => handleCancel(list.toDoListId)}
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffffff",
                            color: colors.redAccent[500],
                          }} // Use fullWidth or sx for 100% width
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6} width="100%">
                        <Button
                          //variant="contained"
                          //color="primary"
                          fullWidth
                          onClick={() =>
                            handleSubmitList(
                              list.toDoListId,
                              list.listName,
                              list.taskList
                            )
                          }
                          sx={{
                            width: "100%",
                            backgroundColor: "#8bc34a",
                            color: "#ffffff",
                            "&:hover": {
                              backgroundColor: "#8bc34a",
                            },
                          }}
                        >
                          Save List
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => {
          confirmAction();
          setIsDialogOpen(false);
        }}
        title="Confirm Deletion"
        message={message}
      />
    </Box>
  );
};

export default ToDoPage;
