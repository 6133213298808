import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { tokens } from "../../theme";
import { useTheme } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";


function ProjectList() {
  const [projects, setProjects] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const API_URL = process.env.REACT_APP_API_URL;


  const fetchProjects = async () => {
    try {
      const response = await fetch(`${API_URL}/projectInfo/all`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
            },
          }
        );

      const data = await response.json();

      if (data.length === 1) {
        navigate('/ClientDashboard/' + data[0].projectId);
      }

      setProjects(data);

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    {
      fetchProjects();
    }
  }, []);

  return (
    <Box m="20px" bgcolor={colors.primary[400]} p={3} borderRadius="10px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight="600" mb={2} fontSize={18}>
          Your Projects
        </Typography>
      </Box>
      <Paper elevation={2}>
        <List>
          {projects.map((project) => (
            <ListItem
              button
              component={RouterLink}
              to={`/ClientDashboard/${project.projectId}`}
              key={project.projectId}
              sx={{
                backgroundColor: colors.white,
                mb: 1,
                borderRadius: "10px",
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon color={colors.primary[400]} />
              </ListItemIcon>
              <ListItemText
                primary={`${project.address}`}
                primaryTypographyProps={{ fontSize: "18px" }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

export default ProjectList;
