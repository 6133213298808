import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Card,
  useTheme,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import axios from "axios";
import Cookies from "js-cookie";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

const CreateProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentStep, setCurrentStep] = useState(0);
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    clientEmail: "",
    ClientUsername: "",
    clientNumber: "",
    projectAddress: "",
    startDate: "",
    clientId: "",
  });
  const [resError, setResError] = useState("");

  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;


  useEffect(() => {
    async function onLoad(){
      if(! await checkWriteAccess(ACCESS_CATEGORIES.CLIENTS)){
        alert("You are not authorized to add a new client. Please contact Admin for help.");
        return;
      } else if(! await checkWriteAccess(ACCESS_CATEGORIES.PROJECTS)){
        alert("You are not authorized to create new projects. However you ARE able to create new users. If you proceed, you will be able to add new user but project creation will fail. Please contact Admin for help.");

      }
    }
    onLoad();
  }, []);

  

  const handleInputChange = (event) => {
    setResError("");
    const { name, value } = event.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (currentStep === steps.length - 1) {
      setIsSubmitting(true);
      try {
        await handleSubmitUser(profileData);
        await handleSubmitProject(profileData);
        setSnackbarMessage("User and Project created successfully!");
      } catch (error) {
        if(error.message == "Unable to create project"){
          setSnackbarMessage("User has been created with no project.");

        } else {
          setSnackbarMessage("Error creating User or Project.");
        }
      } finally {
        setOpen(true);
        setCurrentStep(0);
        setProfileData({
          firstName: "",
          lastName: "",
          clientEmail: "",
          ClientUsername: "",
          clientNumber: "",
          projectAddress: "",
          startDate: "",
          clientId: "",
        });
        setIsSubmitting(false);
      }
    } else {
      if(! await checkWriteAccess(ACCESS_CATEGORIES.CLIENTS)){
        alert("You are not authorized to add a new client. Please contact Admin for help.");
        return;
      } else{
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePrevious = (event) => {
    event.preventDefault();
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const steps = [
    {
      label: "Client Information",
      fields: [
        { name: "firstName", label: "First name" },
        { name: "lastName", label: "Last name" },
        { name: "clientEmail", label: "Client email" },
        { name: "clientNumber", label: "Client phone number" },
      ],
    },
    {
      label: "Project Details",
      fields: [
        { name: "projectAddress", label: "Project's address" },
        { name: "startDate", label: "Start date", type: "date" },
      ],
    },
  ];

  const handleSubmitUser = async (profileData) => {
    try {
      const userDTO = {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        email: profileData.clientEmail,
        phoneNumber: profileData.clientNumber,
        userName: profileData.clientEmail,
        role: "client",
        businessId: Cookies.get("businessId"),
      };

      console.log(userDTO);
      let response;
      const createUserURL = `${API_URL}/client/create`;
      response = await axios.post(createUserURL, userDTO, {
        withCredentials: true,
        withXSRFToken: true,
        headers: {
          Accept: "application/json",
          //'X-XSRF-TOKEN': cookie
        },
      });

      if (response.status === 200) {
        console.log("User created successfully");
        profileData.clientId = response.data.userId;
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          alert("You are not authorized to add a new client. Please contact Admin for help.");
        } else if (error.response.data) {
          console.error("Error from server:", error.response.data);
          setResError(error.response.data.message);
        }
      } else {
        console.error("Error sending request:", error.message);
      }
      throw new Error("Something went wrong!");
    }
  };

  const handleSubmitProject = async (profileData) => {
    try {
      const projectDTO = {
        address: profileData.projectAddress,
        startDate: profileData.startDate,
        businessId: Cookies.get("businessId"),
        clientId: profileData.clientId,
      };

      let response;
      const createProjectURL = `${API_URL}/project/createProject`;
      response = await axios.post(createProjectURL, projectDTO, {
        withCredentials: true,
        withXSRFToken: true,
        headers: {
          Accept: "application/json",
          //'X-XSRF-TOKEN': cookie
        },
      });

      if (response.status === 200) {
        console.log("Project created successfully");
      }
    } catch (error) {
        if (error.response) {
          if (error.response.status === 403) {
            alert("You are not authorized to add a new project. Please contact Admin for help.");
            throw new Error("Unable to create project");
          } else if (error.response.data) {
            console.error("Error from server:", error.response.data);
          }
        } else {
          console.error("Error sending request:", error.message);
        }
        throw new Error("Something went wrong!");
      }
  };

  return (
    <Box
      sx={{
        m: theme.spacing(5),
        backgroundColor: colors.primary[400],
        borderRadius: "10px",
        padding: theme.spacing(3),
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography variant="h3" sx={{ fontWeight: "600", mb: theme.spacing(2) }}>
        Add New Client
      </Typography>
      {resError && <p style={{ color: "red" }}>{resError}</p>}
      <form onSubmit={handleSubmit}>
        <Card
          sx={{
            backgroundColor: colors.primary[400],
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            p: theme.spacing(3),
            display: "flex",
            flexDirection: "column",
          }}
        >
          {steps.map(
            (step, index) =>
              currentStep === index && (
                <Box key={index}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "600", mb: theme.spacing(2) }}
                  >
                    {step.label}
                  </Typography>
                  {step.fields.map((field) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id={field.name}
                      label={field.label}
                      name={field.name}
                      autoComplete={field.name}
                      type={field.type || "text"}
                      value={profileData[field.name]}
                      onChange={handleInputChange}
                      autoFocus
                      key={field.name}
                      sx={{ input: { fontSize: "16px" } }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ))}
                </Box>
              )
          )}
          <Box sx={{ 
            display: "flex", 
            justifyContent: currentStep === 1 ? "space-between" : "flex-end",

            mt: 3 
          }}>
            {currentStep === 1 && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePrevious}
                >
                  Previous
                </Button>
              )}
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
              {currentStep === steps.length - 1 ? "Submit" : "Next"}
            </Button>
          </Box>
        </Card>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default CreateProfile;
