import { useState, useEffect, useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useTheme,
  Grid,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../../CalendarStyles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { clientUserId } = useParams();

  const API_URL = process.env.REACT_APP_API_URL;

  // ref to store the calendar instance
  const calendarRef = useRef(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/client-calander-event/get-event-for-projectId/${projectId}`,
        { withCredentials: true }
      );
      const eventDTOs = response.data;

      addEventsToCalendar(eventDTOs);
    } catch (error) {
      console.error("Error fetching events:", error);
      // Handle error appropriately
    }
  };

  const addEventsToCalendar = (eventDTOs) => {
    const calendarApi = calendarRef.current.getApi(); // Access the calendar API

    eventDTOs.forEach((eventDTO) => {
      calendarApi.addEvent({
        id: eventDTO.eventId,
        title: eventDTO.title,
        start: eventDTO.start,
        end: eventDTO.end,
        allDay: eventDTO.allDay,
      });
    });
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className="custom-event" title={eventInfo.event.title}>
        <strong>{eventInfo.timeText}</strong>
        <span>{eventInfo.event.title}</span>
      </div>
    );
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Calendar" subtitle="Project Calendar" />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {" "}
          {/* CALENDAR */}
          <Box flex="1 1 100%" ml="15px">
            <FullCalendar
              ref={calendarRef}
              height="75vh"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,listMonth",
              }}
              initialView="dayGridMonth"
              editable={false}
              selectable={true}
              selectMirror={true}
              eventContent={renderEventContent}
              dayMaxEvents={true}
              eventsSet={(events) => setCurrentEvents(events)}
            />
          </Box>
        </Grid>
      </Grid>{" "}
    </Box>
  );
};

export default Calendar;
