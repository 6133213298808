import { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Card,
  useTheme,
  CardContent,
  Avatar,
  TextField,
  InputAdornment,
  IconButton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";

import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import MessageIcon from "@mui/icons-material/Message";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NoteIcon from "@mui/icons-material/Note";
import HandymanIcon from "@mui/icons-material/Handyman";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TuneIcon from "@mui/icons-material/Tune";

import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { tokens } from "../../theme";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

import Grid from "@mui/material/Grid";

import axios from "axios";
import Cookies from "js-cookie";
import { red } from "@mui/material/colors";
import { TroubleshootOutlined } from "@mui/icons-material";

const NavigationCard = ({
  title,
  IconComponent,
  notificationCount,
  onClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid item xs={6} sm={3} md={3}>
      <Box
        onClick={onClick}
        sx={{
          backgroundColor: colors.white[500],
          p: 1,
          borderRadius: 2,
          cursor: "pointer",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          //height: window.innerWidth > 768 ? "30vh" : "10vh",
          boxShadow: 2,
        }}
      >
        {/* Display notification count only if notificationCount is provided */}
        {notificationCount > 0 && (
          <Box
            sx={{
              position: "absolute",
              top: "0.5em", // Updated from pixels to em
              right: "0.5em", // Updated from pixels to em
              backgroundColor: "red",
              borderRadius: "50%",
              width: "2.5em", // Updated from pixels to em
              height: "2.5em", // Updated from pixels to em
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "0.8em", // Adjusted for appropriate scaling
              fontWeight: "bold",
              boxShadow: 1,
            }}
          >
            <Typography sx={{ fontSize: "1.4em" }}>
              {notificationCount}
            </Typography>
          </Box>
        )}

        <Box sx={{ textAlign: "center" }}>
          <IconComponent
            strokeWidth={1}
            sx={{
              fontSize: window.innerWidth > 768 ? "3rem" : "2rem",
              color: colors.MoonBlue[100],
            }}
          />
        </Box>
        <Typography
          variant="h5"
          fontWeight="600"
          textAlign="center"
          sx={{
            mb: 1,
            color: colors.MoonBlue[100],
          }}
        >
          {title}
        </Typography>
      </Box>
    </Grid>
  );
};

const CommunicationApps = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { clientUserId } = useParams();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const navigateTo = (path) => () => navigate(path);
  const [notification, setNotification] = useState(null);
  const [eventTypeCounts, setEventTypeCounts] = useState({});
  const API_URL = process.env.REACT_APP_API_URL;
  const employee = Cookies.get("userId");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Get Notification Info
  useEffect(() => {
    async function fetchNotificationData() {
      try {

        const response = await axios.get(`${API_URL}/notification/get-notifications-for-project/${projectId}/${employee}`, {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
              'Accept': 'application/json',
          }
      });

        const data = response.data;
        setNotification(data);
        setLoading(false);

        const eventTypeCounts = {};

        for (let i = 0; i < data.length; i++) {
          const notification = data[i];
          const eventType = notification.eventType;

          // Initialize count for this event type to 0 if it's the first occurrence
          if (!eventTypeCounts[eventType]) {
            eventTypeCounts[eventType] = 0;
          }

          // Increment count for this event type
          eventTypeCounts[eventType] += 1;
        }

        setEventTypeCounts(eventTypeCounts);

        console.log(eventTypeCounts);
      } catch (err) {
        console.error(`Error fetching data for projectId: ${projectId} `, err);
        setError(err);
        setLoading(false);
        if (error.response) {
          if (error.response.status === 403){
            alert("You are not authorized to view notifications. Please contact Admin for help.");
          }
        }
      }
    }
    fetchNotificationData();
  }, [projectId]);

  const CommunicationAppItems = [
    {
      title: "Messages",
      IconComponent: SmsOutlinedIcon,
      eventType: "EVENT_TYPE_7",
      path: `/users/${clientUserId}/${projectId}/message`,
    },
    {
      title: "Tasks",
      IconComponent: FormatListBulletedIcon,
      eventType: "EVENT_TYPE_9",
      path: `/users/${clientUserId}/${projectId}/todos`,
    },
    {
      title: "Documents",
      IconComponent: DescriptionOutlinedIcon,
      eventType: "EVENT_TYPE_10",
      path: `/users/${clientUserId}/${projectId}/document`,
    },
    {
      title: "Selections",
      IconComponent: HandymanIcon,
      eventType: "EVENT_TYPE_8",
      path: `/users/${clientUserId}/${projectId}/finishings`,
    },
    {
      title: "Updates",
      IconComponent: NotificationsActiveIcon,
      path: `/users/${clientUserId}/${projectId}/updates`,
    },
    {
      title: "Calendar",
      IconComponent: CalendarMonthIcon,
      eventType: "EVENT_TYPE_6",
      path: `/users/${clientUserId}/${projectId}/calendar`,
    },
    {
      title: "Internal Notes",
      IconComponent: NoteIcon,
      path: `/users/${clientUserId}/${projectId}/notes`,
    },
    {
      title: "Invoices",
      IconComponent: ReceiptLongIcon,
      path: `/users/${clientUserId}/${projectId}/invoices`,
    },
  ];

  const getStyle = () => {
    if (window.innerWidth > 768) {
      // Assuming 768px is this breakpoint for mobile vs desktop
      return {
        //marginLeft: "25px",
        //mr:"2vw",
        //border: "1px solid #000000",
        height: "100%",
        borderRadius: "10px",
      };
    } else {
      return {
        //border: "1px solid #000000",
        height: "100%",
        borderRadius: "10px",
      };
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data!</div>;
  }

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      sx={{
        minHeight: { sm: "30vh", md: "30vh", lg: "30vh", xl: "30vh" }, // Set height for non-mobile views
      }}
    >
      <Box style={getStyle()}>
        {/*
        <Typography
          variant="h5"
          fontWeight="bold"
          style={{ marginBottom: "21px" }}
        >
          Client Communication
        </Typography>
        */}

        <Grid container spacing={1}>
          {/* Row 1 */}
          {CommunicationAppItems.map((item, index) => (
            <NavigationCard
              key={index}
              title={item.title}
              IconComponent={item.IconComponent}
              notificationCount={
                item.title === "Selections"
                  ? eventTypeCounts[item.eventType] ||
                    0 + eventTypeCounts["EVENT_TYPE_13"] ||
                    0
                  : eventTypeCounts[item.eventType]
              }
              onClick={navigateTo(item.path)}
            />
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

const ProjectManagement = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { clientUserId } = useParams();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const navigateTo = (path) => () => navigate(path);
  const [notification, setNotification] = useState(null);
  const [eventTypeCounts, setEventTypeCounts] = useState({});
  const API_URL = process.env.REACT_APP_API_URL;
  const employee = Cookies.get("userId");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Get Notification Info
  useEffect(() => {
    async function fetchNotificationData() {
      try {
        const response = await fetch(
          `${API_URL}/notification/get-notifications-for-project/${projectId}/${employee}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Network response was not ok ${response.statusText}`);
        }

        const data = await response.json();
        setNotification(data);
        setLoading(false);

        const eventTypeCounts = {};

        for (let i = 0; i < data.length; i++) {
          const notification = data[i];
          const eventType = notification.eventType;

          // Initialize count for this event type to 0 if it's the first occurrence
          if (!eventTypeCounts[eventType]) {
            eventTypeCounts[eventType] = 0;
          }

          // Increment count for this event type
          eventTypeCounts[eventType] += 1;
        }

        setEventTypeCounts(eventTypeCounts);

        console.log(eventTypeCounts);
      } catch (err) {
        console.error(`Error fetching data for projectId: ${projectId} `, err);
        setError(err);
        setLoading(false);
      }
    }
    fetchNotificationData();
  }, [projectId]);

  const ProjectManagementAppItems = [
    {
      title: "Calendar",
      IconComponent: CalendarMonthIcon,
      eventType: "EVENT_TYPE_6",
      path: `/users/${clientUserId}/${projectId}/calendar`,
    },
    {
      title: "Internal Notes",
      IconComponent: NoteIcon,
      path: `/users/${clientUserId}/${projectId}/notes`,
    },
    {
      title: "Invoices",
      IconComponent: ReceiptLongIcon,
      path: `/users/${clientUserId}/${projectId}/invoices`,
    },
  ];

  const getStyle = () => {
    if (window.innerWidth > 768) {
      // Assuming 768px is this breakpoint for mobile vs desktop
      return {
        marginRight: "25px",
        borderRadius: "10px",
        height: "100%",
        border: "1px solid #000000",
      };
    } else {
      return {
        borderRadius: "10px",
        height: "100%",
        border: "1px solid #000000",
      };
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data!</div>;
  }

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        minHeight: { sm: "41vh", md: "41vh", lg: "41vh", xl: "41vh" }, // Set height for non-mobile views
      }}
    >
      <Box p={2} boxShadow={5} style={getStyle()}>
        <Typography
          variant="h5"
          fontWeight="bold"
          style={{ marginBottom: "21px" }}
        >
          Project Management
        </Typography>
        <Grid container spacing={2}>
          {/* Row 1 */}
          {ProjectManagementAppItems.map((item, index) => (
            <NavigationCard
              key={index}
              title={item.title}
              IconComponent={item.IconComponent}
              notificationCount={eventTypeCounts[item.eventType]}
              onClick={navigateTo(item.path)}
            />
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

const ProjectInfo = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { clientUserId } = useParams();
  const { projectId } = useParams();
  const [user, setUser] = useState([]);
  const employee = Cookies.get("userId");
  const [project, setProject] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const descriptionMaxLength = 170;
  const [isEditMode, setIsEditMode] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [editableData, setEditableData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    startDate: "",
    endDate: "",
    description: "",
    status: "",
  });

  useEffect(() => {
    if (user && project) {
      setEditableData({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        phoneNumber: user.phoneNumber || "",
        address: project.address || "",
        startDate: project.startDate || "",
        endDate: project.endDate || "",
        description: project.description || "",
        status: project.status || "",
      });
    }
  }, [user, project]);

  //Get Project Info

  const fetchProjectData = async () => {
    try {
      const response = await fetch(
        `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );
      console.log(response.data);

      const data = await response.json();
      setProject(data);

      setLoading(false);
    } catch (err) {
      console.error(`Error fetching data for userId ${projectId}:`, err);
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [projectId]);

  //Get Client User Info

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${API_URL}/client/get-single-user?userId=${clientUserId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [clientUserId]);

  const getClientInitial = () => {
    return user.firstName ? user.firstName[0].toUpperCase() : "?";
  };

  const saveProjectInfo = async () => {
    const projectDTO = {
      projectId: projectId,
      address: editableData.address,
      startDate: editableData.startDate,
      endDate: editableData.endDate,
      description: editableData.description,
      status: editableData.status,
    };

    try {
      const response = await fetch(`${API_URL}/project/modify`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
        body: JSON.stringify(projectDTO),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Project updated successfully:", data);
      await fetchProjectData();
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  const saveClientUserInfo = async () => {
    const clientUserDTO = {
      userId: clientUserId,
      firstName: editableData.firstName,
      lastName: editableData.lastName,
      email: editableData.email,
      phoneNumber: editableData.phoneNumber,
    };

    try {
      const response = await fetch(`${API_URL}/client/modify`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
        body: JSON.stringify(clientUserDTO),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Project updated successfully:", data);
      await fetchData();
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };
  // Toggle between view and edit mode
  const handleEditToggle = async() => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.PROJECTS)){
      alert("You are not authorized to modify project details. Please contact Admin for help.");
      return;
    }

    if (isEditMode) {
      saveProjectInfo();
      saveClientUserInfo();
    }
    setIsEditMode(!isEditMode);
  };

  const handleStatusToggle = (event) => {
    setEditableData({ ...editableData, status: event.target.checked });
  };

  // Handle changes in input fields
  const handleChange = (prop) => (event) => {
    setEditableData({ ...editableData, [prop]: event.target.value });
  };

  const getStyle = () => {
    if (window.innerWidth > 768 && window.innerWidth < 900) {
      // tablet
      return {
        //marginLeft: "20px",
        //marginRight: "20px",
        borderRadius: "10px",
        height: "100%",
        //border: "1px solid #000000",
        //padding: "10px",
        zIndex: 1,
        position: "relative",
      };
    } else if (window.innerWidth >= 900) {
      // desktop
      return {
        //marginLeft: "25px",
        borderRadius: "10px",
        height: "100%",
        //border: "1px solid #000000",
        //padding: "10px",
        zIndex: 1,
        position: "relative",
      };
    } else {
      return {
        borderRadius: "10px",
        height: "100%",
        //border: "1px solid #000000",
        //padding: "10px",
        zIndex: 1,
        position: "relative",
      };
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data!</div>;
  }

  if (!project) {
    return <div>No project found for this user!</div>;
  }

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      sx={{
        minHeight: { sm: "52vh", md: "30vh", lg: "52vh", xl: "52vh" }, // Set height for non-mobile views
      }}
    >
      {/*<Box boxShadow={5} style={getStyle()}>*/}
      <Card
        style={getStyle()}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          color: colors.grey[50],
          borderRadius: "10px",
          width: "100%",
          p: 2,
          height: "100%",
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 8, // Adjust as needed
            left: 8, // Adjust as needed
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: project.status === true ? "green" : "grey",
          }}
        ></Box>
        <Box sx={{ position: "absolute", top: "20px", right: "20px" }}>
          {isEditMode ? (
            <SaveIcon
              sx={{
                cursor: "pointer",
                color: colors.MoonBlue[100],
                fontSize: "25px",
              }}
              onClick={handleEditToggle}
            />
          ) : (
            <EditIcon
              sx={{
                cursor: "pointer",
                color: colors.MoonBlue[100],
                fontSize: "25px",
              }}
              onClick={handleEditToggle}
            />
          )}
        </Box>

        {/* Client Information Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            //justifyContent: "space-around",
            flexBasis: "33%",
            //borderRight: { md: 1 },
            //borderColor: "divider",
            paddingRight: { xs: 0, md: 2 },
          }}
        >
          {isEditMode && (
            <FormControlLabel
              control={
                <Switch
                  checked={editableData.status}
                  onChange={handleStatusToggle}
                  name="activeStatus"
                />
              }
              label={
                editableData.status
                  ? "Project Status: Active"
                  : "Project Status: Inactive"
              }
              sx={{
                position: "absolute",
                top: 8,
                right: 60,
              }}
            />
          )}
          {isEditMode ? (
            <></>
          ) : (
            <>
              <Avatar
                sx={{
                  bgcolor: colors.MoonGrey[200],
                  width: { xs: 45, sm: 125, md: 125 },
                  height: { xs: 45, sm: 125, md: 125 },
                  fontSize: { xs: "27px", sm: "75px", md: "75px" },
                  fontWeight: "bold",
                  color: colors.MoonBlue[100],
                  mt: 3,
                  mr: 1,
                }}
              >
                {getClientInitial()}
              </Avatar>
            </>
          )}

          {isEditMode ? (
            <>
              <TextField
                label="First Name"
                value={editableData.firstName}
                onChange={handleChange("firstName")}
                fullWidth
                sx={{
                  my: { xs: 4 },
                  "& .MuiInputBase-input": { fontSize: "16px" },
                }}
              />
              <TextField
                label="Last Name"
                value={editableData.lastName}
                onChange={handleChange("lastName")}
                fullWidth
                sx={{
                  my: 1,
                  "& .MuiInputBase-input": { fontSize: "16px" },
                }}
              />
              <TextField
                label="Email"
                value={editableData.email}
                onChange={handleChange("email")}
                onFocus={() => isEditMode && setOpenTooltip(true)}
                onBlur={() => setOpenTooltip(false)}
                fullWidth
                sx={{
                  my: 1,
                  "& .MuiInputBase-input": { fontSize: "16px" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        open={openTooltip}
                        title="WARNING - Modifying this email will modify the login credentials"
                        placement="bottom"
                      >
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Phone Number"
                value={editableData.phoneNumber}
                onChange={handleChange("phoneNumber")}
                fullWidth
                sx={{
                  my: 1,
                  "& .MuiInputBase-input": { fontSize: "16px" },
                }}
              />
            </>
          ) : (
            <>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{
                  mt: 1,
                  mr: 1,
                  fontSize: "clamp(20px, 1.5vw, 32px)", // Adjust as needed
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {`${editableData.firstName.toUpperCase()} ${editableData.lastName.toUpperCase()}`}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="light"
                sx={{
                  mt: 5,
                  mr: 1,
                  maxWidth: "200px",
                  fontSize: "clamp(12px, 1vw, 32px)",
                  whiteSpace: "normal",
                  overflow: "visable",
                  textOverflow: "clip",
                  wordBreak: "break-word",
                }}
              >
                {editableData.email}
              </Typography>
              <Typography
                variant="h5"
                sx={{ mt: 1, mr: 1, fontSize: "clamp(12px, 1.2vw, 32px)" }}
              >
                {editableData.phoneNumber}
              </Typography>
            </>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexBasis: "67%",
            paddingLeft: { xs: 0, md: 2 },
            overflow: "hidden",
          }}
        >
          <Grid container spacing={2}>
            {/* Address & Project ID */}
            <Grid item xs={12} mt={3}>
              {isEditMode ? (
                <TextField
                  label="Address"
                  value={editableData.address}
                  onChange={handleChange("address")}
                  fullWidth
                  sx={{
                    my: 1,
                    "& .MuiInputBase-input": { fontSize: "16px" },
                  }}
                />
              ) : (
                <>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "12px", sm: "15px" },
                      }}
                    >
                      Address:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "12px", sm: "15px" },
                        fontWeight: "light",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "90vw",
                        display: "inline-block", // Necessary for text-overflow to work
                        //cursor: "pointer",
                      }}
                    >
                      {editableData.address}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {isEditMode ? (
                <></>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "12px", sm: "15px" },
                    }}
                  >
                    Project ID:
                  </Typography>
                  <Tooltip
                    title={projectId}
                    placement="bottom"
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          fontSize: "1rem",
                        },
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "12px", sm: "15px" },
                        fontWeight: "light",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "90vw",
                        display: "inline-block", // Necessary for text-overflow to work
                        //cursor: "pointer",
                      }}
                    >
                      {projectId}
                    </Typography>
                  </Tooltip>
                </Box>
              )}
            </Grid>

            {/* Start Date & End Date */}
            <Grid item xs={12}>
              {isEditMode ? (
                <TextField
                  label="Start Date"
                  type="date"
                  value={editableData.startDate}
                  onChange={handleChange("startDate")}
                  fullWidth
                  sx={{
                    my: 1,
                    "& .MuiInputBase-input": { fontSize: "16px" },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              ) : (
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "12px", sm: "15px" },
                    }}
                  >
                    Start Date:
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "light",
                      fontSize: { xs: "12px", sm: "15px" },
                    }}
                  >
                    {editableData.startDate}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              {isEditMode ? (
                <TextField
                  label="End Date"
                  type="date"
                  value={editableData.endDate}
                  onChange={handleChange("endDate")}
                  fullWidth
                  sx={{
                    my: 1,
                    "& .MuiInputBase-input": { fontSize: "16px" },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              ) : (
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "12px", sm: "15px" },
                    }}
                  >
                    End Date:
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "light",
                      fontSize: { xs: "12px", sm: "15px" },
                    }}
                  >
                    {editableData.endDate}
                  </Typography>
                </Box>
              )}
            </Grid>

            {/* Description Box */}
            <Grid item xs={12} md={12}>
              <Box>
                {isEditMode ? (
                  <TextField
                    label="Description"
                    value={editableData.description}
                    onChange={handleChange("description")}
                    fullWidth
                    sx={{
                      my: 1,
                      "& .MuiInputBase-input": { fontSize: "16px" },
                    }}
                    multiline
                    rows={2}
                    inputProps={{ maxLength: descriptionMaxLength }}
                  />
                ) : (
                  <>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "12px", sm: "15px" },
                      }}
                    >
                      Description:
                    </Typography>
                    <Typography variant="body1">
                      <Box
                        sx={{
                          border: 1,
                          borderColor: "divider",
                          p: 1,
                          minHeight: "80px",
                          borderRadius: "10px",
                        }}
                      >
                        {editableData.description}
                      </Box>
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
      {/*</Box>*/}
    </Grid>
  );
};

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const { projectId } = useParams();
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL;

  const [calendarHeight, setCalendarHeight] = useState("75vh");

  const calendarRef = useRef(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/calander-event/get-event-for-projectId/${projectId}`,
        { withCredentials: true }
      );
      const eventDTOs = response.data;

      addEventsToCalendar(eventDTOs);
    } catch (error) {
      console.error("Error fetching events:", error);
      // Handle error appropriately
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className="custom-event" title={eventInfo.event.title}>
        <strong>{eventInfo.timeText}</strong>
        <span>{eventInfo.event.title}</span>
      </div>
    );
  };

  const addEventsToCalendar = (eventDTOs) => {
    const calendarApi = calendarRef.current.getApi(); // Access the calendar API

    eventDTOs.forEach((eventDTO) => {
      calendarApi.addEvent({
        id: eventDTO.eventId,
        title: eventDTO.title,
        start: eventDTO.start,
        end: eventDTO.end,
        allDay: eventDTO.allDay,
      });
    });
  };

  //checks if its dekstop or mobile view and then sets the height for the calendar respectively
  useEffect(() => {
    const updateSize = () => {
      setCalendarHeight(window.innerWidth >= 768 ? "100%" : "75vh");
    };
    window.addEventListener("resize", updateSize);
    updateSize(); // Initialize on component mount

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const getStyle = () => {
    if (window.innerWidth > 768 && window.innerWidth < 900) {
      // tablet
      return {
        //marginLeft: "20px",
        //marginRight: "20px",
        borderRadius: "10px",
        height: calendarHeight,
        //border: "1px solid #000000",
        padding: "10px",
        zIndex: 1,
        position: "relative",
      };
    } else if (window.innerWidth >= 900) {
      // desktop
      return {
        //marginRight: "20px",
        borderRadius: "10px",
        height: calendarHeight,
        //border: "1px solid #000000",
        padding: "10px",
        zIndex: 1,
        position: "relative",
      };
    } else {
      // mobile phone
      return {
        borderRadius: "10px",
        height: calendarHeight,
        //border: "1px solid #000000",
        padding: "10px",
        zIndex: 1,
        position: "relative",
      };
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      sx={{
        minHeight: { sm: "52vh", md: "52vh", lg: "52vh", xl: "52vh" }, // Set height for non-mobile views
      }}
    >
      <Box boxShadow={5} style={getStyle()}>
        {/* CALENDAR */}
        <FullCalendar
          ref={calendarRef}
          timeZone="utc"
          height="100%"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "",
          }}
          initialView="listMonth"
          editable={false}
          selectable={true}
          selectMirror={true}
          eventContent={renderEventContent}
          dayMaxEvents={true}
          eventsSet={(events) => setCurrentEvents(events)}
        />
      </Box>
    </Grid>
  );
};

const BuilderBoard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { clientUserId } = useParams();
  const { projectId } = useParams();
  const [user, setUser] = useState([]);
  const employee = Cookies.get("userId");
  const [project, setProject] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const navigateTo = (path) => () => {
    console.log("Navigating to:", path); // Debugging log
    navigate(path);
  };
  //Get Project Info
  useEffect(() => {
    async function fetchProjectData() {
      try {
        const response = await axios.get(`${API_URL}/project/get-single-project-for-client?projectId=${projectId}`, {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
              'Accept': 'application/json',
          }
      });
        console.log(response.data);

        setProject(response.data);

        setLoading(false);
      } catch (err) {
        console.error(`Error fetching data for userId ${projectId}:`, err);
        setError(err);
        setLoading(false);
        if (error.response ) {
          if (error.response.status === 403){
            alert("You are not authorized to view project details. Please contact Admin for help.");
          }
        }
      }
    }
    fetchProjectData();
  }, [projectId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data!</div>;
  }

  if (!project) {
    return <div>No project found for this user!</div>;
  }

  return (
    <Box sx={{ mx: window.innerWidth > 768 ? "20px" : "10px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Clickable navigation links */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}`)}
          >
            Projects
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography variant="h6" component="span" fontWeight="bold">
            {project.address} {/* Using project address as project name */}
          </Typography>
        </Box>
        <Box sx={{ mr: "20px" }}>
          <Button
            variant="outlined"
            startIcon={<TuneIcon />}
            onClick={navigateTo(
              `/users/${clientUserId}/${projectId}/employees`
            )}
          >
            View employees
          </Button>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="h1"
          fontWeight="medium"
          sx={{ mb: "1rem", fontFamily: '"Montserrat", sans-serif' }}
        >
          Project Dashboard
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <ProjectInfo />

        <Calendar />

        <CommunicationApps />

        {/*<ProjectManagement />*/}
      </Grid>
    </Box>
  );
};

export default BuilderBoard;
