import { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Form from "./scenes/form";
import BuilderBoard from "./scenes/builderBoard/builderBoard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import ProjectPage from "./scenes/ClientList";
import TodoPage from "./scenes/todo/TodoPage"
import FinishingsPage from "./scenes/finishings/FinishingsPage"
import NotesPage from "./scenes/notes/notes";
import BuilderDashboard from "./scenes/dashboard/builderDashboard 2";
import MessagingFeature from "./scenes/message/message";
import DocumentSharing from "./scenes/documentSharing/documentSharing";
import UpdatePage from "./scenes/updates/updatesPage";
import AllMessages from "./scenes/message/allMessages";
import ProjectList from "./scenes/ProjectList/projectList";
import LoginForm from "./scenes/login/login";
import PrivateRoute from "./util/PrivateRoute";
import AdminRoute from "./util/AdminRoute";
import Settings from "./scenes/settings/Settings";
import AdminSettings from "./scenes/settings/AdminSettings";
import Reset from "./scenes/settings/Reset";
import ClientRoute from "./util/ClientRoute";
import ClientDashboard from "./clientPages/clientDashboard/ClientDashboard";
import ClientCalendar from "./clientPages/calendar/calendar"
import ClientTopbar from "./clientPages/global/Topbar";
import ClientSidebar from "./clientPages/global/Sidebar";
import ClientTodos from "./clientPages/todo/todoPage";
import ClientFinishings from "./clientPages/finishings/finishings";
import ClientUpdates from "./clientPages/updates/Updates";
import ClientDocSharing from "./clientPages/documentSharing/documentSharing";
import ClientProjectList from "./clientPages/projectList/ProjectList";
import ClientSettings from "./clientPages/settings/Settings";
import ClientReset from "./clientPages/settings/Reset";
import ForgotPassword from "./scenes/login/ForgotPassword";
import ResetPassword from "./scenes/login/ResetPassword";
import GlobalCalendar from "./scenes/globalCalendar/globalCalendar";
import AddEmployee from "./scenes/settings/AddEmployee";
import EmployeesTable from "./scenes/employeesTable/index";
import AddBrandLogos from "./scenes/settings/AddBrandLogos";
import InternalMessage from "./scenes/message/InternalMessage"

import ContractorInvoices from "./scenes/invoices";
import ClientInvoices from "./clientPages/invoices";

import './responsive.css';




function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();  // Get the current location
  const [isCollapsed, setIsCollapsed] = useState(false); // this is use to toggle the sidebar

  // this will be used to detect mobile view 
  // if true, we will not render the normal sidebar
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // getting the path for the login page
  const isLoginPage = location.pathname === "/";


  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;

  useEffect(() => {
    const appHeight = () => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    // Set the initial height
    appHeight();

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', appHeight);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to adjust container height
  const adjustContainerHeight = () => {
    const viewportHeight = window.innerHeight;
    const contentContainer = document.querySelector('.content');

    if (contentContainer) {
      contentContainer.style.height = `${viewportHeight - 37}px`;
    }
    //contentContainer.style.height = (viewportHeight - 37) + 'px';
  };

  // Call the function to adjust container height on component mount
  useEffect(() => {
    adjustContainerHeight();
  }, []);

    // Function to test if the current pathname matches any pattern in the arrays
    const matchPath = (patterns, pathname) => {
      return patterns.some(pattern => {
        const regex = new RegExp("^" + pattern.replace(/:[^\s/]+/g, '([^/]+)') + "$");
        return regex.test(pathname);
      });
    };

  const clientPortalPages = ["/ClientDashboard/:projectId", "/ClientCalendar/:projectId", "/ClientTodos/:projectId",
                           "/ClientFinishings/:projectId", "/ClientUpdates/:projectId", "/ClientDocSharing/:projectId",
                           "/ClientInvoices/:projectId", "/ClientSettings", "/ClientSettings/reset"];

  const businessPortalPages = ["/Dashboard", "/users", "/users/:clientUserId", "/users/:clientUserId/:projectId",
                              "/users/:clientUserId/:projectId/todos", "/users/:clientUserId/:projectId/finishings",  
                              "/users/:clientUserId/:projectId/notes", "/users/:clientUserId/:projectId/message", 
                              "/users/:clientUserId/:projectId/calendar", "/users/:clientUserId/:projectId/document", 

                              "/users/:clientUserId/:projectId/updates", "/users/:clientUserId/:projectId/employees",
                              "/users/:clientUserId/:projectId/invoices", "/form", "/calendar", "/message", "/internalMessage",

                              "/settings/reset", "/settings", "/adminSettings", "/adminSettings/addEmployees", "/adminSettings/addBrandLogos"];
  
  const isBusinessPortalPage = matchPath(businessPortalPages, location.pathname);
  const isClientPortalPage = matchPath(clientPortalPages, location.pathname);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {!isLoginPage ? (

        <div className="app">
          {/* Only display the Sidebar if it's NOT the login page and if NOT on mobile device*/}
          {isBusinessPortalPage && !isMobile && <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}/>}
          {isClientPortalPage && !isMobile && <ClientSidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}/>}


          <div
            className="content-wrapper"
            style={{
              width: (isBusinessPortalPage || isClientPortalPage) && !isMobile
                ? isCollapsed ? 'calc(100% - 85px)' : 'calc(100% - 205px)'
                : '100%',
              height: (isBusinessPortalPage || isClientPortalPage) && isStandalone
                ? '100vh'
                : 'auto',
                overflowY: isStandalone ? 'auto' : 'initial'
            }}
            
          >
            {/* Only display the Topbar if it's NOT the login page */}
            {isBusinessPortalPage && <Topbar />}
            {/* {isBusinessPortalPage && <Topbar className="topbar"/>} */}

            {isClientPortalPage && <ClientTopbar/>}
        
            {/* login page has a different css styling compared to the others*/}
            <main className={isLoginPage ? "login" : "content"}>

              <Routes>
                <Route path="/ForgotPassword" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                {/* Only display the PrivateRoutes if it's employee is logged in */}
                <Route path="/Dashboard" element={<PrivateRoute> <BuilderDashboard /> </PrivateRoute>} />
                <Route path="/settings" element={<AdminRoute redirectToIfAdmin="/adminSettings"> <Settings /> </AdminRoute>} />
                <Route path="/settings/reset" element={<PrivateRoute> <Reset /> </PrivateRoute>} />
                <Route path="/adminSettings" element={<AdminRoute > <AdminSettings /> </AdminRoute>} />
                <Route path="/adminSettings/addEmployees" element={<AdminRoute > <AddEmployee /> </AdminRoute>} />
                <Route path="/adminSettings/addBrandLogos" element={<AdminRoute > <AddBrandLogos /> </AdminRoute>} />
                <Route path="/users" element={<PrivateRoute> <ProjectPage /> </PrivateRoute>} />
                <Route path="/users/:clientUserId" element={<PrivateRoute> <ProjectList /> </PrivateRoute>} />
                <Route path="/users/:clientUserId/:projectId" element={<PrivateRoute> <BuilderBoard /> </PrivateRoute>} />
                <Route path="/users/:clientUserId/:projectId/todos" element={<PrivateRoute> <TodoPage/></PrivateRoute>} />
                <Route path="/users/:clientUserId/:projectId/finishings" element={<PrivateRoute> <FinishingsPage/></PrivateRoute>}/>
                <Route path="/users/:clientUserId/:projectId/notes" element={<PrivateRoute> <NotesPage/> </PrivateRoute>}/>
                <Route path="/users/:clientUserId/:projectId/message" element={<PrivateRoute> <MessagingFeature/> </PrivateRoute>}/> 
                <Route path="/users/:clientUserId/:projectId/calendar" element={<PrivateRoute> <Calendar/> </PrivateRoute>}/>
                <Route path="/users/:clientUserId/:projectId/document" element={<PrivateRoute> <DocumentSharing/> </PrivateRoute>}/>
                <Route path="/users/:clientUserId/:projectId/updates" element={<PrivateRoute> <UpdatePage/> </PrivateRoute>}/>
                <Route path="/users/:clientUserId/:projectId/employees" element={<PrivateRoute> <EmployeesTable/> </PrivateRoute>}/>

                <Route path="/users/:clientUserId/:projectId/invoices" element={<PrivateRoute> <ContractorInvoices/> </PrivateRoute>}/>

                <Route path="/form" element={<PrivateRoute> <Form /> </PrivateRoute>} />
                <Route path="/calendar" element={<PrivateRoute> <GlobalCalendar /> </PrivateRoute>} />
                <Route path="/message" element={<PrivateRoute> <AllMessages /> </PrivateRoute>} />
                <Route path="/internalMessage" element={<PrivateRoute> <InternalMessage /> </PrivateRoute>} />

                {/* Only display the clientpages if client is logged in */}
                <Route path="/ClientDashboard/:projectId" element={<ClientRoute> <ClientDashboard/> </ClientRoute>} />
                <Route path="/ClientCalendar/:projectId" element={<ClientRoute> <ClientCalendar/> </ClientRoute>} />
                <Route path="/ClientTodos/:projectId" element={<ClientRoute> <ClientTodos/> </ClientRoute>} />
                <Route path="/ClientFinishings/:projectId" element={<ClientRoute> <ClientFinishings/> </ClientRoute>} />
                <Route path="/ClientUpdates/:projectId" element={<ClientRoute> <ClientUpdates/> </ClientRoute>} />
                <Route path="/ClientDocSharing/:projectId" element={<ClientRoute> <ClientDocSharing/> </ClientRoute>} />
                <Route path="/ClientInvoices/:projectId" element={<ClientRoute> <ClientInvoices/> </ClientRoute>} />
                <Route path="/ClientProjectList" element={<ClientRoute> <ClientProjectList/> </ClientRoute>} />
                <Route path="/ClientSettings" element={<ClientRoute> <ClientSettings/> </ClientRoute>} />
                <Route path="/ClientSettings/reset" element={<ClientRoute> <ClientReset/> </ClientRoute>} />
                {/* Catch-all redirect */}
                <Route path="*" element={<Navigate to="/" replace />} />

              </Routes>
            </main>
          </div>
        </div>
        ): (
          <div>
            {/* this is to ensure login uses its own style.css */}
            <main className="login">
          <Routes>
            <Route path="/" element={<LoginForm />} />
          </Routes>
        </main>
        </div>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
