import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Stack,
  Chip,
  MenuItem,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const DeleteEmployee = ({
  open,
  onClose,
  employees,
  onReload,
  deletedUser,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [userIdArr, setUserIdArr] = useState([]);
  const { projectId } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;
  const [getAllEmployees, setGetAllEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const handleSubmit = () => {
    const successorEmployee = selectedItems.map((item) => item.userId);
    deleteEmployee(deletedUser, successorEmployee);
    setSelectedItems([]);
    onClose();
  };

  const deleteEmployee = async (deletedEmployee, successorEmployee) => {
    // Encode your ClientUserDTO objects to ensure they are properly passed as query parameters
    // const deletedEmployeeParam = encodeURIComponent(deletedEmployee);
    // const successorEmployeeParam = encodeURIComponent(successorEmployee);

    try {
      const response = await fetch(
        `${API_URL}/Employee/DeleteEmployee?deletedEmployeeId=${deletedEmployee.userId}&sucessorEmployeeId=${successorEmployee}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);
      onReload(); // Call the parent component's function to refresh the list of employees
      return result;
    } catch (error) {
      console.error("Unable to delete employee:", error);
    }
  };

  useEffect(() => {
    // Only proceed if deletedUser is not null
    if (deletedUser) {
      console.log("Before filtering:", employees);
      const updatedFilteredEmployees = employees.filter(
        (employee) => employee.userId !== deletedUser.userId
      );
      console.log("After filtering:", updatedFilteredEmployees);
      setFilteredEmployees(updatedFilteredEmployees);
    }
  }, [employees, deletedUser]);

  // useEffect(() => {
  //   // Filter out the deletedUser from the list of employees
  //   const updatedFilteredEmployees = employees.filter(
  //     (employee) => employee.userId !== deletedUser.userId
  //   );
  //   setFilteredEmployees(updatedFilteredEmployees);
  // }, [employees, deletedUser]);

  // const filterEmployeesData = (allEmployees) => {
  //   const selectedEmployees = employees.map((employee) => employee.userId);
  //   const result = allEmployees.filter(
  //     (employee) => !selectedEmployees.includes(employee.userId)
  //   );
  //   console.log(result);
  //   setFilteredEmployees(result);
  //   return result;
  // };

  const MultiSelect = ({ employees }) => {
    return (
      <FormControl sx={{ m: 1, width: 500 }}>
        <InputLabel
          id="select-employee"
          sx={{
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          Select Employees
        </InputLabel>
        <Select
          multiple
          value={selectedItems}
          onChange={(e) => setSelectedItems(e.target.value)}
          input={<OutlinedInput label="Select Employees" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value, index) => (
                <Chip
                  key={index}
                  label={value.firstName + " " + value.lastName}
                  onDelete={() =>
                    setSelectedItems(
                      selectedItems.filter(
                        (item) => item.userId !== value.userId
                      )
                    )
                  }
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                />
              ))}
            </Stack>
          )}
        >
          {employees.map((employee) => (
            <MenuItem
              key={employee.id}
              value={employee}
              sx={{ justifyContent: "space-between" }}
            >
              {employee.firstName} {employee.lastName}
              {selectedItems.some((item) => item.userId === employee.userId) ? (
                <CheckIcon color="info" />
              ) : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        style={{
          fontSize: "1.2rem",
          textAlign: "center",
          position: "relative",
        }}
      >
        Select employee to transfer data to:
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 15 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MultiSelect employees={filteredEmployees}></MultiSelect>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "1rem" }}>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!selectedItems.length}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEmployee;
