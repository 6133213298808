import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  IconButton,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams, useNavigate } from "react-router-dom";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

const API_URL = process.env.REACT_APP_API_URL;

const MaterialUIPopupForm = ({
  isOpen,
  setOpen,
  fetchSelections,
  editData,
  onReload,
}) => {
  const { projectId } = useParams();
  const [selectedImageArr, setSelectedImageArr] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [optionIdLists, setOptionIdLists] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(null);

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleDeleteModalOpen = (categoryId, selectionId, index) => {
    setDeleteCategory({ categoryId, selectionId, i: index });
    setDeleteModal(true);
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      room: "",
      date: "",
      allowCustomSelection: false,
      options: [
        {
          name: "",
          category: "",
          description: "",
          partNumber: "",
          constPrice: "",
          retailPrice: "",
          url: "",
          file: [],
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });
  const allowCustomSelection = watch("allowCustomSelection");

  const handleAddOption = async () => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.SELECTIONS)){
      alert("You are not authorized to modify selections. Please contact Admin for help.");
      return;
    }

    append({
      name: "",
      category: "",
      description: "",
      partNumber: "",
      constPrice: "",
      retailPrice: "",
      url: "",
      file: [],
    });
  };

  const handleCheckboxChange = () => {
    setValue("allowCustomSelection", !allowCustomSelection);
    if (!allowCustomSelection) {
      // When unchecking, we reinitialize the options with a default empty field
      setValue("options", [
        {
          name: "",
          category: "",
          description: "",
          partNumber: "",
          constPrice: "",
          retailPrice: "",
          url: "",
          file: [],
        },
      ]);
    } else {
      // When checking, we remove all options fields
      setValue("options", []);
    }
  };

  useEffect(() => {
    if (editData) {
      setEditMode(true);

      setValue("title", editData.title);
      setValue("room", editData.room);
      setValue("date", editData.dueDate);
      setValue(
        "allowCustomSelection",
        editData.status === "WAIT_CONTRACTOR_SELECTION" ? true : false
      );

      setValue("options", []);

      editData.selectionResponseDtoList.forEach((option, index) => {
        append({
          name: option.name,
          category: option.category,
          description: option.description || "",
          partNumber: option.partNumber || "",
          constPrice: option.costPrice || "",
          retailPrice: option.retailPrice || "",
          url: option.url || "",
          file: option.fileUrl || [],
        });
        setSelectedImageArr((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index] = {
            file: { fileUrl: option.fileUrl, fileId: option.fileId },
            index,
            id: option.id,
          };
          return updatedImages;
        });
        setOptionIdLists((prevIds) => {
          const updatedIds = [...prevIds];
          updatedIds[index] = { id: option.id, index };
          return updatedIds;
        });
      });
    }
  }, [editData, append, setValue]);

  const createSelection = async (formData) => {
    try {
      const response = await axios.post(
        `${API_URL}/selections/create`,
        formData,
        {
          withCredentials: true,
          headers: {
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        console.log("saved selection");
        clearAllFormData();
        setOpen(false);
        await fetchSelections();
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 403){
            alert("You are not authorized to add selections. Please contact Admin for help.");
          }
        }
      console.log(error);
    }
  };

  const updateSelections = async (formData) => {
    try {
      const response = await axios.put(
        `${API_URL}/selections/update`,
        formData,
        {
          withCredentials: true,
          headers: {
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        console.log("updated selection");

        clearAllFormData();
        setOpen(false);
        handleDeleteModalClose();
        await onReload();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to modify selections. Please contact Admin for help.");
        }
      }
      console.log(error);
    }
  };

  const clearAllFormData = () => {
    setSelectedImageArr([]);
    setOptionIdLists([]);
    setValue("options", []);
  };

  const handleConfirmDelete = async () => {
    try {
      const selectionCategoryId = deleteCategory.categoryId;
      const selectionId = deleteCategory.selectionId;
      // const projectId = projectId;
      const res = await axios.delete(`${API_URL}/selections/delete`, {
        withCredentials: true,
        headers: {
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
        params: {
          selectionCategoryId,
          selectionId,
          projectId,
        },
      });
      if (res.status === 200 || res.status === 201) {
        console.log("deleted option");
        setOpen(false);
        setEditMode(false);
        handleDeleteModalClose();
        setDeleteCategory(null);
        const imgIndex = deleteCategory.i;
        remove(imgIndex);
        setSelectedImageArr((prevImages) => {
          const updatedImages = prevImages.filter(
            (image) => image && image.index !== imgIndex
          );
          return updatedImages;
        });
        setOptionIdLists((prevIds) => {
          const updateIds = prevIds.filter(
            (ids) => ids && ids.index !== imgIndex
          );
          return updateIds;
        });

        clearAllFormData();
        onReload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (fileList, index, optionId) => {
    // Here we use 'setValue' from react-hook-form to set the file
    setValue(`options[${index}].file`, fileList);
    (async () => {
      await handleGetImageDetails(fileList, index, optionId);
    })();
    // setSelectedImageArr((prevImages) => {
    //   const updatedImages = [...prevImages];
    //   updatedImages[index] = { fileList, index };
    //   return updatedImages;
    // });
    console.log(selectedImageArr);
  };

  const handleDeleteOption = async(index, option) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.SELECTIONS)){
      alert("You are not authorized to delete selections. Please contact Admin for help.");
      return;
    }

    console.log(option);
    console.log(editData);
    if (editMode && option.name && option.category) {
      const selectionCategoryId = editData.id;
      const selectionId = optionIdLists[index].id;
      handleDeleteModalOpen(selectionCategoryId, selectionId);
    } else {
      remove(index);
      setSelectedImageArr((prevImages) => {
        const updatedImages = prevImages.filter(
          (image) => image && image.index !== index
        );
        return updatedImages;
      });
      setOptionIdLists((prevIds) => {
        const updateIds = prevIds.filter((ids) => ids && ids.index !== index);
        return updateIds;
      });
    }
  };

  const handlePhotoDelete = async (index) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.SELECTIONS)){
      alert("You are not authorized to modify selections. Please contact Admin for help.");
      return;
    }

    console.log(index);
    // console.log(optionIdLists[index].id)
    // if(editMode && optionIdLists[index].id) {
    //   const newId = optionIdLists[index].id;
    //   setSelectedImageArr((prevImages) => {
    //     const updatedImages = prevImages.filter((image) => image && image.id !== newId);
    //     console.log('Updated images:', updatedImages);
    //     return updatedImages;
    //   })
    // } else {
    //   // console.log("id", id)
    //   setSelectedImageArr((prevImages) => {
    //     const updatedImages = prevImages.filter((image) => image && image.index !== index);
    //     console.log('Updated images:', updatedImages);
    //     return updatedImages;
    //   });
    // }
    console.log(selectedImageArr);
    setSelectedImageArr((prevImages) => {
      const updatedImages = [...prevImages];
      const filteredImages = updatedImages.filter(
        (image) => image.index !== index
      );
      console.log("Updated images:", filteredImages);
      return filteredImages;
    });
  };

  const onSubmit = async (data) => {
    const businessId = Cookies.get("businessId");
    const userId = Cookies.get("userId");
    const formData = new FormData();
    // const emptyArr = [];
    // const f1 = new FormData()
    // Append fields to formData here
    console.log(data);
    // TODO: Implement submission logic
    // Append mandatory and simple text fields
    formData.append("businessId", businessId);
    formData.append("userId", userId);
    formData.append("title", data.title);
    formData.append("projectId", projectId);
    formData.append("room", data.room);
    formData.append("dueDate", data.date); // Formatting LocalDate as expected by the backend
    if ((data, allowCustomSelection)) {
      formData.append("status", "WAIT_CONTRACTOR_SELECTION");
      // formData.append('selectionsDTOList', JSON.stringify([]))
    }
    if (editMode) {
      formData.append("id", editData.id);
      // formData.append("selectionsDTOList", '__EMPTY_ARRAY__')
    }
    // Append selections with files
    for (const [index, option] of data.options.entries()) {
      if (option.name && option.category) {
        // Check for mandatory fields
        formData.append(`selectionsDTOList[${index}].name`, option.name);
        formData.append(
          `selectionsDTOList[${index}].category`,
          option.category
        );

        // Optional fields
        if (option.description)
          formData.append(
            `selectionsDTOList[${index}].description`,
            option.description
          );
        if (option.partNumber)
          formData.append(
            `selectionsDTOList[${index}].partNumber`,
            option.partNumber
          );
        if (option.constPrice)
          formData.append(
            `selectionsDTOList[${index}].costPrice`,
            option.constPrice
          );
        if (option.retailPrice)
          formData.append(
            `selectionsDTOList[${index}].retailPrice`,
            option.retailPrice
          );
        if (option.url)
          formData.append(`selectionsDTOList[${index}].url`, option.url);
        if (option.file && option.file.name && !editMode) {
          formData.append(
            `selectionsDTOList[${index}].fileId`,
            selectedImageArr[index].file.fileId
          );
          formData.append(
            `selectionsDTOList[${index}].fileUrl`,
            selectedImageArr[index].file.fileUrl
          );
        }
        if (
          editMode &&
          selectedImageArr[index] &&
          selectedImageArr[index].file
        ) {
          formData.append(
            `selectionsDTOList[${index}].fileId`,
            selectedImageArr[index].file.fileId
          );
          formData.append(
            `selectionsDTOList[${index}].fileUrl`,
            selectedImageArr[index].file.fileUrl
          );
        }
        if (editMode && optionIdLists[index] && optionIdLists[index].id) {
          formData.append(
            `selectionsDTOList[${index}].selectionId`,
            optionIdLists[index].id
          );
        }
        console.log(optionIdLists);
        console.log(option.file);
      }
    }

    if (!editMode) {
      await createSelection(formData);
    } else if (editMode) {
      await updateSelections(formData);
    }
  };

  const handleGetImageDetails = async (file, index, optionId) => {
    try {
      const f1 = new FormData();
      // const businessId = Cookies.get('businessId');
      if (file && file.name) {
        f1.append("file", file);
        const response = await axios.post(
          `${API_URL}/selections/upload-image`,
          f1,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
              // "Content-Type": "application/json",
              "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },
            params: {
              projectId,
            },
          }
        );
        if (response.status === 200 || response.status === 201) {
          console.log("image uploaded", response.data, index);
          // file: {fileUrl: option.fileUrl, fileId: option.fileId}, index, id: option.id, key: `key_${index}`
          setSelectedImageArr((prevImages) => {
            // const updatedImages = [...prevImages];
            // updatedImages[index] = { file: {fileUrl: response.data.fileUrl, fileId: response.data.fileId}, index, id: optionId };
            // return updatedImages;
            const updatedImages = [...prevImages];
            const newImage = {
              file: {
                fileUrl: response.data.fileUrl,
                fileId: response.data.fileId,
              },
              index,
              id: optionId,
            };
            const existingIndex = updatedImages.findIndex(
              (image) => image && image.index === index
            );

            if (existingIndex !== -1) {
              // If an image with the same index exists, update it
              updatedImages[existingIndex] = newImage;
            } else {
              // If no image with the same index exists, add the new image
              updatedImages.push(newImage);
            }

            return updatedImages;
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getImage = (fileId) => {
    const url = `${API_URL}/${fileId}`;
    return url;
  };

  const handleImageError = (event) => {
    event.target.src = "https://placehold.co/600x400?text=No+Image";
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    clearAllFormData();
    setOpen(false);
  };

  const handleDateChange = (event) => {
    setValue("date", event.target.value);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown
    >
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} pb={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  {...register("title")}
                  label="Title"
                  required
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  {...register("room")}
                  label="Room"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                {/* <DatePicker
                  label="Due Date"
                  value={watch("date")}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.dueDate}
                      helperText={
                        errors.dueDate ? errors.dueDate.message : null
                      }
                    />
                  )}
                /> */}
                <TextField
                  type="date"
                  label="Due Date"
                  {...register("date")}
                  required
                  error={!!errors.dueDate}
                  helperText={errors.dueDate ? errors.dueDate.message : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register("allowCustomSelection")}
                      onChange={handleCheckboxChange}
                      disabled={editMode}
                    />
                  }
                  label="Allow client to request their own selection"
                />
              </Grid>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            {!allowCustomSelection &&
              fields.map((field, index) => (
                <Box
                  sx={{
                    border: 2,
                    mb: 2,
                    borderColor: "rgba(0, 0, 0, 0.4)",
                    position: "relative",
                  }}
                  key={index}
                >
                  {/* {console.log(field)} */}

                  <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        {...register(`options[${index}].category`)}
                        label="Category"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        {...register(`options[${index}].name`)}
                        label="Name"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        {...register(`options[${index}].partNumber`)}
                        label="Part Number"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        {...register(`options[${index}].constPrice`)}
                        type="number"
                        inputProps={{
                          step: 0.01,
                        }}
                        label="Cost Price"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        {...register(`options[${index}].retailPrice`)}
                        type="number"
                        inputProps={{
                          step: 0.01,
                        }}
                        label="Retail Price"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        {...register(`options[${index}].url`)}
                        label="URL"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={11}>
                      <TextField
                        {...register(`options[${index}].description`)}
                        label="Description"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <label htmlFor={`file-input-${index}`}>
                        {selectedImageArr.find(
                          (image) => image.index === index
                        ) ? (
                          <Box position="relative">
                            <img
                              src={getImage(
                                selectedImageArr.find(
                                  (image) => image.index === index
                                )?.file.fileUrl
                              )}
                              alt={`Selected ${
                                selectedImageArr.find(
                                  (image) => image.index === index
                                ).index
                              }`}
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                              onError={handleImageError}
                            />
                            <IconButton
                              color="secondary"
                              onClick={() => handlePhotoDelete(index)}
                              sx={{
                                position: "absolute",
                                top: ["-11%", "-30%"],
                                right: ["-7%", "-25%"],
                                color: "#bb2929",
                              }}
                            >
                              <CancelRoundedIcon />
                            </IconButton>
                          </Box>
                        ) : (
                          <IconButton
                            color="primary"
                            component="label"
                            sx={{ display: "flex" }}
                          >
                            <AddAPhotoIcon />
                            <input
                              id={`file-input-${index}`}
                              hidden
                              type="file"
                              accept="image/*"
                              onChange={(event) =>
                                handleFileChange(
                                  event.target.files[0],
                                  index,
                                  selectedImageArr.find(
                                    (image) => image.index === index
                                  )
                                    ? selectedImageArr.find(
                                        (image) => image.index === index
                                      ).id
                                    : ""
                                )
                              }
                            />
                          </IconButton>
                        )}
                      </label>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteOption(index, field)}
                        sx={{
                          position: "absolute",
                          top: ["-3%", "-10%"],
                          right: ["-6%", "-1.8%"],
                          color: "#bb2929",
                          display: "flex",
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            {!allowCustomSelection && (
              <Button
                variant="contained"
                sx={{
                  marginRight: 2,

                  backgroundColor: "#0b1537",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    color: "#0b1537",
                    border: 0.5,
                    borderColor: "#0b1537",
                  },
                }}
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleAddOption}
              >
                Add Option
              </Button>
            )}
            <Box textAlign={"right"}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#8bc34a",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    color: "#8bc34a",
                    border: 0.5,
                    borderColor: "#8bc34a",
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </form>
        </LocalizationProvider>
      </DialogContent>
      {/* Delete Modal */}
      <Dialog
        open={deleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          fontSize={"18px"}
          fontWeight={"500"}
        >
          Are you sure you want to delete this selection?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeleteModalClose}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default MaterialUIPopupForm;
