import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmRemoveEmployee from "./components/confirmRemoveEmployee";
import AddEmployeeToProject from "./components/addEmployeeModal";
import AddIcon from "@mui/icons-material/Add";
//import { FaCrown } from "react-icons/fa";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import axios from "axios";
import Cookies from "js-cookie";
import { tokens } from "../../theme";
import { useTheme } from "@mui/system";

const EmployeesTable = () => {
  const [project, setProject] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isAddEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [employeeListLoading, setEmployeeListLoading] = useState(true);
  const [filteredEmployeesData, setFilteredEmployeesData] = useState(null);

  const { clientUserId } = useParams();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigateTo = (path) => () => {
    navigate(path);
  };

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteEmployee(selectedItem.userId);
    setConfirmDialogOpen(false);
    handleReload();
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    handleReload();
  };

  const handleOpenAddEmployeeModal = () => {
    setAddEmployeeModalOpen(true);
    handleReload();
  };

  const handleCloseAddEmployeeModal = () => {
    setAddEmployeeModalOpen(false);
    handleReload();
  };

  const handleDeleteEmployee = async (employeeId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/user-projects/delete?projectId=${projectId}&employeeId=${employeeId}`,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      console.log(response);
      handleReload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmployeeList = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/user-projects/get-all?projectId=${projectId}`,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      console.log(res.data);
      const data = await res.data;
      const sortedData = data.sort((a, b) => {
        if (a.role === "OWNER") return -1;
        if (b.role === "OWNER") return 1;
        return 0;
      });
      setSelectedEmployeeList(sortedData);
      setEmployeeListLoading(false);
      handleFindAdmin(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetFilteredData = async () => {
    try {
      const viewEmployeesData = await axios.get(
        `${API_URL}/user-projects/get-all-employees?projectId=${projectId}`,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      const projectEmployeesData = viewEmployeesData.data;
      handleFilteredEmployees(projectEmployeesData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFindAdmin = () => {
    if (user?.role === "employee_admin") {
      setIsOwner(true);
    } else {
      handleFindOwner(user?.userId);
    }
  };

  const handleFindOwner = (ownerId) => {
    if (user && selectedEmployeeList) {
      let owner = selectedEmployeeList.find(
        (employee) => employee.role === "OWNER"
      );
      if (owner && owner.userId === ownerId) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  };

  const getLoggedInUser = async () => {
    try {
      const userId = Cookies.get("userId");
      const response = await fetch(
        `${API_URL}/client/get-single-user?userId=${userId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      const data = await response.json();
      if (data.role === "employee_admin") setIsOwner(true);
      else handleFindOwner(userId);
      setUser(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await fetch(
          `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
            },
          }
        );
        const projectData = await projectResponse.json();
        setProject(projectData);

        const userResponse = await fetch(
          `${API_URL}/client/get-single-user?userId=${Cookies.get("userId")}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
            },
          }
        );
        const userData = await userResponse.json();
        setUser(userData);
        setUserLoading(false);

        const selectedEmployeesResponse = await axios.get(
          `${API_URL}/user-projects/get-all?projectId=${projectId}`,
          {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },
          }
        );
        const selectedEmployeesData = selectedEmployeesResponse.data;
        const sortedData = selectedEmployeesData.sort((a, b) => {
          if (a.role === "OWNER") return -1;
          if (b.role === "OWNER") return 1;
          return 0;
        });
        setSelectedEmployeeList(sortedData);
        setEmployeeListLoading(false);

        const viewEmployeesData = await axios.get(
          `${API_URL}/user-projects/get-all-employees?projectId=${projectId}`,
          {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },
          }
        );
        const projectEmployeesData = viewEmployeesData.data;
        handleFilteredEmployees(projectEmployeesData);

        if (userData.role === "employee_admin") {
          setIsOwner(true);
        } else {
          const owner = sortedData.find(
            (employee) => employee.role === "OWNER"
          );
          setIsOwner(owner.userId === Cookies.get("userId"));
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [API_URL, projectId]);

  const handleFilteredEmployees = (employees) => {
    const selectedEmployees = selectedEmployeeList.map(
      (employee) => employee.userId
    );
    const result = employees.filter(
      (employee) => !selectedEmployees.includes(employee.userId)
    );
    setFilteredEmployeesData(result);
  };

  const handleReload = () => {
    getLoggedInUser();
    handleEmployeeList();
    handleGetFilteredData();
  };

  return (
    <Box>
      {isLoading || userLoading || employeeListLoading ? (
        <>You do not have the proper permissions to view this page</>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
          >
            <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
              {/* Clickable navigation links */}
              <Typography
                variant="h6"
                component="span"
                sx={{ cursor: "pointer" }}
                onClick={navigateTo(`/users`)}
              >
                Clients
              </Typography>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              {/* Arrow icon */}
              <Typography
                variant="h6"
                component="span"
                sx={{ cursor: "pointer" }}
                onClick={navigateTo(`/users/${clientUserId}`)}
              >
                Projects
              </Typography>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              {/* Arrow icon */}
              <Typography
                variant="h6"
                component="span"
                sx={{ cursor: "pointer" }}
                onClick={navigateTo(`/users/${clientUserId}/${projectId}`)}
              >
                {project?.address}
              </Typography>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              <Typography variant="h6" component="span" fontWeight="bold">
                Employees list
              </Typography>
            </Box>
          </Box>
          <Box m="20px" bgcolor={colors.primary[400]} p={3} borderRadius="10px">
            <Box sx={{ display: isOwner ? "block" : "none" }}>
              <Button
                onClick={handleOpenAddEmployeeModal}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add employee
              </Button>
            </Box>
            <Box marginTop={"1rem"}>
              <Paper elevation={2}>
                <List>
                  {selectedEmployeeList &&
                    selectedEmployeeList.map((item, index) => (
                      <ListItem
                        button
                        component={RouterLink}
                        key={index}
                        sx={{
                          backgroundColor: colors.white,
                          mb: 1,
                          borderRadius: "10px",
                        }}
                      >
                        <ListItemIcon>
                          <AccountCircleIcon
                            sx={{
                              color: colors.primary[500],
                              fontSize: { xs: "24px", sm: "30px" },
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Box sx={{ padding: "0.5rem 0" }}>
                              <Typography
                                textTransform={"capitalize"}
                                fontSize={"1rem"}
                              >
                                {item.firstName} {item.lastName}
                              </Typography>
                            </Box>
                          }
                          primaryTypographyProps={{
                            fontSize: { xs: "16px", sm: "18px" },
                          }}
                        />
                        <ListItemSecondaryAction>
                          {item.role === "OWNER" ? (
                            <IconButton edge="end">
                              <AdminPanelSettingsIcon
                                style={{ color: "#b79b06" }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              sx={{ display: isOwner ? "block" : "none" }}
                              onClick={() => handleDeleteClick(item)}
                            >
                              <DeleteIcon sx={{ color: "#b72222" }} />
                            </IconButton>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
              </Paper>
            </Box>
          </Box>
          <ConfirmRemoveEmployee
            open={isConfirmDialogOpen}
            onClose={handleCancelDelete}
            onConfirm={handleConfirmDelete}
            employeeName={selectedItem}
          />
          <AddEmployeeToProject
            open={isAddEmployeeModalOpen}
            onClose={handleCloseAddEmployeeModal}
            employees={filteredEmployeesData}
            onReload={handleReload}
          />
        </>
      )}
    </Box>
  );
};

export default EmployeesTable;
