import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useState, useInsertionEffect, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import MobileSidebar from "./mobileSidebar";
import LogoutIcon from "@mui/icons-material/Logout";
const Topbar = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [cookie, setCookie] = useState("defaut cookie");

  // this will be used to detect mobile view
  // if true, we will render a mobileSidebar in the topbar
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const newCookie = parts.pop().split(";").shift();
      setCookie(newCookie);
    }
  };

  useInsertionEffect(() => {
    const fetchData = async () => {
      const temp = await fetch(`${API_URL}/welcome`, {
        method: "GET",
        credentials: "include",
      });
      getCookie("XSRF-TOKEN");
    };

    fetchData();
  }, []);

  const navigateToSettings = () => {
    navigate("/settings");
  };

  const handleLogout = async () => {
    const response = await fetch(`${API_URL}/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": cookie,
      },
    }).then(navigate("/"));
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        mx: 2,
      }}
    >
      <Box>
        {/* Conditionally rendering mobileSidebar*/}
        {isMobile && <MobileSidebar />}
      </Box>

      <Box justifyContent="flex-end">
        <Tooltip title="Settings">
          <IconButton onClick={navigateToSettings}>
            <SettingsOutlinedIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Logout">

          <IconButton onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Topbar;
