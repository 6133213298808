import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { tokens } from "../../theme";
import { useTheme } from "@mui/system";
import axios from "axios"; // Step 1: Import axios
import Cookies from "js-cookie";

function UsersList() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State to manage search term
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [showActiveClientsOnly, setShowActiveClientsOnly] = useState(false);

  //TODO: these need to change, shouldnt hard code businessId and API_URL
  // userId should be tied into authentication so its always in the url
  //api_url will change once we host it on an actual server
  const userId = Cookies.get("userId"); // replace with dynamic value if needed
  const API_URL = process.env.REACT_APP_API_URL;
  console.log(API_URL);

  useEffect(() => {
    async function fetchData() {
      try {
        // Then use it in your fetch call:
        const response = await fetch(
          `${API_URL}/client/get-users?userId=${userId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );
        
        if(response.ok && response.status === 200) {
          const data = await response.json();
          setUsers(data);
          setLoading(false);
        } else {
          if(response.status === 403){
            alert("You are not authorized to view client data. Please contact Admin for help.");
          }
          setLoading(false);
          setUsers([]);
        }

      } catch (error) {
        console.error("Error fetching user data:", error);
      } 
    }

    fetchData();
  }, [userId]);

  // const fetchProjects = async () => {
  //   try {
  //     const response = await fetch(
  //       `${API_URL}/project/get-projects-for-client?clientUserId=${userId}`,
  //       {
  //         method: "GET",
  //         credentials: "include",
  //         headers: {
  //           Accept: "application/json",
  //           //'X-XSRF-TOKEN': cookie
  //         },
  //       }
  //     );
  //     const data = await response.json();
  //     setProjects(data);
  //   } catch (error) {
  //     console.error("Error fetching user data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   {
  //     fetchProjects();
  //   }
  // }, [userId]);

  //TODO: ENDPOINT TO DELETE USER DOESNT EXIST YET CREATE IT AND THEN FINISH THIS:

  // // Function to handle delete action
  // const handleDelete = (note) => {
  //   setClientToDelete(note);
  //   setDeleteDialogOpen(true);
  // };

  // const handleConfirmDelete = async () => {
  //   try {
  //     // Close the confirmation dialog
  //     setDeleteDialogOpen(false);

  //     // Make DELETE request to your backend
  //     await axios.delete(
  //       `${API_URL}/project-update/delete/${clientToDelete.projectUpdateId}`
  //     );

  //     // Remove the note from the local state (or refetch all notes)
  //     const newNotes = notes.filter(
  //       (n) => n.projectUpdateId !== noteToDelete.projectUpdateId
  //     );
  //     setNotes(newNotes);

  //     // Optionally set a success message or do other UI updates here
  //   } catch (error) {
  //     console.error("Failed to delete project update:", error);
  //     // Handle the error, e.g., show a notification or an error message
  //   }
  // };

  // Filter users based on search term

  const filteredByActiveProjects = showActiveClientsOnly
    ? users.filter((user) => user.hasActiveProjects)
    : users;

  const finalVisibleUsers = users
    .filter((user) => {
      // Apply active projects filter if toggle is enabled
      return (
        !showActiveClientsOnly ||
        (showActiveClientsOnly && user.activeProjects > 0)
      );
    })
    .filter((user) =>
      // Then, apply the search filter
      `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            mb: "5px",
            zIndex: 100,
            position: "relative",
            p: 3,
            // bgcolor: colors.primary[400],
          }}
        >
          {/* Search Input */}
          <TextField
            label="Search Clients"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ m: 1, width: "300px" }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showActiveClientsOnly}
                onChange={(e) => setShowActiveClientsOnly(e.target.checked)}
                name="showActiveClientsOnly"
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green", // Thumb color when checked
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green", // Track color when checked
                  },
                }}
              />
            }
            label="Only Show Clients with Active Projects"
          />
        </Box>
      </Box>
      <Box m="20px" bgcolor={colors.primary[400]} p={3} borderRadius="10px">
        <Paper elevation={2}>
          <List>
            {finalVisibleUsers.map((user) => (
              <ListItem
                button
                component={RouterLink}
                to={`/users/${user.userId}`}
                key={user.userId}
                sx={{
                  backgroundColor: colors.white,
                  mb: 1,
                  borderRadius: "10px",
                  px: { xs: 1, sm: 2 },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemIcon>
                  <AccountCircleIcon
                    sx={{
                      color: colors.primary[500],
                      fontSize: { xs: "24px", sm: "30px" },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${user.firstName} ${user.lastName}`}
                  primaryTypographyProps={{
                    fontSize: { xs: "16px", sm: "18px" },
                  }}
                />
                {/* Status indicator for active projects */}
                <Box
                  sx={{
                    ml: "auto",
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    backgroundColor: user.activeProjects > 0 ? "green" : "grey",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </Box>
  );
}

export default UsersList;
