import React, { useState, useEffect } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  CardActions,
  Button,
  Box,
  Modal,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  Dialog,
  DialogTitle,
  DialogActions,
  createTheme,
  ThemeProvider,
  IconButton,
  TextareaAutosize,
  DialogContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloseIcon from "@mui/icons-material/Close";

const API_URL = process.env.REACT_APP_API_URL;

const ClientSelectionsComponent = ({
  data,
  rejectedItems,
  onReload,
  isConfirmed,
}) => {
  const businessId = Cookies.get("businessId");
  const userId = Cookies.get("userId");
  const [selectedCard, setSelectedCard] = useState(null);
  const [file, setFile] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const { projectId } = useParams();
  const [categoryId, setCategoryId] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmSelection, setConfirmSelection] = useState({});
  const [clientSelectionCategoryId, setClientSelectionCategoryId] =
    useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const [modalCard, setModalCard] = useState({});
  const [isAccordionExpanded, setAccordionExpanded] = useState(false);
  const [openAccordionSections, setOpenAccordionSections] = useState({});

  const handleAccordionToggle = (index) => {
    setAccordionExpanded(!isAccordionExpanded);
    setOpenAccordionSections((prevOpenSections) => ({
      ...prevOpenSections,
      [index]: !prevOpenSections[index],
    }));
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1E98D7",
      },
    },
  });

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleCloseCardModal = () => {
    setCardModalOpen(false);
  };

  const handleOpenCardModal = (card) => {
    setModalCard(card);
    setCardModalOpen(true);
  };

  const createSelection = async (formData) => {
    try {
      const response = await axios.post(
        `${API_URL}/client-selections/create`,
        formData,
        {
          withCredentials: true,
          headers: {
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
          params: {
            selectionCategoryId: categoryId,
            projectId,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        console.log("saved selection");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    let selectionData = {
      name: formData.get("name"),
    };

    if (formData.get("price"))
      selectionData.retailPrice = formData.get("price");
    if (formData.get("partNumber"))
      selectionData.partNumber = formData.get("partNumber");
    if (formData.get("description"))
      selectionData.description = formData.get("description");
    if (formData.get("url")) selectionData.url = formData.get("url");

    if (file && file.fileId && file.fileUrl) {
      selectionData.fileId = file.fileId;
      selectionData.fileUrl = file.fileUrl;
    }

    console.log(selectionData);
    await createSelection(selectionData);
    handleModalClose();
    onReload();
  };

  const handleOpenCreateSelection = (id) => {
    handleModalOpen();
    setCategoryId(id);
  };

  const confirmClientSelection = async (selectionCategoryId, selectionId) => {
    try {
      const response = await axios.put(
        `${API_URL}/client-selections/confirm`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
          params: {
            selectionCategoryId: selectionCategoryId,
            selectionId: selectionId,
            projectId,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        console.log("saved confirm");
        onReload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRadioChange = () => {
    setIsChecked(!isChecked);
    !isConfirmed && !isChecked && setConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
    setConfirmSelection({});
    handleCardSelect(confirmSelection.id, confirmSelection);
  };

  const handleCardSelectionConfirmed = () => {
    confirmClientSelection(clientSelectionCategoryId, confirmSelection.id);
    handleCardSelect(confirmSelection.id, confirmSelection);
    setConfirmModalOpen(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };
  const handleCardSelect = (id, selection, categoryId) => {
    setConfirmSelection(selection);
    setClientSelectionCategoryId(categoryId);
    setSelectedCard(selectedCard === id ? null : id);
    handleRadioChange();
  };
  // Format date to a human-readable form
  const formatDate = (dateString) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: userTimezone,
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSetImage = (file) => {
    setSelectedImage(file);
  };

  const removeSelectedImage = () => {
    setSelectedImage(null);
  };

  const getImage = (fileId) => {
    const url = `${process.env.REACT_APP_API_URL}/${fileId}`;
    return url;
  };

  const handleImageError = (event) => {
    event.target.src = "https://placehold.co/600x400?text=No+Image";
  };

  return (
    <Grid container spacing={2}>
      {data.map((category, i) => (
        <Grid
          item
          sm={12}
          key={category.id}
          sx={{
            //backgroundColor: "rgba(238, 238, 238, 0.803921568627451)",
            boxShadow: 3,
            boarderRadius: 1,
            position: "relative",
            "@media screen and (max-width: 600px)": {
              width: "100%",
              paddingRight: "16px",
            },
          }}
          mb={i === data.length - 1 ? 0 : 3}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            //alignItems={"center"}
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems="flex-start"
          >
            <Box display={"flex"} flexDirection="column" flexGrow={1}>
              <Typography variant="h5" gutterBottom>
                <strong>{category.title}</strong>
              </Typography>
              {category.room != "" ? (
                <Typography variant="h5" gutterBottom>
                  <strong>Room: </strong>
                  {category.room}
                </Typography>
              ) : null}
            </Box>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginRight: "1rem",
              }}
            >
              <strong>Due Date: </strong>
              {category.dueDate}
            </Typography>
          </Box>
          {category.status === "WAIT_CONTRACTOR_APPROVAL" && (
            <Typography mr={3} textAlign={"right"} color="red">
              Pending for approval
            </Typography>
          )}
          <Grid
            container
            spacing={2}
            pb={"12px"}
            justifyContent={
              category.status !== "WAIT_CLIENT_SELECTION"
                ? "space-between"
                : "start"
            }
          >
            {category.status === "WAIT_CONTRACTOR_SELECTION" && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2.2}
                key={category.id}
                // padding={"1rem 0"}
              >
                <Card
                  sx={{
                    cursor: "pointer",
                    boxShadow: 3,
                  }}
                  onClick={() => handleOpenCreateSelection(category.id)}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    padding={1}
                    pl={2}
                  >
                    {"Add your selection"}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 140,
                    }}
                  >
                    <AddIcon fontSize="large" />
                  </Box>
                  <CardContent>
                    <Box
                      display={"flex"}
                      justifyContent={"space-evenly"}
                      pb={1}
                    ></Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {category.clientSelectionResponseDtos &&
              category.clientSelectionResponseDtos
                .filter((f) => f.selectionStatus !== "REJECTED")
                .map((selection) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.2}
                      key={selection.id}
                    >
                      <Card
                        sx={{
                          // border: selectedCard === selection.id ? 2 : 1,
                          borderColor:
                            selectedCard === selection.id
                              ? "primary.main"
                              : "grey.300",
                          ":hover": {
                            borderColor: "primary.main",
                            cursor: "pointer",
                            boxShadow: 6,
                          },
                          position: "relative",
                          height: "100%",
                          boxShadow: 4,
                        }}
                        onClick={() =>
                          // handleCardSelect(selection.id, selection, category.id)
                          handleOpenCardModal(selection)
                        }
                      >
                        {category.status !== "WAIT_CONTRACTOR_APPROVAL" &&
                          !isConfirmed && (
                            <Radio
                              checked={selectedCard === selection.id}
                              color="primary"
                              checkedIcon={<CheckCircleIcon />}
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                zIndex: 10,
                              }}
                              onChange={() => {
                                handleCardSelect(
                                  selection.id,
                                  selection,
                                  category.id
                                );
                                setCardModalOpen(false);
                              }}
                            />
                          )}
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          padding={1}
                          pl={2}
                        >
                          {selection.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center", // Center the image
                            alignItems: "center", // Vertically center
                            height: "140px", // Fixed height for image container
                            width: "auto", // Fixed width for image container
                            //maxHeight:"",
                            maxWidth: "300",
                            overflow: "hidden", // Hide overflow
                            margin: "auto", // Center the box in the Card
                            //border: 0.01,
                            //boarderColor: "#f3f3f3",
                            borderRadius: 1,
                            //px: 2,
                          }}
                        >
                          <CardMedia
                            component="img"
                            sx={{
                              px: 1,
                              height: "auto", // Auto height for aspect ratio
                              width: "auto", // Auto width for aspect ratio
                              maxHeight: "140px", // Max height for image
                              maxWidth: "200px", // Max width for image
                              objectFit: "contain", // Contain to maintain aspect ratio without cropping
                            }}
                            image={
                              getImage(selection.fileUrl) ||
                              "https://placehold.co/600x400?text=No+Image"
                            }
                            alt={selection.name}
                            onError={handleImageError}
                          />
                        </Box>
                        <CardContent>
                          <Box
                            display={"flex"}
                            justifyContent={"space-evenly"}
                            pb={1}
                          >
                            <Typography variant="body2" color="text.secondary">
                              <strong>Price: </strong>
                              {selection.retailPrice &&
                                `$${selection.retailPrice.toFixed(2)}`}
                            </Typography>
                          </Box>
                          <Typography
                            textAlign={"center"}
                            variant="body1"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {selection.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                ))}
            <Box
              display={"flex"}
              alignSelf={"end"}
              sx={{
                "@media screen and (max-width: 600px)": {
                  marginTop: "10px",
                  marginLeft: "16px",
                },
              }}
            >
              {category.clientSelectionResponseDtos.find(
                (f) => f.selectionStatus === "REJECTED"
              ) && (
                <Button
                  sx={{
                    backgroundColor: "#c3c3c3",
                    alignSelf: "end",
                    margin: "0 16px 10px 0",
                  }}
                  endIcon={<ArrowDownwardIcon />}
                  onClick={() => handleAccordionToggle(i)}
                >
                  View Rejected
                </Button>
              )}
              {isConfirmed && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "end",
                      marginRight: "1rem",
                    }}
                  >
                    <strong>Confirmed on: </strong>
                    {formatDate(category.confirmedOn)}
                  </Typography>
                </Box>
              )}
            </Box>
            {category.clientSelectionResponseDtos.find(
              (f) => f.selectionStatus === "REJECTED"
            ) && (
              <Box width={"100%"} marginTop={"5px"}>
                {openAccordionSections[i] && (
                  <Accordion
                    expanded={isAccordionExpanded}
                    sx={{ boxShadow: "unset" }}
                  >
                    <AccordionSummary
                      aria-controls="panel1-content"
                      sx={{ display: "none" }}
                    ></AccordionSummary>

                    <AccordionDetails
                      sx={{
                        backgroundColor:
                          "rgba(238, 238, 238, 0.803921568627451)",
                      }}
                    >
                      {category.clientSelectionResponseDtos.length > 0 &&
                        category.clientSelectionResponseDtos
                          .filter((el) => el.selectionStatus === "REJECTED")
                          .map((selection, i) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={4}
                                key={selection.id}
                              >
                                <Box
                                  display={"flex"}
                                  gap={1}
                                  width={"100%"}
                                  justifyContent={"space-around"}
                                  sx={{
                                    marginBottom: "1rem",
                                    "@media screen and (max-width: 600px)": {
                                      flexDirection: "column",
                                    },
                                  }}
                                >
                                  <Card
                                    sx={{
                                      borderColor:
                                        selectedCard === selection.id
                                          ? "primary.main"
                                          : "grey.300",
                                      ":hover": {
                                        borderColor: "primary.main",
                                        cursor: "pointer",
                                        boxShadow: 3,
                                      },
                                      flex: 1,
                                    }}
                                    onClick={() =>
                                      handleOpenCardModal(selection)
                                    }
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      component="div"
                                      padding={1}
                                      pl={2}
                                    >
                                      {selection.name}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center", // Center the image
                                        alignItems: "center", // Vertically center
                                        height: "140px", // Fixed height for image container
                                        width: "auto", // Fixed width for image container
                                        //maxHeight:"",
                                        maxWidth: "300",
                                        overflow: "hidden", // Hide overflow
                                        margin: "auto", // Center the box in the Card
                                        //border: 0.01,
                                        //boarderColor: "#f3f3f3",
                                        borderRadius: 1,
                                        //px: 2,
                                      }}
                                    >
                                      <CardMedia
                                        component="img"
                                        sx={{
                                          px: 1,
                                          height: "auto", // Auto height for aspect ratio
                                          width: "auto", // Auto width for aspect ratio
                                          maxHeight: "140px", // Max height for image
                                          maxWidth: "200px", // Max width for image
                                          objectFit: "contain", // Contain to maintain aspect ratio without cropping
                                        }}
                                        image={
                                          getImage(selection.fileUrl) ||
                                          "https://placehold.co/600x400?text=No+Image"
                                        }
                                        alt={selection.name}
                                        onError={handleImageError}
                                      />
                                    </Box>
                                    <CardContent>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"space-evenly"}
                                        pb={1}
                                      >
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          <strong>Price: </strong>
                                          {selection.retailPrice &&
                                            `$${selection.retailPrice.toFixed(
                                              2
                                            )}`}
                                        </Typography>
                                      </Box>
                                      <Typography
                                        textAlign={"center"}
                                        variant="body1"
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {selection.description}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                  <Box>
                                    <Typography
                                      color={"red"}
                                      variant="h5"
                                      marginBottom={"10px"}
                                    >
                                      Reason for rejection
                                    </Typography>
                                    <textarea
                                      style={{
                                        height: "5rem",
                                        resize: "none",
                                        "@media screen and (max-width: 600px)":
                                          {
                                            width: "100%",
                                          },
                                      }}
                                      value={selection.comment}
                                      disabled
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      ))}
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="add-selection-form"
      >
        <Box sx={modalStyle} component="form" onSubmit={handleSubmit}>
          <Typography
            id="add-selection-form"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Add Your Selection
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoFocus
          />
          <Box textAlign={"center"}>
            <label htmlFor="file">
              {selectedImage ? (
                <>
                  <Box position="relative" sx={{ height: "10rem" }}>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt={`Selected ${selectedImage.index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <IconButton
                      color="secondary"
                      onClick={() => removeSelectedImage()}
                      sx={{
                        position: "absolute",
                        top: "-7%",
                        right: "-5%",
                        color: "#bb2929",
                      }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <>
                  <input
                    hidden
                    type="file"
                    accept="image/*"
                    name="file"
                    id="file"
                    onChange={async (event) => {
                      handleSetImage(event.target.files[0]);
                      console.log(event.target.files);
                      const files = event.target.files;
                      if (files.length > 0) {
                        try {
                          const f1 = new FormData();
                          f1.append("file", files[0]);
                          const response = await axios.post(
                            `${API_URL}/client-selections/upload-image`,
                            f1,
                            {
                              withCredentials: true,
                              headers: {
                                "Content-Type": "multipart/form-data",
                                "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
                              },
                              params: {
                                projectId,
                              },
                            }
                          );
                          if (
                            response.status === 200 ||
                            response.status === 201
                          ) {
                            console.log("image uploaded", response.data);
                            setFile({
                              fileId: response.data.fileId,
                              fileUrl: response.data.fileUrl,
                            });
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      }
                    }}
                  />
                  <Box border={1} p={3} mt={1} style={{ cursor: "pointer" }}>
                    <AddIcon fontSize="large" />
                    <Typography>Upload Photo</Typography>
                  </Box>
                </>
              )}
            </label>
          </Box>
          <Box display={"flex"} columnGap={2}>
            <TextField
              margin="normal"
              fullWidth
              id="price"
              label="price"
              name="price"
              type="number"
              inputProps={{
                step: 0.01,
              }}
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              id="partNumber"
              label="Part Number"
              name="partNumber"
              autoFocus
            />
          </Box>
          <TextField
            required
            margin="normal"
            fullWidth
            id="description"
            label="description"
            name="description"
            autoFocus
          />
          <TextField
            margin="normal"
            fullWidth
            id="url"
            label="url"
            name="url"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      {/* Confirm Modal */}
      <ThemeProvider theme={theme}>
        <Dialog
          open={isConfirmModalOpen}
          onClose={handleConfirmModalClose}
          style={{ width: "40vw", margin: "auto" }}
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            Are you sure you want to confirm this selection?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleConfirmModalClose}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleCardSelectionConfirmed}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* Modal  */}
        <Dialog open={cardModalOpen} onClose={handleCloseCardModal}>
          <DialogTitle sx={{ position: "relative" }}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseCardModal}
              sx={{ position: "absolute", top: 8, right: 15 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ overflow: "hidden", width: "100%" }}>
            <Box>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                padding={1}
                pl={2}
              >
                {modalCard.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center", // Center the image
                  alignItems: "center", // Vertically center
                  height: "350px", // Fixed height for image container
                  width: "auto", // Fixed width for image container
                  //maxHeight:"",
                  maxWidth: "400px",
                  overflow: "hidden", // Hide overflow
                  margin: "auto", // Center the box in the Card
                  //border: 0.01,
                  //boarderColor: "#f3f3f3",
                  borderRadius: 1,
                  //px: 2,
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    px: 1,
                    height: "auto", // Auto height for aspect ratio
                    width: "auto", // Auto width for aspect ratio
                    maxHeight: "350px", // Max height for image
                    maxWidth: "400px", // Max width for image
                    objectFit: "contain", // Contain to maintain aspect ratio without cropping
                  }}
                  image={
                    getImage(modalCard.fileUrl) ||
                    "https://placehold.co/600x400?text=No+Image"
                  }
                  alt={modalCard.name}
                  onError={handleImageError}
                />
              </Box>
              <CardContent>
                <Box display={"flex"} justifyContent={"space-evenly"} pb={1}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "20px" }}
                  >
                    <strong>Price: </strong>
                    {modalCard.retailPrice &&
                      `$${modalCard.retailPrice.toFixed(2)}`}
                  </Typography>
                </Box>
                <Box sx={{ maxHeight: 120, overflowY: 'auto', width: '100%' }}>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  sx={{
                    fontSize: "20px",
                    width: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  {modalCard.description}
                </Typography>
                </Box>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  sx={{
                    fontSize: "20px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <a
                    href={modalCard.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {modalCard.url}
                  </a>
                </Typography>
                {modalCard.comment && (
                  <TextareaAutosize
                    textAlign={"center"}
                    variant="body1"
                    disabled
                    value={modalCard.comment}
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      height: "5rem",
                      fontSize: "13px",
                      resize: "none",
                    }}
                  />
                )}
              </CardContent>
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </Grid>
  );
};

export default ClientSelectionsComponent;
