import React, { useState, useEffect, useInsertionEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = date.toLocaleString("en-US", {
    month: "long",
    timeZone: "UTC",
  });
  const day = date.getUTCDate().toString().padStart(2, "0");
  return `${month} ${day}, ${year}`;
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${minutes} ${ampm}`;
};

const EventDetailsDialog = ({
  selectedEvent,
  setSelectedEvent,
  projectId,
  clientUserId,
}) => {
  const allDay = selectedEvent?.allDay;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [error, setError] = useState(null);
  const [user, setUser] = useState([]);

  const API_URL = process.env.REACT_APP_API_URL;

  //Get Project Info
  useEffect(() => {
    async function fetchProjectData() {
      if (!selectedEvent || !selectedEvent.projectId) return;

      try {
        const response = await fetch(
          `${API_URL}/project/get-single-project-for-client?projectId=${selectedEvent.projectId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        const data = await response.json();
        setProject(data);

        setLoading(false);
      } catch (err) {
        console.error(
          `Error fetching data for userId ${selectedEvent.projectId}:`,
          err
        );
        setError(err);
        setLoading(false);
      }
    }
    fetchProjectData();
  }, [selectedEvent?.projectId]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${API_URL}/client/get-single-user?userId=${project.clientId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (project?.clientId) {
      fetchData();
    }
  }, [project?.clientId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data!</div>;
  }

  return (
    <Dialog
      open={Boolean(selectedEvent)}
      onClose={() => setSelectedEvent(null)}
      aria-labelledby="event-details-title"
      sx={{
        "& .MuiDialog-container .MuiPaper-root": {
          borderRadius: 2,
          padding: 2,
        },
      }}
    >
      <DialogTitle
        id="event-details-title"
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        {selectedEvent?.title}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography sx={{ color: "#333", fontWeight: "medium" }}>
          <strong>Date:</strong>{" "}
          {selectedEvent?.start ? formatDate(selectedEvent.start) : ""}
        </Typography>

        {allDay ? (
          <Typography sx={{ color: "#333", fontWeight: "medium" }}>
            <strong>All Day</strong>
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography sx={{ color: "#333", fontWeight: "medium" }}>
              <strong>Start:</strong>{" "}
              {selectedEvent?.start ? formatTime(selectedEvent.start) : ""}
            </Typography>
            <Typography sx={{ color: "#333", fontWeight: "medium" }}>
              <strong>End:</strong>{" "}
              {selectedEvent?.end ? formatTime(selectedEvent.end) : ""}
            </Typography>
          </Box>
        )}

        <Typography sx={{ color: "#333", fontWeight: "medium" }}>
          <strong>Address:</strong> {project?.address || "N/A"}
        </Typography>
        <Typography sx={{ color: "#333", fontWeight: "medium" }}>
          <strong>Client:</strong>{" "}
          {`${user?.firstName || "N/A"} ${user?.lastName || ""}`.trim()}
        </Typography>
        <Button
          sx={{
            marginTop: 2,
            alignSelf: "center",
            backgroundColor: colors.MoonBlue[100],
            color: colors.white[500],
            ":hover": {
              backgroundColor: "#115293",
            },
            fontWeight: "bold",
          }}
          onClick={() =>
            navigate(
              `/users/${project?.clientId}/${selectedEvent.projectId}/calendar`
            )
          }
        >
          View Project Calendar
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default EventDetailsDialog;
