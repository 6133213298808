import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Snackbar,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteEmployee from "./DeleteEmployee";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { gridColumnsSelector } from "@mui/x-data-grid";
import Divider from '@mui/material/Divider'; 
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"


// multiple textfields used in dialog box for adding new employee
const RenderTextField = ({ name, label, value, onChange }) => (
  <TextField
    margin="dense"
    name={name}
    label={label}
    fullWidth
    value={value}
    onChange={onChange}
    sx={{ input: { fontSize: "16px" } }}
  />
);

const AddEmployee = () => {
  const [employees, setEmployees] = useState([]); // hold list of employees
  const [open, setOpen] = useState(false); // is the dialog box for adding new employee open
  const [currentStep, setCurrentStep] = useState(1); // holds the current step of adding new employee (2 step process)
  const [editMode, setEditMode] = useState(false); // indicated if we are editing permissions for existing user
  const [backupPermissions, setBackupPermissions] = useState({}); // store a backup of an employees permissions before we start editing them
  const [error, setError] = useState(""); // stores error message that is displayed in snackbar
  const API_URL = process.env.REACT_APP_API_URL; 
  const [selectedItem, setSelectedItem] = useState(null); // hold the employee we selected to delete
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false); // I think this is also used for delete employee
  const [OpenEmployeeInfo, setOpenEmployeeInfo] = useState(false); // this is used to open/close the dialog box used for displaying existing employee info
  const [selectedEmployee, setSelectedEmployee] = useState(null);


  const [newEmployee, setNewEmployee] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    phoneNumber: ""
  });

  const defaultPermissions = Object.keys(ACCESS_CATEGORIES).reduce((acc, key) => {
    acc[ACCESS_CATEGORIES[key]] = 'no_access';
    return acc;
  }, {});

  const [permissions, setPermissions] = useState(defaultPermissions);
  


  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    const response = await axios.get(
      `${API_URL}/Employee/Get-All?businessId=${Cookies.get("businessId")}`, {
        withCredentials: true,
        withXSRFToken: true,
      }
    );
    setEmployees(response.data);
  };

  const handleOpen = () => {
    setOpen(true);
    setCurrentStep(1);
  };

  const handleOpenEmployeeInfo = (employee) => {
    setSelectedEmployee(employee);
    setOpenEmployeeInfo(true);
    getPermissions(employee)
  };
  const handleCloseEmployeeInfo = () => {
    setSelectedEmployee(null);
    setOpenEmployeeInfo(false);
    setEditMode(false);
    resetPermissions();
  };

  const resetPermissions = () => {
    setPermissions(defaultPermissions);
  }

  const handleClose = () => {
    setOpen(false);
    setError("");
    setNewEmployee({
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      phoneNumber: ""
    }); 
    resetPermissions();
    setCurrentStep(1);
  };

  const handleBack = () => {
    setCurrentStep(1);
  }

  const handleInputChange = (e) => {
    if(e.target.name == "email"){
      // when updating the email, we also have to set the username as the email
      setNewEmployee({ ...newEmployee, ["userName"]: e.target.value, [e.target.name]: e.target.value });
      return
    }
    setNewEmployee({ ...newEmployee, [e.target.name]: e.target.value });
  };

  const isFormValid = () => {
    return Object.values(newEmployee).every((field) => field.trim() !== "");
  };

  const handleCreate = async () => {
    // setting the userName to be equal to the email
    // this is technically redundant
    newEmployee.userName = newEmployee.email;

    if (!isFormValid()) {
      setError("Please fill in all fields");
      return;
    }

    try {
      // first create the employee
      const response = await axios.post(`${API_URL}/Employee/Create`, {
        ...newEmployee,
        businessId: Cookies.get("businessId")
      }, {
        withCredentials: true,
        withXSRFToken: true,
      });
      setEmployees([...employees, response.data]);

      // now set their permissions
      if(response.status == 200 && response.data.userId && response.data.role){

        const permResponse = await axios.put(`${API_URL}/Employee/ModifyEmployeePermissions`,
        {
          userId: response.data.userId,
          role: response.data.role,
          permissions: permissions
        },
        {
          withCredentials: true,
          withXSRFToken: true,
        });

      }

      handleClose();

    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message || "Error creating employee");
      }
    }
  };

  const handleDeleteClick = (deleteEmployee, event) => {
    event.stopPropagation();
    setSelectedItem(deleteEmployee);
    setConfirmDialogOpen(true);
    // fetchEmployees();

    // deleteEmployee(deleteEmployee);
  };

  const handleCloseAddEmployeeModal = () => {
    // setAddEmployeeModalOpen(false);
    // handleReload();
    setConfirmDialogOpen(false);
  };

  // Call the function with your DTOs
  // deleteEmployee(deletedEmployee, successorEmployee);

  // const handleConfirmDelete = () => {
  //   handleDeleteEmployee(selectedItem.userId);
  //   setConfirmDialogOpen(false);
  //   // handleReload();
  // };

  // const handleCancelDelete = () => {
  //   setConfirmDialogOpen(false);
  //   // handleReload();
  // };

  //
  const renderPermissionsStep = () => (
    <FormControl component="fieldset" sx={{ width: '100%', mt: 2, minWidth: 300 }}>
      <FormLabel component="legend" sx={{ mb: 2, fontSize: "16px", color:"black" }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{fontWeight:"bold", fontSize: "16px", color:"black" }}>
            Set Permissions For:&nbsp;
          </Typography>  
          {newEmployee.firstName.replace(/^\w/, c => c.toUpperCase())} {newEmployee.lastName.replace(/^\w/, c => c.toUpperCase())}
          
        </Box>
      </FormLabel>
      {Object.keys(permissions).map((category) => (
        <Box key={category} sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ fontSize: "16px", color: 'text.primary', fontWeight: 'bold', mb: 1 }}>
            {category.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase())} {/* Converts camelCase to separate upercase words */}
          </FormLabel>
          <RadioGroup
            row
            value={permissions[category]}
            onChange={(e) => handlePermissionChange(category, e.target.value)}
            sx={{ justifyContent: "space-between", display: 'flex', width: '100%' }}
          >
            <FormControlLabel value="no_access" control={<Radio />} label="No Access" sx={{ '& .MuiTypography-root': { fontSize: 16 } }} />
            <FormControlLabel value="read" control={<Radio />} label="Read" sx={{ '& .MuiTypography-root': { fontSize: 16 } }} />
            <FormControlLabel value="write" control={<Radio />} label="Write" sx={{ '& .MuiTypography-root': { fontSize: 16 } }} />
          </RadioGroup>
        </Box>
      ))}
    </FormControl>
  );

  // this method calls the getPermissions API
  // todo: error handling if status is not 200
  const getPermissions = async (employee) => {

    const permissionsResponse = await axios.get(`${API_URL}/Employee/GetEmployeePermissions`, {
          params: { userId: employee.userId },
          withCredentials: true,
          withXSRFToken: true,
        });

        if(permissionsResponse.status == 200 && permissionsResponse.data.permissions){
          // this only returns permissions that the employee has
          // so if they only have 2 permissions then we need to set the rest as no access
          appendAndsetPermissions(permissionsResponse.data.permissions);
        }

  }

  const appendAndsetPermissions = (userPerms) =>{
            // Ensure all default permissions are set in the userPerm
            const allPerms = defaultPermissions;
            Object.keys(allPerms).forEach(key => {
              if(userPerms.hasOwnProperty(key)){
                allPerms[key] = userPerms[key];
              }
            });
          setPermissions(allPerms);
  }

  // this dialog box will display employee info and permissions and allow you to modify permissions
  const displayEmployeeInfo = () => (
    <Dialog open={OpenEmployeeInfo} onClose={handleCloseEmployeeInfo} sx={{my:1}}>

      <DialogTitle variant="h4">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {`${selectedEmployee?.firstName.replace(/^\w/, c => c.toUpperCase())} ${selectedEmployee?.lastName.replace(/^\w/, c => c.toUpperCase())}`}
          <Button onClick={toggleEditMode} color="primary" size="small" sx={{ boxShadow: 1 }}>
            <Typography sx={{m:0.5}}> {editMode ? 'Cancel' : 'Edit Permissions'} </Typography>
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
      <Box
          sx={{
            width: {               
              xs: 300,             
              sm: 400,             
            },
            hight: {              
              xs: 200,            
              sm: 300,           
            },
            margin: 'auto',      
          }}
        >
          <Divider sx={{ my: 2, bgcolor: 'lightgrey' }} /> 
          <Typography variant="body1" sx={{ fontSize: { xs: "16px", sm: "16px" }, fontWeight: "light", }}>{selectedEmployee?.email}</Typography>
          <Typography variant="body1" sx={{ fontSize: { xs: "16px", sm: "16px" }, fontWeight: "light", }}>{selectedEmployee?.phoneNumber}</Typography>
          <Divider sx={{ my: 2, bgcolor: 'lightgrey' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: 'text.primary' }}>USER PERMISSIONS</Typography>
          {Object.keys(permissions).map((category) => (
            <Box key={category}>
              <Typography sx={{ fontSize: "16px", color: 'text.primary', fontWeight: 'bold', mb: 1 }}>
                {category.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase())}
              </Typography>
              {editMode ? (
                <RadioGroup
                  row
                  name={category}
                  value={permissions[category]}
                  onChange={(e) => handlePermissionChange(category, e.target.value)}
                >
                  <FormControlLabel value="no_access" control={<Radio />} label="No Access" />
                  <FormControlLabel value="read" control={<Radio />} label="Read" />
                  <FormControlLabel value="write" control={<Radio />} label="Write" />
                </RadioGroup>
              ) : (
                <Typography sx={{ fontSize: "16px", color: 'text.secondary' }}>
                  {permissions[category] === "no_access" && "No Access"}
                  {permissions[category] === "read" && "Read"}
                  {permissions[category] === "write" && "Write"}
               </Typography>
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>
      {editMode && (
        <DialogActions>
          <Button onClick={() => savePermissions(selectedEmployee)} color="primary" size="small" sx={{ boxShadow: 1 }}>
            <Typography sx={{m:0.5}}>Save Changes </Typography>
          </Button>
        </DialogActions>
      )}
    </Dialog>

  );

  const toggleEditMode = () => {
    if (!editMode) {
      // Backup the current permissions when entering edit mode
      setBackupPermissions({ ...permissions });
      setEditMode(true);
    } else {
      // Restore permissions from backup when cancelling edit mode
      setPermissions(backupPermissions);
      setEditMode(false);
    }
  };

  // this method is used to update an employees permissions
  const savePermissions = async (employee) => {
    try {
      const response = await axios.put(`${API_URL}/Employee/ModifyEmployeePermissions`, {
        userId: employee.userId,
        role: employee.role,
        permissions: permissions,
      }, {
        withCredentials: true,
        withXSRFToken: true,
      });
      if (response.status === 200) {
        setEditMode(false);
        setOpenEmployeeInfo(false);
        fetchEmployees(); // refresh the list if needed
      }
    } catch (error) {
      console.error('Failed to modify permissions:', error);
    }
  };
    

  const handlePermissionChange = (category, value) => {
    setPermissions({ ...permissions, [category]: value });
  };

  const handleNext = () => {
    if (currentStep === 1 && isFormValid()) {
      setCurrentStep(2);
    } else {
      setError("Please fill in all fields");
    }
  };



  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h4" gutterBottom>
        Add Employees
      </Typography>
      <List>
        {employees.map((employee, index) => (
          <ListItem key={index} onClick={()=> handleOpenEmployeeInfo(employee)}>
            <ListItemText
              primary={`${employee.firstName} ${employee.lastName}`}
              secondary={employee.email}
            />
            {employee.role === "employee_admin" ? (
              <IconButton edge="end">
                <AdminPanelSettingsIcon style={{ color: "#b79b06" }} />
              </IconButton>
            ) : (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(event) => handleDeleteClick(employee, event)}
              >
                <DeleteIcon sx={{ color: "#b72222" }} />
              </IconButton>
            )}
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Employee
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle variant="h4">Add a New Employee</DialogTitle>
        <DialogContent>
        <Box
         sx={{
          width: {               
            xs: 300,             
            sm: 400,             
          },
          hight: {              
            xs: 200,            
            sm: 300,           
          },
          margin: 'auto',      
        }}
        >
          {currentStep === 1 ? (
            <div>
              <RenderTextField name="firstName" label="First Name" value={newEmployee.firstName} onChange={handleInputChange} />
              <RenderTextField name="lastName" label="Last Name" value={newEmployee.lastName} onChange={handleInputChange} />
              <RenderTextField name="email" label="Email" value={newEmployee.email} onChange={handleInputChange} />
              <RenderTextField name="phoneNumber" label="Phone Number" value={newEmployee.phoneNumber} onChange={handleInputChange} />
            </div>
            ) : (
              renderPermissionsStep()
            )}
          </Box>
        </DialogContent>
        <DialogActions>
        {currentStep === 1 ? (
          <Button onClick={handleNext} color="primary">Next</Button>
          ) : (
          <>
          <Button onClick={handleBack} color="primary">Back</Button>
          <Button onClick={handleCreate} color="primary">Add</Button>
          </>
          )}
        </DialogActions>
      </Dialog>

      {/*this will display inployee info including current permissions*/}
      {displayEmployeeInfo()}
            
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError("")}
          message={error}
        />
      )}
      <DeleteEmployee
        open={isConfirmDialogOpen}
        onClose={handleCloseAddEmployeeModal}
        employees={employees}
        onReload={fetchEmployees}
        deletedUser={selectedItem}
      />
    </Box>
  );
};

export default AddEmployee;
