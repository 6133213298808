import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const ConfirmRemoveEmployee = ({ open, onClose, onConfirm, employeeName }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle style={{ textAlign: "center", fontSize: "1.2rem" }}>
        Confirm Deletion
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontSize: "1rem", textAlign: "center" }}>
        Are you sure you want to remove <b style={{textTransform: "capitalize"}}>{employeeName?.firstName} {employeeName?.lastName}</b> from this project? They will not receive any project notifications if removed
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", padding: "16px" }}>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onConfirm} ccolor="primary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRemoveEmployee;
