import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MemberDialog = ({
  open,
  onClose,
  members,
  conversationId,
  employees,
  addMemberToConversation,
  removeMemberFromConversation,
  updateConversationMembers,
}) => {
  const [selectedNewMember, setSelectedNewMember] = React.useState("");
  const handleAddMember = async () => {
    if (!selectedNewMember) {
      alert("Please select a member to add.");
      return;
    }
    try {
      await addMemberToConversation([selectedNewMember], conversationId);
      updateConversationMembers(selectedNewMember, "add");
      setSelectedNewMember("");
      onClose();
    } catch (error) {
      console.error("Failed to add member:", error);
      alert("An error occurred while adding the member.");
    }
  };

  const handleRemoveMember = async (memberId) => {
    try {
      await removeMemberFromConversation(memberId, conversationId);
      updateConversationMembers(memberId, "remove");
    } catch (error) {
      console.error("Failed to remove member:", error);
      alert("An error occurred while removing the member.");
    }
  };

  const availableEmployees = employees.filter(
    (emp) => !members.some((member) => member.userId === emp.userId)
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="members-dialog-title"
      aria-describedby="members-dialog-description"
    >
      <DialogTitle id="members-dialog-title" sx={{ fontWeight: "bold" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          Conversation Members
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <List sx={{ pt: 0 }}>
          {members.map((member, index) => (
            <ListItem
              key={index}
              alignItems="flex-start"
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveMember(member.userId)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar
                  alt={`${member?.firstName} ${member?.lastName}`}
                  src="/static/images/avatar/1.jpg"
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${member?.firstName} ${member?.lastName}`}
                secondary={
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {"employee"}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
        {availableEmployees.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="new-member-select-label">
                Add New Member
              </InputLabel>
              <Select
                labelId="new-member-select-label"
                value={selectedNewMember}
                onChange={(e) => setSelectedNewMember(e.target.value)}
                label="Add New Member"
              >
                {availableEmployees.map((employee) => (
                  <MenuItem key={employee.userId} value={employee.userId}>
                    {employee.firstName} {employee.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddMember}
              sx={{ mt: 2, width: "100%" }}
              disabled={!selectedNewMember}
            >
              Add Member
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MemberDialog;
