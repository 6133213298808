import { Box, Typography, Button, Grid, Tab, Tabs } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { finishingsDatabase } from "../../data/mockFinishingsData";
import { tokens } from "../../theme";
import axios from "axios"; // Step 1: Import axios
import ClientSelectionsComponent from "./ClientSelectionComponent";

const API_URL = process.env.REACT_APP_API_URL;

const AwaitingFinishingPage = ({ finishings, onApprove, data, onReload }) => {
  const rejectedItems = data.filter(item => item.clientSelectionResponseDtos.some(selection => selection.selectionStatus === "REJECTED"))
  const handleReload = () => {
    onReload();
  }
  return (
    <Grid container spacing={2}>
      <ClientSelectionsComponent data={data} rejectedItems={rejectedItems} onReload={handleReload} isConfirmed={false}/>
    </Grid>
  );
};

const ConfirmedFinishingPage = ({ data }) => {
  return (
    <Grid container spacing={2}>
      <ClientSelectionsComponent data={data} isConfirmed={true}/>
    </Grid>
  );
};

const FinishingsPage = () => {
  const { projectId } = useParams();

  const [tabValue, setTabValue] = useState(0); // Set the default tab to Awaiting Approval

  const [finishings, setFinishings] = useState([]);
  const [awaitingFinishings, setAwaitingFinishings] = useState([]);
  const [confirmedFinishings, setConfirmedFinishings] = useState([]);
  const [selectionData, setSelectionData] = useState([]);

  const fetchSelections = async () => {
    try {
      const response = await fetch(
        `${API_URL}/client-selections/get-all?projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          withCredentials: true,
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const selectionsData = await response.json();
      setSelectionData(selectionsData);
    } catch (error) {
      console.error("Error fetching finishings data:", error);
    }
  };

  useEffect(() => {
    fetchSelections();
  }, [projectId]);

  const handleReload = () => {
    fetchSelections();
  }

  const handleApprove = async (finishingId) => {
    try {
      const response = await axios.put(
        `${API_URL}/client-selections/confirm-selection?selectionId=${finishingId}`,
        {}, // If you're not sending any body data, an empty object should be passed
        {
          withCredentials: true,
          // Remove the Content-Type header if you're not sending form data
        }
      );

      const approved = awaitingFinishings.find(
        (finishing) => finishing.selectionId === finishingId
      );
      setConfirmedFinishings((prev) => [...prev, approved]);
      setAwaitingFinishings((prev) =>
        prev.filter((finishing) => finishing.selectionId !== finishingId)
      );
      // fetchFinishings();
    } catch (err) {
      console.error(
        `Error modifying selection for selection ${finishingId}:`,
        err
      );
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box m={4}>
      <Typography variant="h4" mb={3}>
        Selections
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label={`Pending (${selectionData.filter((item) =>
          item.status !== "APPROVED").length})`} />
        <Tab label={`Confirmed (${selectionData.filter((item) =>
          item.status === "APPROVED").length})`} />
      </Tabs>
      <Box m={4} paddingTop={"10px"}>
        {tabValue === 0 && (
          <AwaitingFinishingPage
            finishings={awaitingFinishings}
            onApprove={handleApprove}
            onReload={handleReload}
            data={selectionData.filter((item) =>
              item.status !== "APPROVED")}
          />
        )}
        {tabValue === 1 && (
          <ConfirmedFinishingPage
            data={selectionData.map(item => ({
              ...item,
              clientSelectionResponseDtos: item.clientSelectionResponseDtos.filter(selection => selection.selectionStatus === "CONFIRMED")
          })).filter(item => item.status === "APPROVED" && item.clientSelectionResponseDtos.length > 0)}
            finishings={confirmedFinishings} />
        )}
      </Box>
    </Box>
  );
};

export default FinishingsPage;
