import React, {
  useState,
  useEffect,
  useInsertionEffect,
  useRef,
  useLayoutEffect,
} from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  useTheme,
  Grid,
  useMediaQuery,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { tokens } from "../../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

const MessagingFeature = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { clientUserId } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;
  // using context
  const [project, setProject] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [bottom, setBottom] = useState(true);
  const [page, setPage] = useState(0);
  const employee = Cookies.get("userId");
  const messagesEndRef = useRef(null);
  const MessagesRef = useRef(messages);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [notification, setNotification] = useState(null);
  const [eventTypeCounts, setEventTypeCounts] = useState({});
  const [notificationCounts, setNotificationCounts] = useState({});

  // defining popup
  function NewConversationDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = async () => {
      if(! await checkWriteAccess(ACCESS_CATEGORIES.CLIENT_MESSAGES)){
        alert("You are not authorized to create a new chat. Please contact Admin for help.");
        return;
      }

      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleConfirm = async () => {
      setOpen(false);
      const newChatResponse = await fetch(
        `${API_URL}/messages/chat?clientId=${project.clientId}&employeeId=${employee}&projectId=${project.projectId}&businessId=${project.businessId}`,
        {
          method: "Post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );

      if (newChatResponse.ok && newChatResponse.status === 200) {
        const chatData = await newChatResponse.json();
        setConversations([...conversations, chatData]);
        // Process chatData here
        console.log("Success:", chatData);
      } else {
        if (newChatResponse.status === 403) {
          alert("You are not authorized to create a new chat. Please contact Admin for help.");
        } else {
          alert("This chat already exists");
        }
        console.error("HTTP Error:", newChatResponse.status);
      }
    };

    return (
      <React.Fragment>
        <Button variant="outlined" onClick={handleClickOpen} color="primary">
          <Box display="flex" alignItems="center">
            <AddIcon sx={{ mr: 1 }} />
            <Typography variant="h6">New Conversation</Typography>
          </Box>
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="New conversation">
            {"Add new conversation?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Confirm you want to create a new conversation
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancle</Button>
            <Button onClick={handleConfirm} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handlePagination = async () => {
    setBottom(false);
    setPage(page + 1);
    await GetMessages(conversationId, page + 1);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const HandleSwitchConversation = (convId) => {
    setMessages([]);
    setPage(0);
    setConversationId(convId);

    // Check if there are notifications to clear for this conversation
    if (notificationCounts[convId] && notificationCounts[convId] > 0) {
      const newCounts = { ...notificationCounts, [convId]: 0 }; // Reset count for this conversation
      setNotificationCounts(newCounts); // Update the state to reflect this
    }

    GetMessages(convId, 0);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "" || selectedImage) {
      const messageDTO = {
        messageId: "",
        conversationId: conversationId,
        senderId: employee,
        messageText: newMessage,
        sentAt: new Date().toISOString(),
        isRead: false,
      };
  
      //setSelectedImage(null);
  
      // check to see if employee is sending msg on the correct chat
      const specificConversation = conversations.find(
        (conversation) => conversation.conversationId === conversationId
      );

      if (!specificConversation) {
        return;
      }

      const userIds = specificConversation.participantDTOs.map(
        (participant) => participant.userDTO.userId
      );
  
      if (userIds.some((userId) => userId === employee)) {
        try {
          const sendResponse = await fetch(`${API_URL}/messages/send`, {
            method: "POST",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },
            body: JSON.stringify(messageDTO),
          });
  
          if (sendResponse.ok && sendResponse.status === 200) {
            const sentMsgData = await sendResponse.json();
            setMessages([...messages, sentMsgData]);
            setNewMessage("");
          } else {
            if (sendResponse.status === 403) {
              alert("You are not authorized to send messages to client. Please contact Admin for help.");
            } else {
              alert("Failed to send the message.");
            }
            console.error("HTTP Error:", sendResponse.status);
          }
        } catch (error) {
          console.error("Fetch error:", error);
        }
      } else {
        alert("You are not a member of this chat");
      }
    }
    scrollToBottom();
  };
  

  const navigateBack = () => {
    navigate(`/users/${clientUserId}/${projectId}`);
  };

  const navigateTo = (path) => () => {
    console.log("Navigating to:", path); // Debugging log
    navigate(path);
  };

  function msgUnion(newMsgList) {
    const combined = [...MessagesRef.current, ...newMsgList];
    const uniqueMsgMap = new Map();

    combined.forEach((msg) => uniqueMsgMap.set(msg.messageId, msg));

    return Array.from(uniqueMsgMap.values());
  }

  //gets all the messages for a given chat
  async function GetMessages(convId, page) {
    const msgResponse = await fetch(
      `${API_URL}/messages/getMessages?conversationId=${convId}&userId=${employee}&page=${page}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      }
    );

    // Check if the response status is 200-OK
    if (msgResponse.ok && msgResponse.status === 200) {
      const msgData = await msgResponse.json();

      const msgList = msgUnion(msgData.content);

      const sortedMessages = msgList.sort((a, b) => {
        return new Date(a.sentAt) - new Date(b.sentAt);
      });

      setMessages(sortedMessages);
    } else {
      // Handle non-200 responses
      if (msgResponse.status === 403) {
        alert("You are not authorized to read client messages. Please contact Admin for help.");
      }
      console.error("HTTP Error:", msgResponse.status);
    }
  }

  useEffect(() => {
    async function fetchNotificationData() {
      const response = await fetch(
        `${API_URL}/notification/get-notifications-for-project/${projectId}/${employee}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }

      const data = await response.json();
      const newCounts = {};

      data.forEach((notification) => {
        if (notification.eventType === "EVENT_TYPE_7" && !notification.viewed) {
          const { eventId } = notification; // eventId is the conversationId for these notifications
          newCounts[eventId] = (newCounts[eventId] || 0) + 1;
        }
      });

      setNotificationCounts(newCounts);
    }

    fetchNotificationData();
  }, [projectId, employee, API_URL]);

  // getting conversations, messages, and project
  useEffect(() => {
    //todo: add api call to get projectOwner name

    async function fetchData() {
      // getting list of all conversations
      const allChatsResponse = await fetch(
        `${API_URL}/messages/AllChatsWithClient?clientId=${clientUserId}&projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (allChatsResponse.ok && allChatsResponse.status === 200) {
        const chatsData = await allChatsResponse.json();
        if (chatsData.length > 0) {
          setConversations(chatsData);
          setConversationId(chatsData[0].conversationId);

          // getting messages from a conversation
          const msgResponse = await fetch(
            `${API_URL}/messages/getMessages?conversationId=${chatsData[0].conversationId}&userId=${employee}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
              },
            }
          );

          if(msgResponse.ok && msgResponse.status === 200){
            const msgData = await msgResponse.json();

            const sortedMessages = msgData.content.sort((a, b) => {
              return new Date(a.sentAt) - new Date(b.sentAt);
            });
            setMessages(sortedMessages);
          } else {
            if (msgResponse.status === 403) {
              alert("You are not authorized read client messages. Please contact Admin for help.");
            }
          }
          
        }
      } else {
        if (allChatsResponse.status === 403) {
          alert("You are not authorized read client messages. Please contact Admin for help.");
        }
      }

      // geting project info
      //todo: eventually use shared context or something so that we dont make this call for evey feature page
      const projectResponse = await fetch(
        `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );

      const projectData = await projectResponse.json();
      setProject(projectData);
    }

    fetchData();
  }, []);

  useEffect(() => {
    MessagesRef.current = messages;
  }, [messages]);

  useInsertionEffect(() => {
    // Set an interval to fetch messages every 30 seconds
    const intervalId = setInterval(() => {
      GetMessages(conversationId, 0);
    }, 30000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [conversationId]);

  const scrollToBottom = () => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  console.log(isMobile);
  useEffect(() => {
    if (bottom) {
      scrollToBottom();
    } else {
      setBottom(true);
    }
  }, [messages]);

  // Handle case where project data is not yet loaded
  if (conversations === null) {
    return <div>Loading...</div>;
  }

  if (!project) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
          {/* Clickable navigation links */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}`)}
          >
            Projects
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}/${projectId}`)}
          >
            {project.address}
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          <Typography variant="h6" component="span" fontWeight="bold">
            Messages {/* Using project address as project name */}
          </Typography>
        </Box>
      </Box>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginRight="25px"
        >
          {!isMobile && (
            <Typography variant="h4" fontWeight="600" mb="20px">
              Messages from{" "}
              {conversations.length > 0 &&
              conversations[0].participantDTOs.length > 1
                ? conversations[0].participantDTOs[0].userDTO.role === "client"
                  ? conversations[0].participantDTOs[0].userDTO.firstName
                  : conversations[0].participantDTOs[1].userDTO.firstName
                : " "}
            </Typography>
          )}

          <NewConversationDialog />
        </Box>

        <Grid container spacing={3}>
          {/* left box displays list of conversations */}
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                p: "5px",
                borderRadius: "8px",
                backgroundColor: colors.primary[400],
                display: "flex",
                flexDirection: "column",
                columnGap: "5px",
                rowGap: "5px",
                //maxHeight: "300px",
                height: isMobile ? "300px" : "68vh",
                minHeight: "300px",
                overflowY: "auto",
              }}
            >
              <List>
                {conversations.length > 0 ? (
                  conversations.map((conversation) => (
                    <ListItem
                      key={conversation.conversationId}
                      button
                      selected={conversation.conversationId === conversationId}
                      onClick={() =>
                        HandleSwitchConversation(conversation.conversationId)
                      }
                      sx={{
                        "&:hover": {
                          backgroundColor: colors.hoverColor, // Your hover color
                        },
                        backgroundColor:
                          conversation.conversationId === conversationId
                            ? colors.selectedColor // Your selected color
                            : "inherit",
                      }}
                    >
                      <ListItemText
                        primary={
                          conversation.participantDTOs[0].userDTO.role ===
                            "employee" ||
                          conversation.participantDTOs[0].userDTO.role ===
                            "employee_admin" ||
                          !conversation.participantDTOs[0].userDTO.role
                            ? !conversation.participantDTOs[0].userDTO.active
                              ? "Deleted User"
                              : conversation.participantDTOs[0].userDTO
                                  .firstName
                            : !conversation.participantDTOs[1].userDTO.active
                            ? "Deleted User"
                            : conversation.participantDTOs[1].userDTO.firstName
                        }
                        secondary={
                          conversation.participantDTOs[0].userDTO.role ===
                            "employee" ||
                          conversation.participantDTOs[0].userDTO.role ===
                            "employee_admin" ||
                          !conversation.participantDTOs[0].userDTO.role
                            ? conversation.participantDTOs[1].userDTO.firstName
                            : conversation.participantDTOs[0].userDTO.firstName
                        }
                      />
                      {notificationCounts[conversation.conversationId] > 0 && (
                        <Box
                          component="span"
                          sx={{
                            display: "inline-block",
                            ml: 1,
                            width: 10,
                            height: 10,
                            bgcolor: "red",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body1">No chats available.</Typography>
                )}
              </List>
            </Box>
          </Grid>

          {isMobile && (
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="600" mb="0px">
                Messages from{" "}
                {conversations.length > 0 &&
                conversations[0].participantDTOs.length > 1
                  ? conversations[0].participantDTOs[0].userDTO.role ===
                    "client"
                    ? conversations[0].participantDTOs[0].userDTO.firstName
                    : conversations[0].participantDTOs[1].userDTO.firstName
                  : " "}
              </Typography>
            </Grid>
          )}

          {/* Right box displays all message of given conversation */}
          <Grid item xs={12} md={9}>
            <Box
              sx={{
                p: "20px",
                borderRadius: "8px",
                backgroundColor: colors.primary[400],
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                //maxHeight: "300px",
                height: isMobile ? "300px" : "68vh",
                minHeight: "300px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                color="#3366CC"
                onClick={handlePagination}
              >
                click to load older messages.
              </Typography>
              {messages.length > 0 ? (
                messages.map((message) => (
                  <Box
                    key={message.messageId}
                    backgroundColor={
                      message.senderId === clientUserId
                        ? colors.blueAccent[300]
                        : colors.primary[300]
                    }
                    borderRadius="8px"
                    padding="10px"
                    maxWidth="80%"
                    alignSelf={
                      message.senderId === clientUserId
                        ? "flex-start"
                        : "flex-end"
                    } // Updated positioning
                  >
                    <Typography
                      variant="body1"
                      color="white"
                      sx={{ wordWrap: "break-word" }}
                    >
                      {message.messageText}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "gray", fontSize: "0.75rem", mt: 1 }}
                    >
                      {new Date(message.sentAt).toLocaleString()}{" "}
                      {/* Formatted timestamp */}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body1">No messages available.</Typography>
              )}
              <Box ref={messagesEndRef} />
            </Box>
          </Grid>
          {/* </Box> */}
        </Grid>

        <Box display="flex" gap="10px" mt="20px" marginRight="25px">
          <TextField
            value={newMessage}
            onChange={handleNewMessageChange}
            label="New Message"
            variant="outlined"
            focused
            fullWidth
            multiline
            maxRows={4}
            inputProps={{
              style: {
                overflow: "auto",
                fontSize: "16px",
              },
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
                e.preventDefault();
              }
            }}
          />
          {/*
            <label htmlFor="image-upload">
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <IconButton
                color="primary"
                component="span"
                style={{ color: colors.blueAccent[500] }}
              >
                <PhotoCameraIcon />
              </IconButton>
            </label>
            */}
          <IconButton
            color="primary"
            onClick={handleSendMessage}
            style={{ color: colors.blueAccent[500] }}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default MessagingFeature;
