import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tokens } from "../../theme";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import InvoiceForm from "./components/invoiceForm";
import InvoiceTable from "./components/invoiceTable";
import ViewContractorInvoiceForm from "./components/viewInvoiceForm";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

const ContractorInvoices = () => {
  const [project, setProject] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState("draft");
  const [isShowInvoiceForm, setIsShowInvoiceForm] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const { clientUserId } = useParams();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigateTo = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
            },
          }
        );
        const projectData = await response.data;
        setProject(projectData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProjectData();
  }, [projectId]);

  const handleCreateInvoice = async (event) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INVOICES)){
      alert("You are not authorized to create a new invoice. Please contact Admin for help.");
      return;
    }

    setIsShowInvoiceForm(event);
  };

  const handleCloseInvoice = () => {
    setIsShowInvoiceForm(false);
    setInvoiceData(null);
    setIsEdit(false);
  };

  const handleSingleInvoice = (res) => {
    console.log(res);
    setSelectedInvoiceId(res.invoiceId);
    if (res.status === "SENT" || res.status === "PAID") {
      setIsViewOnly(true);
    } else if (res.status === "DRAFT") {
      setIsEdit(true);
    }
  };

  const handleCloseView = (res) => {
    console.log(res);
    setIsViewOnly(false);
  };

  const handleGetSingleInvoiceData = async (invoiceId) => {
    try {
      const response = await axios.get(
        `${API_URL}/project-invoice/get?projectId=${projectId}&invoiceId=${invoiceId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      setInvoiceData(data[0]);
      console.log(data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          alert("You are not authorized to view invoices. Please contact Admin for help.");
        } else {
          console.error("Error from server:", error.response.data);
        }
      } else {
        console.error("Error sending request:", error.message);
      }
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}`)}
          >
            Projects
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}/${projectId}`)}
          >
            {project?.address}
          </Typography>
          {isShowInvoiceForm ? (
            <Box>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              <Typography
                variant="h6"
                component="span"
                sx={{ cursor: "pointer" }}
                onClick={navigateTo(
                  `/users/${clientUserId}/${projectId}/invoices`
                )}
              >
                Invoices
              </Typography>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              <Typography variant="h6" component="span" fontWeight="bold">
                Create Invoice
              </Typography>
            </Box>
          ) : isViewOnly ? (
            <Box>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              <Typography
                variant="h6"
                component="span"
                sx={{ cursor: "pointer" }}
                onClick={navigateTo(
                  `/users/${clientUserId}/${projectId}/invoices`
                )}
              >
                Invoices
              </Typography>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              <Typography variant="h6" component="span" fontWeight="bold">
                View Invoice
              </Typography>
            </Box>
          ) : isEdit ? (
            <Box>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              <Typography
                variant="h6"
                component="span"
                sx={{ cursor: "pointer" }}
                onClick={navigateTo(
                  `/users/${clientUserId}/${projectId}/invoices`
                )}
              >
                Invoices
              </Typography>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              <Typography variant="h6" component="span" fontWeight="bold">
                Edit Invoice
              </Typography>
            </Box>
          ) : (
            <Box>
              <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
              <Typography variant="h6" component="span" fontWeight="bold">
                Invoices
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Box margin={"0 20px"}>
          {isShowInvoiceForm ? (
            <Box>
              <InvoiceForm
                onClose={handleCloseInvoice}
                project={project}
                singleInv={invoiceData}
              ></InvoiceForm>
            </Box>
          ) : isViewOnly ? (
            <Box>
              <ViewContractorInvoiceForm
                onClose={handleCloseView}
                selectedInvId={selectedInvoiceId}
              />
            </Box>
          ) : isEdit ? (
            <Box>
              <InvoiceForm
                onClose={handleCloseInvoice}
                project={project}
                isEditOnly={isEdit}
                invoiceId={selectedInvoiceId}
              ></InvoiceForm>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    boxShadow:
                      "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
                  }}
                  onClick={handleCreateInvoice}
                >
                  Create Invoice
                </Button>
              </Box>
              <InvoiceTable
                projectId={projectId}
                onClick={handleSingleInvoice}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContractorInvoices;
