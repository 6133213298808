import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";





function Settings() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const navigateToReset = () => {
        navigate("/ClientSettings/reset");
    }

    return(
        <Box  m="20px">

            <Typography variant="h4" fontWeight="600" mb="20px">
                Settings
            </Typography>

            <Box sx={{backgroundColor:colors.primary[400]}}>
                <List>
                    <ListItem>
                        <Button 
                            variant="contained" 
                            onClick={navigateToReset}
                            sx={{backgroundColor:colors.MoonBlue[100], minWidth:"250px", fontSize:'0.8rem'}}
                            >
                            Reset Password
                            </Button>
                    </ListItem>
                </List>
            </Box>
        </Box>
    );

}

export default Settings;