import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TableSortLabel,
  styled,
  tableCellClasses,
} from "@mui/material";
import styles from "./invoice.module.css";
import axios from "axios";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const headCells = [
  { id: "name", label: "Invoice Name" },
  { id: "status", label: "Status" },
  { id: "dateIssued", label: "Date Issued" },
  { id: "dueDate", label: "Due Date" },
  { id: "invoiceNumber", label: "Invoice Number" },
  { id: "totalAmount", label: "Total Amount" },
];

const InvoiceTable = ({ projectId, onClick }) => {
  // const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceId, setInvoiceId] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  const createData = (
    name,
    status,
    dateIssued,
    dueDate,
    invoiceNumber,
    totalAmount,
    invoiceId
  ) => ({
    name,
    status,
    dateIssued,
    dueDate,
    invoiceNumber,
    totalAmount,
    invoiceId,
  });

  const formatDate = (date) => {
    const inputDate = new Date(date);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = inputDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: userTimezone,
    });
    return formattedDate;
  };

  const rows = invoiceData.map((invoice) =>
    createData(
      invoice.invoiceName,
      invoice.status,
      formatDate(invoice.dateOfIssue),
      formatDate(invoice.dueDate),
      invoice.invoiceNo,
      invoice.totalAmount,
      invoice.invoiceId
    )
  );

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortedColumn(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    if (orderBy === "dateIssued" || orderBy === "dueDate") {
      return order === "desc"
        ? (a, b) => compareDates(b[orderBy], a[orderBy])
        : (a, b) => compareDates(a[orderBy], b[orderBy]);
    } else {
      return order === "desc"
        ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
        : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
    }
  };

  const compareDates = (date1, date2) => {
    const timestamp1 = new Date(date1).getTime();
    const timestamp2 = new Date(date2).getTime();
    return timestamp1 - timestamp2;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (invoice) => {
    onClick(invoice);
  };

  const getInvoiceTableData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/project-invoice/get-all?projectId=${projectId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      setInvoiceData(data);
      console.log(data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          alert("You are not authorized to view invoices. Please contact Admin for help.");
        } else {
          console.error("Error from server:", error.response.data);
        }
      } else {
        console.error("Error sending request:", error.message);
      }
    }
  };

  useEffect(() => {
    getInvoiceTableData();
  }, [projectId]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  className={
                    orderBy === headCell.id
                      ? styles.active_header_cell
                      : styles.active_header_cell
                  }
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    backgroundColor: "#0B1537",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    className={
                      orderBy === headCell.id ? styles.active_header_cell : ""
                    }
                    direction={orderBy === headCell.id ? order : "asc"}
                    sx={{
                      // color: "#eee",
                      "&:hover": {
                        color: "white",
                      },
                      "&.MuiTableSortLabel-active": {
                        color: "white",
                      },
                      ".MuiTableSortLabel-icon": {
                        display: "none",
                      },
                    }}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      order === "asc" ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <ArrowDropUpIcon
                          style={{ fontSize: 18, margin: "0" }}
                        />{" "}
                        <ArrowDropDownIcon
                          style={{ fontSize: 18, margin: "-10px 0 0 0" }}
                        />
                      </div>
                    )}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.invoiceNumber}
                  hover
                  style={{ textDecoration: "none" }}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.dateIssued}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.dueDate}
                  </TableCell>
                  <TableCell>{row.invoiceNumber}</TableCell>
                  <TableCell>{row.totalAmount}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default InvoiceTable;
