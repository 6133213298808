import {
  Box,
  Button,
  TextField,
  Typography,
  CardMedia,
  Paper,
  Grid,
  Divider,
  List,
  ListItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Input,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Cookies from "js-cookie";
import styles from "./invoice.module.css";
import ACCESS_CATEGORIES from "../../../constants/AccessCatagories"
import checkWriteAccess from "../../../util/CheckWriteAccess";

const InvoiceForm = ({ project, onClose, isEditOnly, invoiceId }) => {
  console.log("selected", invoiceId);
  const [singleInvoiceData, setSingleInvoiceData] = useState(null);
  const [invoiceName, setInvoiceName] = useState("New Invoice");
  const [issuedDate, setIssuedDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [amountDue, setAmountDue] = useState("");
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const { clientUserId } = useParams();
  const { projectId } = useParams();
  const [items, setItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountOpen, setDiscountOpen] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [tax, setTax] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [taxOpen, setTaxOpen] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAfterTax, setTotalAfterTax] = useState(0);
  const [isSaveDisabled, setSaveDisabled] = useState(true);
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [businessUrl, setBusinessUrl] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [isInvoiceDelete, setIsInvoiceDelete] = useState(false);
  const [isInvoiceItemDelete, setIsInvoiceItemDelete] = useState(false);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState(null);
  const [selectedLineIndex, setSelectedLineIndex] = useState(0);
  const [isDisableSave, setIsDesableSave] = useState(true);
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [lineItemDiscountAmount, setLineItemDiscountAmount] = useState(0);

  const API_URL = process.env.REACT_APP_API_URL;

  const handleGetSingleInvoiceData = async (invoiceId) => {
    try {
      const response = await axios.get(
        `${API_URL}/project-invoice/get?projectId=${projectId}&invoiceId=${invoiceId}`,
        {
          withCredentials: true,
        }
      );
      const data = response.data[0];
      setSingleInvoiceData(data);
      handleSetAllValues(data);
      setIsLoading(false);
      if(data.amountPaid > data.totalAmount) {
        setAmountPaid(0)
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isEditOnly) {
      handleGetSingleInvoiceData(invoiceId);
    } else {
      setClientAddress(project?.address);
      setSingleInvoiceData(null);
    }
  }, [projectId]);

  const invoiceFormDetails = ["description", "rate", "quantity"];
  let [errors, setErrors] = useState({});

  const validateForm = (datakeys, index) => {
    const newErrors = {};
    datakeys.forEach((key) => {
      if (!items[index][key]) {
        newErrors[key] = `${key} is required`;
      } else {
        delete newErrors[key];
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const invoiceDateDetails = ["issuedDate", "dueDate"];
  const [dateErrors, setDateErrors] = useState({});

  const validateDates = (data) => {
    const newErrors = {};
    data.forEach((date) => {
      if (date === "issuedDate" && !issuedDate) {
        newErrors[date] = `Date of issue is required`;
      } else if (date === "dueDate" && !dueDate) {
        newErrors[date] = `Due date is required`;
      // } else if (issuedDate && dueDate && dueDate > issuedDate) {
      //   newErrors["dueDate"] = "Due date should be greater than issued date"
      } else {
        delete newErrors[date];
      }
    });
    setDateErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const invoiceAmountPaidDetails = ["amountPaid"];
  const [amountErrors, setAmountErrors] = useState({});

  const validateDueAmount = (data) => {
    const newError = {};
    data.forEach((amount) => {
      if (amount === "amountPaid") {
        newError[amount] = "Amount is greater than total amount";
      } else {
        delete newError[amount];
      }
    });
    setAmountErrors(newError);
    return Object.keys(newError).length === 0;
  };

  const handleSetAllValues = (invoice) => {
    setInvoiceName(invoice.invoiceName);
    setIssuedDate(invoice.dateOfIssue);
    setDueDate(invoice.dueDate);
    setInvoiceNumber(invoice.invoiceNo);
    setBusinessName(invoice.businessName);
    setBusinessPhone(invoice.businessPhone);
    setBusinessAddress(invoice.businessAddress);
    setBusinessUrl(invoice.businessLogoUrl);
    setClientName(invoice.clientName);
    setClientPhone(invoice.clientPhone);
    setClientAddress(invoice.clientAddress);
    setAmountDue(invoice.amountDue);
    setTax(invoice.taxPercent);
    setTaxValue(invoice.taxPercent);
    setDiscount(invoice.discountPercent);
    setDiscountValue(invoice.discountPercent);
    setTaxAmount(invoice.taxAmount);
    setDiscountedAmount(invoice.discountAmount);
    setAmountPaid(invoice.amountPaid);
    setSubTotal(invoice.subTotal);
    setTotalAmount(invoice.totalAmount);
    if (invoice.invoiceLineItemResponseDto) {
      setItems(
        invoice.invoiceLineItemResponseDto.map((item) => ({
          lineId: item.lineId,
          description: item.description,
          rate: item.rate,
          quantity: item.quantity,
          discountPercent: item.discountPercent,
        }))
      );
    } else {
      setItems([]);
    }
  };
  let [focusedIndex, setFocusedIndex] = useState(0);
  const handleAddItem = async () => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INVOICES)){
      alert("You are not authorized to modify invoices. Please contact Admin for help.");
      return;
    }

    // if (!isEditOnly) {
    if (items.length === 0) {
      setItems((prevItems) => [
        ...prevItems,
        { description: "", rate: null, quantity: null, discountPercent: null },
      ]);
      setFocusedIndex(0);
    } else {
      setFocusedIndex(items.length - 1);
      if (validateForm(invoiceFormDetails, items.length - 1)) {
        setItems((prevItems) => [
          ...prevItems,
          {
            description: "",
            rate: null,
            quantity: null,
            discountPercent: null,
          },
        ]);
      }
    }

    // }
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    calculateTotal();
  };

  const calculateTotal = () => {
    const totalAmount = items.reduce((acc, item) => {
      const itemTotal =
        item.rate * item.quantity * (1 - item.discountPercent / 100);
      return acc + itemTotal;
    }, 0);
    const totalDiscountAmount = items.reduce((acc, item) => {
      const itemTotal = item.rate * item.quantity;
      const discountedTotal =
        itemTotal - itemTotal * (1 - item.discountPercent / 100);
      return acc + discountedTotal;
    }, 0);
    // console.log("totalDiscountAmount", totalDiscountAmount)
    setSubTotal(totalAmount);
    setLineItemDiscountAmount(totalDiscountAmount);
  };

  const handleDiscountOpen = () => {
    setDiscountOpen(true);
  };

  const handleDiscountClose = () => {
    setDiscountOpen(false);
  };

  const handleDiscountApply = () => {
    setDiscount(discountValue);
    const discAmount = subTotal - (subTotal * discount) / 100;
    setDiscountedAmount(subTotal - discAmount + lineItemDiscountAmount);
    handleDiscountClose();
    calculateTotal();
  };

  const calculateDiscountedTotal = () => {
    return subTotal - (subTotal * discount) / 100;
  };

  const calculateAmountDue = () => {
    const discountedTotal = calculateDiscountedTotal();
    const calculatedTaxAmount = (discountedTotal * tax) / 100;
    setTotalAmount(discountedTotal + calculatedTaxAmount);
    if((discountedTotal + calculatedTaxAmount) < amountPaid) {
      setAmountPaid(0)
    }
    return discountedTotal + calculatedTaxAmount - amountPaid;
  };

  const handleInvoiceNameChange = (event) => {
    setInvoiceName(event.target.value);
  };

  const handleTaxOpen = () => {
    setTaxOpen(true);
  };

  const handleTaxClose = () => {
    setTaxOpen(false);
  };

  const handleInvoiceDeleteOpen = async() => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INVOICES)){
      alert("You are not authorized to delete invoice. Please contact Admin for help.");
      return;
    }
    setIsInvoiceDelete(true);
  };

  const handleInvoiceDeleteClose = () => {
    setIsInvoiceDelete(false);
  };

  const handleConfirmInvoiceDelete = () => {
    handleDeleteInvoice();
    setIsInvoiceDelete(false);
  };

  const handleInvoiceLineDeleteOpen = async () => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INVOICES)){
      alert("You are not authorized to modify invoices. Please contact Admin for help.");
      return;
    }

    setIsInvoiceItemDelete(true);
  };

  const handleInvoiceLineDeleteClose = () => {
    setIsInvoiceItemDelete(false);
    setSelectedInvoiceItem(null);
  };

  const handleInvoiceLineDeleteConfirm = (lineId) => {
    handleDeleteInvoiceItem(lineId);
    handleInvoiceLineDeleteClose();
    handleDeleteItem(selectedLineIndex);
  };

  const handleTaxApply = () => {
    setTax(taxValue);
    const discountedTotal = calculateDiscountedTotal();
    const calculatedTaxAmount = (discountedTotal * tax) / 100;
    setTaxAmount(calculatedTaxAmount);
    setTotalAfterTax(discountedTotal + calculatedTaxAmount);
    setTaxOpen(false);
    setAmountDue(calculateAmountDue().toFixed(2));
  };

  const createInvoiceForm = async (status) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INVOICES)){
      alert("You are not authorized to send invoices. Please contact Admin for help.");
      return;
    }

    if (amountDue < 0) {
      setIsDesableSave(true);
      setSaveDisabled(true);
      validateDueAmount(invoiceAmountPaidDetails);
      return;
    }
    if (status === "SENT") {
      validateDates(invoiceDateDetails);
    }
    if(dueDate !== "" && issuedDate !== "") {
      
    }
    validateDueAmount(invoiceAmountPaidDetails);
    const filteredItems = items.map((item) => {
      if (item.discountPercent === null) {
        const { discountPercent, ...rest } = item;
        return rest;
      }
      return item;
    });
    const invoiceData = {
      invoiceName: invoiceName ? invoiceName : "New Invoice",
      businessName: businessName,
      dateOfIssue: issuedDate,
      dueDate: dueDate,
      discountPercent: discount,
      taxPercent: tax,
      amountPaid: amountPaid,
      businessPhone: businessPhone,
      businessAddress: businessAddress,
      clientName: clientName,
      clientPhone: clientPhone,
      clientAddress: clientAddress,
      status: status,
      invoiceLineItemRequestDto: filteredItems,
    };
    console.log(project);
    console.log(invoiceData);
    if (isEditOnly) {
      handleUpdateInvoice(invoiceData);
    } else {
      handleCreateInvoice(invoiceData);
    }
  };

  const handleCreateInvoice = async (requestBody) => {
    try {
      const response = await axios.post(
        `${API_URL}/project-invoice/create?projectId=${projectId}`,
        requestBody,
        {
          withCredentials: true,
          headers: {
            // "Content-Type": "multipart/form-data",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      console.log(await response.data);
      onClose();
      setSingleInvoiceData(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateInvoice = async (requestBody) => {
    try {
      const invoiceId = singleInvoiceData.invoiceId;
      const response = await axios.put(
        `${API_URL}/project-invoice/update?projectId=${projectId}&invoiceId=${invoiceId}`,
        requestBody,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      const result = await response.data;
      console.log(result);
      onClose();
      setSingleInvoiceData(null);
    } catch (error) {
      console.log(error);
    }
  };

  const getRecieverDetails = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/client/get-single-user?userId=${clientUserId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      setClientName(`${data.firstName} ${data.lastName}`);
      setClientPhone(data.phoneNumber);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getBusinessDetails = async () => {
    try {
      const businessId = Cookies.get("businessId");
      const response = await axios.get(
        `${API_URL}/business/getBusiness?businessId=${businessId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      setBusinessName(data.businessName);
      setBusinessPhone(data.phoneNumber);
      setBusinessAddress(data.address);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetBusinessLogo = async () => {
    try {
      const businessId = Cookies.get("businessId");
      const response = await axios.get(
        `${API_URL}/logo/get-logos?businessId=${businessId}`,
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      if (data) {
        data.forEach((item) => {
          if (item.logoType === "mainLogo") {
            setBusinessUrl(item.fileInfoDTO.fileUrl);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteInvoice = async () => {
    try {
      const invoiceId = singleInvoiceData.invoiceId;
      const response = await axios.delete(
        `${API_URL}/project-invoice/delete?invoiceId=${invoiceId}&projectId=${projectId}`,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      const result = await response.data;
      console.log(result);
      onClose();
      setSingleInvoiceData(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteInvoiceItem = async (lineId) => {
    try {
      const invoiceId = singleInvoiceData.invoiceId;
      const response = await axios.delete(
        `${API_URL}/project-invoice/delete-lineItem?invoiceId=${invoiceId}&projectId=${projectId}&lineId=${lineId}`,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      const result = await response.data;
      console.log(result);
      handleGetSingleInvoiceData(invoiceId);
      if (items.length === 0) {
        setItems(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isEditOnly) {
      calculateTotal();
      handleTaxApply();
      handleDiscountApply();
      const isRequiredFilled = items.every(
        (item) =>
          item.description.trim() !== "" &&
          item.rate !== "" &&
          item.rate > 0 &&
          item.quantity !== "" &&
          item.quantity > 0
      );
      const areDatesValid = issuedDate !== "" && dueDate !== "";

      setSaveDisabled(!isRequiredFilled || !areDatesValid || !items.length);
      setIsDesableSave(!isRequiredFilled || !items.length);
      getRecieverDetails();
      getBusinessDetails();
      handleGetBusinessLogo();
    }
    if (isEditOnly) {
      calculateTotal();
      handleTaxApply();
      handleDiscountApply();
      const isRequiredFilled = items.every(
        (item) =>
          item.description.trim() !== "" &&
          item.rate !== "" &&
          item.rate > 0 &&
          item.quantity !== "" &&
          item.quantity > 0
      );
      const areDatesValid = issuedDate !== "" && dueDate !== "";

      setSaveDisabled(!areDatesValid || !isRequiredFilled || !items.length);
      setIsDesableSave(!isRequiredFilled || !items.length);
    }
    setAmountErrors({});
    
  }, [
    items,
    issuedDate,
    dueDate,
    amountPaid,
    subTotal,
    discount,
    tax,
    projectId,
  ]);

  const handleClose = () => {
    onClose();
    setSingleInvoiceData(null);
  };

  const handleImageUrl = (url) => {
    return `${API_URL}/${url}`;
  };

  return (
    <Box sx={{ margin: "0 20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "28px",
          flexWrap: "wrap",
        }}
      >
        <TextField
          id="invoice-name"
          label=""
          value={invoiceName}
          onChange={handleInvoiceNameChange}
          variant="standard"
          sx={{
            // flexGrow: 1,
            marginRight: "8px",
            "& input": {
              fontSize: "28px",
            },
            "& .MuiInputLabel-outlined": {
              fontSize: "28px",
              display: "none",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "&:focus": { borderColor: "rgba(0, 0, 0, 0.23)" },
            marginBottom: "5px",
          }}
          onFocus={() => setIsTextFieldFocused(true)}
          onBlur={() => setIsTextFieldFocused(false)}
        />
        <Box sx={{ display: "flex", gap: "10px", height: "80%" }}>
          {isEditOnly && (
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                boxShadow:
                  "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
              }}
              onClick={handleInvoiceDeleteOpen}
            >
              Delete
            </Button>
          )}
          <Button
            variant="outlined"
            sx={{
              boxShadow:
                "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
              width: "100%",
            }}
            onClick={() => createInvoiceForm("DRAFT")}
            disabled={isDisableSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              boxShadow:
                "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
            }}
            onClick={handleClose}
            disabled={
              isEditOnly && !singleInvoiceData?.invoiceLineItemResponseDto
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              boxShadow:
                "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
            }}
            onClick={() => createInvoiceForm("SENT")}
            disabled={isSaveDisabled}
          >
            Send
          </Button>
          <Dialog open={isInvoiceDelete} onClose={handleInvoiceDeleteClose}>
            <DialogTitle fontSize={"18px"} fontWeight={"500"}>
              Are you sure you want to delete this invoice?
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={handleInvoiceDeleteClose}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmInvoiceDelete}
                variant="contained"
                color="primary"
              >
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
      <Box sx={{ marginTop: "16px", width: "100%", display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    width: "80%",
                    height: "100%",
                  }}
                  className={styles.grid_invoice_logo}
                >
                  {businessUrl ? (
                    <CardMedia
                      component="img"
                      alt="Business Logo"
                      // height="80"
                      image={handleImageUrl(businessUrl)}
                      maxWidth="200px !important"
                      height="150px"
                      className={styles.invoice_logo_fit}
                    />
                  ) : (
                    <Typography variant="h5" fontSize="20px" fontWeight="600">
                      {businessName}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                sm={0}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
                className={styles.grid_invoice_head}
              >
                <Box>
                  <Typography
                    variant="h6"
                    fontSize="16px"
                    textTransform="capitalize"
                  >
                    {businessName}
                  </Typography>
                  <Typography variant="body2" fontSize="16px">
                    {businessPhone}
                  </Typography>
                  <Typography variant="body2" fontSize="16px">
                    {businessAddress}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid
                item
                sx={{ flex: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className={`${styles.grid_invoice_subhead} ${styles.res_invoice_header_titles}`}
              >
                <Box>
                  <Typography
                    variant="h6"
                    fontSize="16px"
                    textTransform="capitalize"
                  >
                    {businessName}
                  </Typography>
                  <Typography variant="body2" fontSize="16px">
                    {businessPhone}
                  </Typography>
                  <Typography variant="body2" fontSize="16px">
                    {businessAddress}
                  </Typography>
                </Box>
              </Grid>

              <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "18px", color: "#666" }}
                >
                  Billed To
                </Typography>
                <Typography
                  variant="h6"
                  fontSize="16px"
                  textTransform="capitalize"
                  fontWeight="600"
                >
                  {clientName}
                </Typography>
                <Typography variant="body2" fontSize="16px" fontWeight="600">
                  {clientPhone}
                </Typography>
                <Typography variant="body3" fontSize="16px" fontWeight="600">
                  {clientAddress}
                </Typography>
              </Grid>

              <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "18px", color: "#666" }}
                >
                  Date of Issue
                </Typography>
                <TextField
                  type="date"
                  value={issuedDate}
                  onChange={(e) => {
                    setIssuedDate(e.target.value);
                    setDateErrors({});
                  }}
                  inputProps={{
                    max:dueDate
                  }}
                  required
                  error={!!dateErrors["issuedDate"]}
                  helperText={dateErrors["issuedDate"]}
                  className={styles.res_invoice_head_date}
                />
              </Grid>

              <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "18px", color: "#666" }}
                >
                  Due Date
                </Typography>
                <TextField
                  type="date"
                  value={dueDate}
                  onChange={(e) => {
                    setDueDate(e.target.value);
                    setDateErrors({});
                  }}
                  inputProps={{
                    min:issuedDate
                  }}
                  required
                  error={!!dateErrors["dueDate"]}
                  helperText={dateErrors["dueDate"]}
                  className={styles.res_invoice_head_date}
                />
              </Grid>

              <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "18px", color: "#666" }}
                >
                  Invoice Number
                </Typography>
                <TextField
                  type="text"
                  value={invoiceNumber}
                  variant="standard"
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  InputProps={{
                    readOnly: true,
                    style: { border: "none", fontSize: "18px" },
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                  }}
                />
              </Grid>

              <Grid item sx={{ flex: 1 }} className={styles.res_invoice_header_titles}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "18px", color: "#666" }}
                >
                  Amount Due
                </Typography>
                <TextField
                  type="text"
                  value={`$ ${amountDue == 0 ? "0.00" : amountDue}`}
                  variant="standard"
                  onChange={(e) => setAmountDue(e.target.value)}
                  required
                  InputProps={{
                    readOnly: true,
                    style: {
                      border: "none",
                      fontSize: "24px",
                      fontWeight: 600,
                    },
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Divider
              style={{
                margin: "20px 0",
                padding: "1px",
                backgroundColor: "#838383",
              }}
            />
            {/* List of Items */}
            <List className={styles.res_web_create_edit}>
              <ListItem>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      fontSize={"15px"}
                      fontWeight={"600"}
                      color="#666"
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <Typography
                      variant="subtitle2"
                      fontSize={"15px"}
                      fontWeight={"600"}
                      color="#666"
                    >
                      Rate
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={1.5}>
                    <Typography
                      variant="subtitle2"
                      fontSize={"15px"}
                      fontWeight={"600"}
                      color="#666"
                    >
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2} md={1.5}>
                    <Typography
                      variant="subtitle2"
                      fontSize={"15px"}
                      fontWeight={"600"}
                      color="#666"
                    >
                      Discount (%)
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <Typography
                      variant="subtitle2"
                      fontSize={"15px"}
                      fontWeight={"600"}
                      color="#666"
                    >
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={1}>
                    <Typography
                      variant="subtitle2"
                      fontSize={"15px"}
                      fontWeight={"600"}
                      color="#666"
                    >
                      {/* Actions */}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
            <List className={styles.res_web_create_edit}>
              {items &&
                items.map((item, index) => (
                  <ListItem key={index}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          type="text"
                          value={item.description}
                          onChange={(e) => {
                            const updatedItems = [...items];
                            updatedItems[index].description = e.target.value;
                            setItems(updatedItems);
                            setErrors({});
                          }}
                          placeholder={`Item ${index + 1} Description`}
                          fullWidth
                          required
                          error={
                            index === focusedIndex && !!errors["description"]
                          }
                          helperText={
                            index === focusedIndex && errors["description"]
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={2}>
                        <TextField
                          type="number"
                          value={item.rate === 0 ? "" : item.rate}
                          onChange={(e) => {
                            const updatedItems = [...items];
                            const newRate = parseFloat(e.target.value) || 0;
                            const clampedRate =
                              parseFloat(newRate.toFixed(2)) || 0;
                            updatedItems[index].rate = clampedRate;
                            setItems(updatedItems);
                            setErrors({});
                          }}
                          onWheel={(e) => e.target.blur()}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Rate"
                          required
                          error={index === focusedIndex && !!errors["rate"]}
                          helperText={index === focusedIndex && errors["rate"]}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={1.5}>
                        <TextField
                          type="tel"
                          value={item.quantity}
                          onChange={(e) => {
                            const updatedItems = [...items];
                            const newQuantity = parseFloat(e.target.value) || 0;
                            updatedItems[index].quantity =
                              newQuantity < 0 ? 0 : newQuantity;
                            setItems(updatedItems);
                            setErrors({});
                          }}
                          inputProps={{
                            min: 0,
                          }}
                          placeholder="Quantity"
                          required
                          error={index === focusedIndex && !!errors["quantity"]}
                          helperText={
                            index === focusedIndex && errors["quantity"]
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={1.5}>
                        <TextField
                          type="number"
                          sx={{ width: "100%" }}
                          value={
                            item.discountPercent === 0
                              ? ""
                              : item.discountPercent
                          }
                          onChange={(e) => {
                            const updatedItems = [...items];
                            const inputValue = parseFloat(e.target.value) || 0;
                            const clampedValue = Math.min(
                              100,
                              Math.max(0, inputValue.toFixed(2))
                            );
                            updatedItems[index].discountPercent = clampedValue;
                            setItems(updatedItems);
                          }}
                          onWheel={(e) => e.target.blur()}
                          inputProps={{
                            min: 0,
                            max: 99,
                          }}
                          placeholder="Discount"
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={2}>
                        <TextField
                          type="number"
                          value={(
                            item.rate *
                            item.quantity *
                            (1 - item.discountPercent / 100)
                          ).toFixed(2)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={1}>
                        <IconButton
                          onClick={() => {
                            if (isEditOnly && item.lineId) {
                              console.log(item.lineId);
                              handleInvoiceLineDeleteOpen();
                              setSelectedInvoiceItem(item);
                              setSelectedLineIndex(index);
                            } else {
                              handleDeleteItem(index);
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
            </List>
            <Card className={styles.res_mobile_create_edit}>
            <Table>
                  <TableHead className={styles.res_table_head}>
                    <TableRow>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Description
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Rate
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Quantity
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Discount (%)
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Total
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px", color: "#666", fontWeight: "600" }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items && items.map((item, index) => (
                      <TableRow key={index} className={styles.res_table_row}>
                        <TableCell>
                          <label>Description</label>
                          <TextField
                          type="text"
                          value={item.description}
                          onChange={(e) => {
                            const updatedItems = [...items];
                            updatedItems[index].description = e.target.value;
                            setItems(updatedItems);
                            setErrors({});
                          }}
                          placeholder={`Item ${index + 1} Description`}
                          fullWidth
                          required
                          error={
                            index === focusedIndex && !!errors["description"]
                          }
                          helperText={
                            index === focusedIndex && errors["description"]
                          }
                          className={styles.res_mobile_create_input}
                        />
                        </TableCell>
                        <TableCell>
                          <label>Rate</label>
                          <TextField
                          type="number"
                          value={item.rate === 0 ? "" : item.rate}
                          onChange={(e) => {
                            const updatedItems = [...items];
                            const newRate = parseFloat(e.target.value) || 0;
                            const clampedRate =
                              parseFloat(newRate.toFixed(2)) || 0;
                            updatedItems[index].rate = clampedRate;
                            setItems(updatedItems);
                            setErrors({});
                          }}
                          onWheel={(e) => e.target.blur()}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Rate"
                          required
                          error={index === focusedIndex && !!errors["rate"]}
                          helperText={index === focusedIndex && errors["rate"]}
                          className={styles.res_mobile_create_input}
                        />
                        </TableCell>
                        <TableCell>
                          <label>Quantity</label>
                          <TextField
                          type="tel"
                          value={item.quantity}
                          onChange={(e) => {
                            const updatedItems = [...items];
                            const newQuantity = parseFloat(e.target.value) || 0;
                            updatedItems[index].quantity =
                              newQuantity < 0 ? 0 : newQuantity;
                            setItems(updatedItems);
                            setErrors({});
                          }}
                          inputProps={{
                            min: 0,
                          }}
                          placeholder="Quantity"
                          required
                          error={index === focusedIndex && !!errors["quantity"]}
                          helperText={
                            index === focusedIndex && errors["quantity"]
                          }
                          className={styles.res_mobile_create_input}
                        />
                        </TableCell>
                        <TableCell>
                          <label>Discount (%)</label>
                          <TextField
                          type="number"
                          sx={{ width: "100%" }}
                          value={
                            item.discountPercent === 0
                              ? ""
                              : item.discountPercent
                          }
                          onChange={(e) => {
                            const updatedItems = [...items];
                            const inputValue = parseFloat(e.target.value) || 0;
                            const clampedValue = Math.min(
                              100,
                              Math.max(0, inputValue.toFixed(2))
                            );
                            updatedItems[index].discountPercent = clampedValue;
                            setItems(updatedItems);
                          }}
                          onWheel={(e) => e.target.blur()}
                          inputProps={{
                            min: 0,
                            max: 99,
                          }}
                          placeholder="Discount"
                          className={styles.res_mobile_create_input}
                        />
                        </TableCell>
                        <TableCell className={styles.res_line_total}>
                          <label>Total</label>
                          <TextField
                          type="number"
                          value={(
                            item.rate *
                            item.quantity *
                            (1 - item.discountPercent / 100)
                          ).toFixed(2)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                          className={styles.res_mobile_create_input}
                        />
                        </TableCell>
                        <TableCell>
                          <label>Actions</label>
                        <IconButton
                          onClick={() => {
                            if (isEditOnly && item.lineId) {
                              console.log(item.lineId);
                              handleInvoiceLineDeleteOpen();
                              setSelectedInvoiceItem(item);
                              setSelectedLineIndex(index);
                            } else {
                              handleDeleteItem(index);
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
            </Card>
            <Dialog
              open={isInvoiceItemDelete}
              onClose={handleInvoiceLineDeleteClose}
            >
              <DialogTitle fontSize={"18px"} fontWeight={"500"}>
                Are you sure you want to delete the{" "}
                {selectedInvoiceItem?.description} item?
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={handleInvoiceLineDeleteClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    handleInvoiceLineDeleteConfirm(selectedInvoiceItem?.lineId)
                  }
                  variant="contained"
                  color="primary"
                >
                  Apply
                </Button>
              </DialogActions>
            </Dialog>
            {/* Add Button */}
            <Button
              variant="outlined"
              onClick={() => {
                handleAddItem();
                calculateTotal();
              }}
              className={styles.res_mobile_add_line}
            >
              Add a Line
            </Button>
            {/* Subtotal */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={6} md={2} display={"flex"} justifyContent={"end"}>
                <Typography
                  variant="body1"
                  display={"flex"}
                  alignSelf={"center"}
                  fontWeight={600}
                >
                  Subtotal:
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  className={styles.sub_total_field}
                  type="number"
                  value={subTotal?.toFixed(2)}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: { border: "none" },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid
                item
                xs={6}
                md={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="outlined"
                  onClick={handleDiscountOpen}
                  fontWeight={600}
                  disabled={!subTotal}
                >
                  Add Discount
                </Button>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  className={styles.sub_total_field}
                  type="text"
                  value={`${discount} %`}
                  onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)}
                  inputProps={{ min: 0, max: 100 }}
                />
              </Grid>
            </Grid>

            {/* Discounted Total */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={6} md={2} display={"flex"} justifyContent={"end"}>
                <Typography
                  variant="body1"
                  display={"flex"}
                  alignSelf={"center"}
                  fontWeight={600}
                >
                  Amount saved:
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  type="text"
                  className={styles.sub_total_field}
                  value={`$ ${discountedAmount.toFixed(2)}`}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: { border: "none" },
                  }}
                />
              </Grid>
            </Grid>

            {/* Discount Modal */}
            <Dialog open={discountOpen} onClose={handleDiscountClose}>
              <DialogTitle fontSize={"18px"} fontWeight={"500"}>
                Enter Discount Percentage
              </DialogTitle>
              <DialogContent>
                <FormControl sx={{ width: "100%" }}>
                  <Input
                    type="number"
                    value={discountValue === 0 ? "" : discountValue}
                    onChange={(e) => {
                      setDiscountOpen(true);
                      // e.preventDefault()
                      const inputValue = parseFloat(e.target.value) || 0;
                      const clampedValue = Math.min(
                        99,
                        Math.max(0, inputValue.toFixed(2))
                      );
                      setDiscountValue(clampedValue);
                    }}
                    onWheel={(e) => e.target.blur()}
                    inputProps={{
                      min: 0.01,
                      max: 99,
                    }}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDiscountClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleDiscountApply}
                  variant="contained"
                  color="primary"
                >
                  Apply
                </Button>
              </DialogActions>
            </Dialog>

            {/* Add Tax Button */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid
                item
                xs={6}
                md={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="outlined"
                  onClick={handleTaxOpen}
                  fontWeight={600}
                  disabled={!subTotal}
                >
                  Add Tax
                </Button>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  type="text"
                  className={styles.sub_total_field}
                  value={`${tax} %`}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            {/* Tax Modal */}
            <Dialog open={taxOpen} onClose={handleTaxClose}>
              <DialogTitle fontSize={"18px"} fontWeight={"500"}>
                Enter Tax Percentage
              </DialogTitle>
              <DialogContent>
                <FormControl sx={{ width: "100%" }}>
                  <Input
                    type="number"
                    value={taxValue === 0 ? "" : taxValue}
                    onChange={(e) => {
                      const inputValue = parseFloat(e.target.value) || 0;
                      const clampedValue = Math.min(
                        100,
                        Math.max(0, inputValue.toFixed(2))
                      );
                      setTaxValue(clampedValue);
                    }}
                    onWheel={(e) => e.target.blur()}
                    inputProps={{ min: 0.01, max: 100 }}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleTaxClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleTaxApply}
                  variant="contained"
                  color="primary"
                >
                  Apply
                </Button>
              </DialogActions>
            </Dialog>

            {/* Tax */}
            <Grid container spacing={2} display={"none"}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={6} md={2} display="flex" justifyContent={"end"}>
                <Typography
                  variant="body1"
                  display="flex"
                  alignSelf="center"
                  fontWeight={600}
                >
                  Tax:
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  type="text"
                  className={styles.sub_total_field}
                  value={`$ ${taxAmount.toFixed(2)}`}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            {/* Total After Tax */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={6} md={2} display="flex" justifyContent={"end"}>
                <Typography
                  variant="body1"
                  display="flex"
                  alignSelf="center"
                  fontWeight={600}
                >
                  Tax Amount:
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  type="text"
                  className={styles.sub_total_field}
                  value={`$ ${taxAmount.toFixed(2)}`}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={12} md={4} className={styles.horizontal_line}>
                <Divider style={{ margin: "6px 0 0 0" }} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={6} md={2} display="flex" justifyContent={"end"}>
                <Typography
                  variant="body1"
                  display="flex"
                  alignSelf="center"
                  fontWeight={600}
                >
                  Total Amount:
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  type="text"
                  className={styles.sub_total_field}
                  value={`$ ${totalAmount.toFixed(2)}`}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            {/* Amount Paid */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={6} md={2} display={"flex"} justifyContent={"end"}>
                <Typography
                  variant="body1"
                  display={"flex"}
                  alignSelf={"center"}
                  fontWeight={600}
                >
                  Amount Paid:
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  type="number"
                  className={styles.sub_total_field}
                  value={amountPaid === 0 ? "" : amountPaid}
                  onChange={(e) => {
                    const inputValue =
                      parseFloat(e.target.value) || 0;
                    const clampedValue = Math.min(
                      totalAmount.toFixed(2),
                      Math.max(0, inputValue.toFixed(2))
                    );
                    setAmountPaid(clampedValue);
                    setAmountErrors({});
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    min: 0,
                    max: totalAmount,
                  }}
                  onWheel={(e) => e.target.blur()}
                  disabled={!subTotal}
                  placeholder="Amount Paid"
                  error={!!amountErrors["amountPaid"]}
                  helperText={amountErrors["amountPaid"]}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={12} md={4} className={styles.horizontal_line}>
                <Divider style={{ margin: "6px 0 0 0" }} />
              </Grid>
            </Grid>

            {/* Amount Due */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}></Grid>
              <Grid item xs={6} md={2} display={"flex"} justifyContent={"end"}>
                <Typography
                  variant="body1"
                  display={"flex"}
                  alignSelf={"center"}
                  fontWeight={600}
                >
                  Amount Due:
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  type="text"
                  className={styles.sub_total_field}
                  value={`$ ${amountDue == 0 ? "0.00" : amountDue}`}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceForm;
