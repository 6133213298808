import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ClientMobileSidebar from "./clientMobileSidebar";
import LogoutIcon from "@mui/icons-material/Logout";
const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL;

  // this will be used to detect mobile view
  // if true, we will render a mobileSidebar in the topbar
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigateToSettings = () => {
    navigate("/ClientSettings");
  };

  const handleLogout = async () => {
    const response = await fetch(`${API_URL}/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
      },
    }).then(navigate("/"));
  };

  return (
    <Box display="flex" justifyContent="space-between" sx={{ mx: 2 }}>
      <Box>
        {/* Conditionally rendering mobileSidebar*/}
        {isMobile && <ClientMobileSidebar />}
      </Box>

      <Box justifyContent="flex-end">
        <Tooltip title="Settings">
          <IconButton>
            <SettingsOutlinedIcon onClick={navigateToSettings} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Logout">
          <IconButton>
            <LogoutIcon onClick={handleLogout} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Topbar;
