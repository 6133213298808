import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Grid, Paper, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { mockNotesDatabase } from "../../data/mockNotesData";
import { tokens } from "../../theme";
import { useTheme } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

const Updates = () => {
  const { projectId } = useParams();
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const API_URL = process.env.REACT_APP_API_URL;

  // method to get project updates
  // its a method because so we can use it at different points to refresh the updates list
  const fetchProjectUpdates = async () => {
    try {
      const response = await fetch(
        `${API_URL}/client-project-update/get-updates/${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      const data = await response.json();

      const projectNotes = Array.isArray(data)
        ? data.map((note) => ({
            ...note,
          }))
        : [];

      if (projectNotes.length > 0) {
        setSelectedNote(projectNotes[0]);
      } else {
        setSelectedNote(null);
      }

      setNotes(projectNotes);
    } catch (error) {
      console.error("Failed to fetch project notes:", error);
      // Handle error appropriately here
    }
  };

  //get all updates using the fetch project updates
  useEffect(() => {
    fetchProjectUpdates();
  }, [projectId]);

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Function to format date
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  };

  const renderImageCarousel = () => {
    const totalImages = selectedNote?.fileInfoDTOs?.length || 0;
    const isPrevDisabled = carouselIndex <= 0;
    const isNextDisabled = carouselIndex >= totalImages - 1;

    const handlePrevClick = () => {
      if (!isPrevDisabled) {
        setCarouselIndex((prevIndex) => prevIndex - 1);
      }
    };

    const handleNextClick = () => {
      if (!isNextDisabled) {
        setCarouselIndex((prevIndex) => prevIndex + 1);
      }
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 3,
          mb: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={handlePrevClick}
            disabled={isPrevDisabled}
            sx={{
              opacity: isPrevDisabled ? 0.3 : 1,
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            }}
            aria-label="Previous image"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Box sx={{ display: "flex", overflow: "hidden", maxWidth: "80%", height:"200px" }}>
            {selectedNote?.fileInfoDTOs?.[carouselIndex] && (
              <img
                src={`${API_URL}/${selectedNote.fileInfoDTOs[carouselIndex].fileUrl}`}
                alt={`Attachment ${carouselIndex}`}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "100%",
                  objectFit: "contain",
                  cursor: "pointer",
                  margin: "auto",
                }}
                onClick={() =>
                  handleImageClick(selectedNote.fileInfoDTOs[carouselIndex])
                }
              />
            )}
          </Box>
          <IconButton
            onClick={handleNextClick}
            disabled={isNextDisabled}
            sx={{
              opacity: isNextDisabled ? 0.3 : 1,
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            }}
            aria-label="Next image"
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          {Array.from({ length: totalImages }, (_, index) => (
            <div
              key={index}
              style={{
                height: "10px",
                width: "10px",
                backgroundColor:
                  index === carouselIndex ? "black" : "lightgray",
                borderRadius: "50%",
                margin: "5px",
              }}
            />
          ))}
        </Box>
      </Box>
    );
  };

  // if (!selectedNote) {
  //   return <div>No projects updates</div>;
  // }

  return (
    <Box m="20px">
      <Typography variant="h4" mb={1}>Project Updates</Typography>
      <Box sx={{
          backgroundColor: colors.MoonGrey[200],
          boxShadow: 3,
          borderRadius: 1,
          minHeight: "84vh",
        }}>
        <Grid container spacing={3} sx={{ marginTop: "1em" }}>
          <Grid item xs={12} md={4}>
            <Paper
              style={{ maxHeight: "50vh", overflow: "auto" }}
              sx={{ ml: "10px" }}
            >
              {notes.map((note, index) => (
                <MenuItem
                  key={note.projectUpdateId}
                  selected={
                    selectedNote &&
                    note.projectUpdateId === selectedNote.projectUpdateId
                  }
                  onClick={() => {
                    setSelectedNote(note);
                    setCarouselIndex(0);
                  }}
                >
                  <ListItemAvatar>
                    {note.fileInfoDTOs && note.fileInfoDTOs.length > 0 ? (
                      <Avatar
                        variant="rounded"
                        src={`${API_URL}/${note.fileInfoDTOs[0].fileUrl}`}
                        alt={note.title}
                      />
                    ) : (
                      // Render some default content if fileInfoDTOs is null or empty
                      <Avatar variant="rounded">N/A</Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={note.title}
                    primaryTypographyProps={{
                      style: { 
                        fontWeight: "bold", 
                        fontSize: "1.2rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                      },
                    }}
                    secondary={`Created: ${formatDate(note.date)}`}
                  />
                </MenuItem>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            {selectedNote && (
              <>
                <Typography variant="h5" sx={{ marginBottom: "1em", marginX:"0.5em", border: "1px solid black", p:"1em", borderRadius: "8px" }}>
                  {selectedNote.title}
                </Typography>
                <Typography variant="h5" sx={{ marginBottom: "1em", marginX:"0.5em", border: "1px solid black", p:"1em", borderRadius: "8px", height: "200px",overflow: "auto" }}>
                  {selectedNote.description}
                </Typography>
                {selectedNote.fileInfoDTOs.length > 0 && renderImageCarousel()}

              </>
            )}
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{"Image"}</DialogTitle>
          <DialogContent>
            <img
              src={selectedImage}
              alt="Selected attachment"
              style={{
                width: "100%",
                maxHeight: "400px",
                objectFit: "contain",
              }}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Updates;
