import React, { Component, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import LogoUpload from "./LogoUpload";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";

const AddBrandLogos = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [logos, setLogos] = useState({
    mainLogo: null,
    sidebarLogo: null,
    emailLogo: null,
  });
  const API_URL = process.env.REACT_APP_API_URL;

  const businessId = Cookies.get("businessId");

  useEffect(() => {
    if (logos.mainLogo) {
    }
  }, [logos]);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const response = await fetch(
          `${API_URL}/logo/get-logos?businessId=${businessId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              // If needed: 'X-XSRF-TOKEN': cookie,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data);

        // Process the logos
        const logosMap = data.reduce(
          (acc, logo) => {
            acc[logo.logoType] = logo;
            return acc;
          },
          { mainLogo: null, sidebarLogo: null, emailLogo: null }
        );

        await setLogos(logosMap);
      } catch (error) {
        console.error("Failed to fetch logos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLogos();
  }, [businessId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        style={{ marginBottom: "20px", marginLeft: "20px" }}
      >
        Upload Brand Logos
      </Typography>
      <Grid container spacing={1}>
        <LogoUpload
          label="Main Login Page Logo"
          id="main-logo"
          logoType="mainLogo"
          businessId={Cookies.get("businessId")}
          fileInfoDTO={logos.mainLogo?.fileInfoDTO}
          logoId={logos.mainLogo?.logoId}
        />
        <LogoUpload
          label="Sidebar Logo"
          id="sidebar-logo"
          logoType="sidebarLogo"
          businessId={Cookies.get("businessId")}
          fileInfoDTO={logos.sidebarLogo?.fileInfoDTO}
          logoId={logos.sidebarLogo?.logoId}
        />
        <LogoUpload
          label="Email Signature Logo"
          id="email-logo"
          logoType="emailLogo"
          businessId={Cookies.get("businessId")}
          fileInfoDTO={logos.emailLogo?.fileInfoDTO}
          logoId={logos.emailLogo?.logoId}
        />
      </Grid>
    </Box>
  );
};

export default AddBrandLogos;
