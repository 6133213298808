import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import ClientInvoiceTable from "./components/invoiceTable";
import ClientInvoiceForm from "./components/invoiceForm";

const ClientInvoices = () => {
  const [isShowInvoiceForm, setIsShowInvoiceForm] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");

  const handleInvoiceForm = (invId) => {
    setIsShowInvoiceForm(!isShowInvoiceForm);
    setInvoiceId(invId);
  };
  return (
    <Box m={4}>
      <Typography variant="h4" mb={3}>
        Invoices
      </Typography>
      <Box>
        {isShowInvoiceForm ? (
          <ClientInvoiceForm
            onClose={handleInvoiceForm}
            selectedInvId={invoiceId}
          />
        ) : (
          <ClientInvoiceTable onClick={handleInvoiceForm} />
        )}
      </Box>
    </Box>
  );
};

export default ClientInvoices;
