import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MessageIcon from "@mui/icons-material/Message";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const ClientMobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const pathLocation = useLocation();
  const pathSegments = pathLocation.pathname.split("/");
  const projectId = pathSegments[2];
  const API_URL = process.env.REACT_APP_API_URL;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const navigateTo = (path) => () => navigate(path);

  // MenuItemComponent
  const MenuItemComponent = ({ title, path, icon }) => (
    <ListItem button onClick={navigateTo(path)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={<Typography style={{ color: "#FFFFFF" }}>{title}</Typography>}
      />
    </ListItem>
  );

  const menuItems = [
    {
      title: "Dashboard",
      path: `/ClientDashboard/${projectId}`,
      icon: <HomeOutlinedIcon style={{ color: "#FFFFFF" }} />,
    },
    {
      title: "Calendar",
      path: `/ClientCalendar/${projectId}`,
      icon: <CalendarTodayOutlinedIcon style={{ color: "#FFFFFF" }} />,
    },
  ];

  const [logos, setLogos] = useState({
    mainLogo: null,
    sidebarLogo: null,
    emailLogo: null,
  });

  //get logos
  useEffect(() => {
    async function fetchLogo() {
      try {
        const response = await fetch(
          `${API_URL}/logo/get-logos?businessId=${Cookies.get("businessId")}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              // If needed: 'X-XSRF-TOKEN': cookie,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const logosMap = data.reduce(
          (acc, logo) => {
            acc[logo.logoType] = logo;
            return acc;
          },
          { mainLogo: null, sidebarLogo: null, emailLogo: null }
        );

        console.log(logosMap);

        setLogos(logosMap);
      } catch (error) {
        console.error("Failed to fetch logos:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchLogo();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  // grabbing user and business data to display on side bar
  useEffect(() => {
    const fetchData = async () => {
      await fetchUserData();
      await fetchBusinessData();
      setIsLoading(false);
    };

    const fetchUserData = async () => {
      try {
        const userId = Cookies.get("userId");
        if (userId) {
          const response = await fetch(
            `${API_URL}/clientPortal-user/get-single-user?userId=${userId}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
              },
            }
          );

          const data = await response.json();
          setUser(data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchBusinessData = async () => {
      try {
        const businessId = Cookies.get("businessId");
        if (businessId) {
          const response = await fetch(
            `${API_URL}/client-business/getBusiness?businessId=${businessId}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
              },
            }
          );

          const data = await response.json();
          setBusiness(data);
        }
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };

    fetchData();
  }, []);

  // Function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else return "";
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        style={{ marginLeft: "2px" }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          style={{
            width: "290px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#0B1537", // Adjusted background color
          }}
        >
          {/*top of the side bar*/}
          <Box>
            <Box textAlign="center" mt="10px">
              {logos.sidebarLogo ? (
                <img
                  src={`${API_URL}/${logos.sidebarLogo.fileInfoDTO.fileUrl}`}
                  alt="Sidebar Logo"
                  style={{
                    position: "relative",
                    maxWidth: "70%",
                    maxHeight: "70%",
                    maxHeight: "150px",
                    zIndex: 10,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color="#FFFFFF"
                  sx={{
                    textAlign: "center",
                    wordBreak: "break-word",
                    padding: "10px",
                  }}
                >
                  {capitalizeFirstLetter(business.businessName)}
                </Typography>
              )}
            </Box>

            <List>
              {menuItems.map((item) => (
                <MenuItemComponent
                  key={item.title}
                  title={item.title}
                  path={item.path}
                  icon={item.icon}
                />
              ))}
            </List>
          </Box>

          {/* bottom of the side bar*/}
          <Box textAlign="center">
            <Typography
              variant="h4"
              style={{
                color: "#FFFFFF",
                margin: "10px 5px 10px 5px",
                wordBreak: "break-word",
              }} // Adjusted color
            >
              {user.firstName} {user.lastName}
            </Typography>
          </Box>
        </div>
      </Drawer>
    </div>
  );
};

export default ClientMobileSidebar;
