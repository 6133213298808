import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Stack,
  Chip,
  MenuItem,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const AddEmployeeToProject = ({ open, onClose, employees, onReload }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [userIdArr, setUserIdArr] = useState([]);
  const { projectId } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;
  const [getAllEmployees, setGetAllEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const handleSubmit = async () => {
    const employeeIds = selectedItems.map((item) => item.userId);
    await handleAddEmployee(employeeIds); // Add employees and refresh list
    setSelectedItems([]); // Clear selection
    onClose(); // Close dialog
  };

  const handleGetAllEmployees = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/user-projects/get-all-employees?projectId=${projectId}`,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      const data = await response.data;
      setGetAllEmployees(data);
      filterEmployeesData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllEmployees();
  }, [projectId]);

  const handleAddEmployee = async (employeeIds) => {
    try {
      const response = await axios.post(
        `${API_URL}/user-projects/add-employees?projectId=${projectId}`,
        employeeIds,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      console.log(await response.data);
      handleGetAllEmployees();
    } catch (error) {
      console.log(error);
    }
  };

  const filterEmployeesData = (allEmployees) => {
    const selectedEmployees = employees.map((employee) => employee.userId);
    const result = allEmployees.filter(
      (employee) => !selectedEmployees.includes(employee.userId)
    );
    console.log(result);
    setFilteredEmployees(result);
    return result;
  };

  const MultiSelect = ({ employees }) => {
    return (
      <FormControl sx={{ m: 1, width: 500 }}>
        <InputLabel
          id="select-employee"
          sx={{
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          Select Employees
        </InputLabel>
        <Select
          multiple
          value={selectedItems}
          onChange={(e) => setSelectedItems(e.target.value)}
          input={<OutlinedInput label="Select Employees" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value, index) => (
                <Chip
                  key={index}
                  label={value.firstName + " " + value.lastName}
                  onDelete={() =>
                    setSelectedItems(
                      selectedItems.filter(
                        (item) => item.userId !== value.userId
                      )
                    )
                  }
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                />
              ))}
            </Stack>
          )}
        >
          {employees.map((employee) => (
            <MenuItem
              key={employee.id}
              value={employee}
              sx={{ justifyContent: "space-between" }}
            >
              {employee.firstName} {employee.lastName}
              {selectedItems.some((item) => item.userId === employee.userId) ? (
                <CheckIcon color="info" />
              ) : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        style={{
          fontSize: "1.2rem",
          textAlign: "center",
          position: "relative",
        }}
      >
        Add Employees
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 15 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MultiSelect employees={employees}></MultiSelect>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "1rem" }}>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!selectedItems.length}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEmployeeToProject;
