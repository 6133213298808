import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Menu, 
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Breadcrumbs,
  Link,
  FormControl,
  InputLabel,
  Select,
  Tooltip
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import FolderIcon from '@mui/icons-material/Folder';
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { tokens } from "../../theme";
import { useTheme } from "@mui/system";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import Header from "../../components/Header";

// Define your API URL
const API_URL = process.env.REACT_APP_API_URL;

const FolderItem = ({ folder, onDelete, onOpenFolder, view }) => {

  const [isDialogOpen, setDialogOpen] = useState(false); // State to manage "confirm folder deletion" dialog visibility
  const [anchorEl, setAnchorEl] = useState(null); // For menu anchor
  const open = Boolean(anchorEl);

  // three dot menu   
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // three dot menu   
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const formatDate = (isoDate) => {
    return new Date(isoDate).toLocaleDateString(undefined, {
      year: 'numeric', month: 'long', day: 'numeric'
    });
  };


  const handleDeleteFolder = async () => {
    try {
      if (folder.createdBy === Cookies.get("userId")) {
        await axios.delete(
          `${API_URL}/client-document-sharing/deleteFolder/${folder.folderId}`,
          {
            withCredentials: true,
            withXSRFToken: true,
          }
        );

        onDelete(folder.folderId);
        closeDeleteConfirmationDialog();
      } else {
        alert("Access Denied. You can only delete folders that you created.");
        closeDeleteConfirmationDialog();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data;
        // Check if the error message contains "Folder is not empty"
        if (errorMessage.includes("Folder is not empty")) {
          alert("The folder is not empty and cannot be deleted.");
        } else {
          console.error("An unexpected error occurred when attempting to delete folder:  ", error);
        } 
      } else {
        console.error("An unexpected error occurred when attempting to delete folder: ", error);
      }
      closeDeleteConfirmationDialog();
    }
  };

  const openDeleteConfirmationDialog = () => setDialogOpen(true);
  const closeDeleteConfirmationDialog = () => setDialogOpen(false);

  return (
    <>
    {view === "Grid" ? (
      <Card sx={{
        p: 2,
        display: "flex",
        flexDirection: "row", 
        alignItems: "center", 
        justifyContent: "space-between", 
        cursor: "pointer",
        position: "relative",
        boxShadow: 1, 
        borderRadius: 2, 
      }}>
        <Tooltip title={folder.folderName} placement="top" enterDelay={1700}>
          <Box  onClick={() => onOpenFolder(folder.folderId)} sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, maxWidth: 'calc(100% - 48px)'  }}>
            <FolderIcon style={{ fontSize: 35, color: 'grey' }} />
            <Typography variant="subtitle2" sx={{ 
              fontWeight: "bold", 
              fontSize:16,  
              ml: 2,
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              //maxWidth: '80px', 
              }}>
              {folder.folderName}
            </Typography>
          </Box>
        </Tooltip>
        <IconButton onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
            <MenuItem onClick={openDeleteConfirmationDialog}>
              <DeleteIcon fontSize="small" sx={{ mr: 1 }} /> Delete
            </MenuItem>
          </Menu>
      </Card>
    ) : (
      <Card sx={{ display: 'flex', width: '100%', mb: 0.5 }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, maxWidth:'100%' }}>
          <Box onClick={() => onOpenFolder(folder.folderId)} sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, maxWidth: 'calc(100% - 140px)' }}>
            <FolderIcon style={{ fontSize: 35, color: 'grey' }} />
            <Typography variant="subtitle2" sx={{ 
              fontWeight: "bold", 
              fontSize:16,  
              ml: 2,
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              //maxWidth: '80px', 
              }}>
              {folder.folderName}
          </Typography>
          </Box>
          
          <Typography color="textSecondary" sx={{ ml: 2 }}>
            {formatDate(folder.createdDate)}
          </Typography>

          <IconButton onClick={handleMenuClick} edge="end">
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
            <MenuItem onClick={openDeleteConfirmationDialog}>
              <DeleteIcon fontSize="small" sx={{ mr: 1 }} /> Delete
            </MenuItem>
          </Menu>
        </CardContent>
      </Card>
    )}
     {/* Confirmation Dialog for Deleting Folder */}
     <Dialog open={isDialogOpen} onClose={closeDeleteConfirmationDialog}>
     <DialogTitle>Delete Folder</DialogTitle>
     <DialogContent>
       <Typography>Are you sure you want to delete this folder?</Typography>
     </DialogContent>
     <DialogActions>
       <Button onClick={closeDeleteConfirmationDialog}>Cancel</Button>
       <Button onClick={handleDeleteFolder} color="error">Delete</Button>
     </DialogActions>
   </Dialog>
   </>
  );
};


// // Function to handle individual document file
const DocumentFile = ({ file, onDelete, view }) => {
  // Define theme and dialog state
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // formatting that date to be displayed in listview
  const formatDate = (isoDate) => {
    if (!isoDate) {
      return "undefined";
    }

    return new Date(isoDate).toLocaleDateString(undefined, {
      year: 'numeric', month: 'long', day: 'numeric'
    });
  };


  // Handle document deletion
  const handleDeleteDocument = async () => {
    try {
      if (file.userOwnerId === Cookies.get("userId")) {
        await axios.delete(
          `${API_URL}/client-document-sharing/delete/${file.documentSharingId}`,
          {
            withCredentials: true,
            withXSRFToken: true,
          }
        );

        setDialogOpen(false);
        onDelete(file.documentSharingId);
      } else {
        alert("You can only delete files that you added.");
        setDialogOpen(false);
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  // Handle file download
  const handleDownloadFile = () => {
    const link = document.createElement("a");
    link.href = `${API_URL}/${file.fileInfoDTO.fileUrl}`;
    link.download = file.fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getFilePreview = (document, width, height) => {
    const fileExtension = document.fileName.split(".").pop().toLowerCase();

    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      // Directly use image URL for image files
      return (
        <img
          src={`${API_URL}/${document.fileInfoDTO.fileUrl}`}
          alt={document.fileName}
          width={width}
          height={height}
        />
      );
    } else {
      // Default file icon for other file types
      return (
        <InsertDriveFileIcon style={{ width: width, height: height }} />
      );
    }
  };

  // Reusable IconButton component
  const ActionIconButton = ({ children, onClick, color, position }) => (
    <IconButton
      color="primary"
      aria-label="action"
      onClick={onClick}
      style={{ color: color, position: "absolute", top: "0", ...position }}
    >
      {children}
    </IconButton>
  );

  // Confirmation dialog component
  const ConfirmationDialog = ({ isOpen, onClose, onConfirm }) => (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Document</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete the document?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  // menu for download/deleting file
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // menu for download/deleting file
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    view === "Grid" ? ( 
      <Card
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          style={{ position: "absolute", top: "0", right: "0" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => { handleDownloadFile(); handleMenuClose(); }}>
            <GetAppIcon />&nbsp;Download
          </MenuItem>
          <MenuItem onClick={() => { setDialogOpen(true); handleMenuClose(); }}>
            <DeleteIcon />&nbsp;Delete
          </MenuItem>
        </Menu>

        <Tooltip title={file.fileName} placement="top" enterDelay={1700}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            {file.fileName}
          </Typography>
        </Tooltip>

        {getFilePreview(file, "200px", "200px")}

        <ConfirmationDialog
          isOpen={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={() => handleDeleteDocument()}
        />
      </Card>
    ) : (
      <Card sx={{ display: 'flex', width: '100%', mb: 0.5 }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, width:'100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, maxWidth: 'calc(100% - 145px)' }}>
            {getFilePreview(file, "35px", "35px")}
            <Typography variant="subtitle2" sx={{ 
              fontWeight: "bold", 
              fontSize:16,  
              ml: 2,
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              //maxWidth: '80px', 
              }}>
              {file.fileName}
          </Typography>
          </Box>

          <Typography color="textSecondary" sx={{ ml: 2 }} >
            {formatDate(file.createdDate)}
          </Typography>

          <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          edge="end"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => { handleDownloadFile(); handleMenuClose(); }}>
            <GetAppIcon />&nbsp;Download
          </MenuItem>
          <MenuItem onClick={() => { setDialogOpen(true); handleMenuClose(); }}>
            <DeleteIcon />&nbsp;Delete
          </MenuItem>
        </Menu>

        </CardContent>
        <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDeleteDocument}
        />
      </Card>
    )
  );
};



const DocumentSharing = () => {
  // State to store documents
  const [documents, setDocuments] = useState([]);
  const [subFolders, setSubFolders] = useState([]);
  const [curFolder, setCurFolder] = useState(null);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { clientUserId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [view, setView] = useState("Grid");
  const [openDialog, setOpenDialog] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  // this is a LIFO stack used to do hierachical navigation through folders 
  const [navigationStack, setNavigationStack] = useState([]); 
  const [sortingCriteria, setSortingCriteria] = useState('date'); // 'date' or 'alpha'


  //const [isListView, setIsListView] = useState(false);

  const inputRef = useRef(null);

  // used to sort the docs and files 
  // sort alphabetically
  // sort by date
  const sortItems = (items, criteria) => {
    return items.sort((a, b) => {
      if (criteria === 'alpha') {
        // Determine the correct property to use ('folderName' for folders, 'fileName' for documents)
        const nameA = a.folderName || a.fileName;
        const nameB = b.folderName || b.fileName;
        
        return nameA.localeCompare(nameB);
      } else { // Default to sorting by date
        // Assuming both folders and documents have a 'createdDate' property
        return new Date(b.createdDate) - new Date(a.createdDate);
      }
    });
  };

  const sortBothLists = (criteria) => {
    setSortingCriteria(criteria); // Update the sorting criteria state
    setSubFolders(sortItems(subFolders, criteria)); // Sort folders then set
    setDocuments(sortItems(documents, criteria)); // Sort documents then set
  };

  // Fetch documents for the project
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/client-document-sharing/get-documents/${projectId}`,
          {
            withCredentials: true,
            withXSRFToken: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        console.log("getting docs from default folder: ");
        console.log(response.data);
        // todo: add saftey check on response. 200ok and not empty
        setCurFolder(response.data);
        setDocuments(sortItems(response.data.documents, sortingCriteria)  || []);
        setSubFolders(sortItems(response.data.subFolders, sortingCriteria) || []);


        setNavigationStack([{ "folderId": response.data.folderId, "contents": response.data }]);
        
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };
    fetchDocuments();
  }, [projectId]);

  // Function to handle file uploads and create a new document
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected for upload.");
      return;
    }

    // create the dtos and form data we need to post
    const newDocumentSharingDTO = {
      userOwnerId: Cookies.get("userId"),
      projectId: projectId,
      businessId: Cookies.get("businessId"),
      fileName: file.name,
      folderId: curFolder.folderId,
    };
    
    // Create a FormData object to hold the file
    const formData = new FormData();
    formData.append("documentSharingDTO", JSON.stringify(newDocumentSharingDTO));
    formData.append("folderDTO", JSON.stringify(curFolder));
    formData.append("file", file);


    try {
      // Post the form data to the backend to create a new document
      const response = await axios.post(
        `${API_URL}/client-document-sharing/create`,
        formData,
        {
          withCredentials: true,
          withXSRFToken: true,
        }
      );

      // Update the documents in the current folder
      const updatedNavigationStack = navigationStack.map(folder => {
        if (folder.folderId === curFolder.folderId) {
          folder.contents.documents = [...folder.contents.documents, response.data];
        }
        return folder;
      });

      setNavigationStack(updatedNavigationStack);

      setDocuments(sortItems([...documents, response.data], sortingCriteria));
    } catch (error) {
      console.log(error);

      if (error.response) {
        
        if (error.response.status === 500 && error.response.data.message === "Maximum upload size exceeded") {
          alert("Upload failed: The file size exceeds the maximum limit of 10MB. Please select a smaller file and try again.");
        } else if(error.response.status === 413) {
          alert("Upload failed: The file size exceeds the maximum limit of 10MB. Please select a smaller file and try again.");
        } else if (error.response.status === 409){
          alert(error.response.data.message);
        } else {
          console.error("An unexpected error occurred when upload the file:  ", error);
          alert("An unexpected error occurred when upload the file");
        } 
      } else {
        console.error("An unexpected error occurred when upload the file :  ", error);
        alert("An unexpected error occurred when upload the file");

      }
    }
  };

  //dleting a document
  const handleDelete = (documentId) => {
     // Update the documents in the current folder
    const updatedNavigationStack = navigationStack.map(folder => {
      if (folder.folderId === curFolder.folderId) {
        folder.contents.documents = folder.contents.documents.filter(doc => doc.documentSharingId !== documentId);
      }
      return folder;
    });

    setNavigationStack(updatedNavigationStack);

    setDocuments(sortItems( documents.filter((doc) => doc.documentSharingId !== documentId), sortingCriteria));
  };

  // opens dropdown menu for adding doc or folder
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // closing the menue for adding doc/folder
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handler for adding a new document
  const handleAddDocument = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(event);
    }
  };

  // menue to either add new doc or new folder
  const handleMenuClick = (action) => {
    if (action === 'addDocument') {
      inputRef.current.click();
    } else if (action === 'createFolder') {
      handleDialogOpen();
    }
    handleClose();
  };

  // Navigate back to project page
  const navigateBack = () => {
    navigate(`/users/${clientUserId}/${projectId}`);
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  // forms let us submit when we hit "enter" instead of needing to click create button
  // we use this handler to prevent default onSubmit behaviour for forms and instead call out method to create the folder
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    handleCreateFolder(); // Call your existing function to create the folder
  };

  // used to open popup for creating new folder
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  
  // used to close popup for creating new folder
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // used to delete folder from state
  const handleDeleteFolder = (folderId) => {
    setSubFolders(sortItems(subFolders.filter((folder) => folder.folderId !== folderId), sortingCriteria));


    // Update navigationStack to remove any instances of the deleted folder
    // and update parent folder's subFolders list
    const updatedNavigationStack = navigationStack.map(folder => {
      if (folder.contents.subFolders) {
        folder.contents.subFolders = folder.contents.subFolders.filter(subFolder => subFolder.folderId !== folderId);
      }
      return folder;
    });

    setNavigationStack(updatedNavigationStack);
  };
  
  // used for creating new folder
  const handleCreateFolder = async () => {
    console.log("Folder name: ", newFolderName);
    
    // Construct the FolderDTO object
    // We might want to change path to use curFolderId to avoid collisions
    const folderDTO = {
      folderName: newFolderName,
      projectId: projectId,
      businessId: Cookies.get("businessId"),
      createdDate: new Date().toISOString(),
      createdBy: Cookies.get("userId"),
      parentFolderId: curFolder.folderId,
      path: curFolder.path + "/" + curFolder.folderId, 
    };

    try {
      // Make the POST request to the createFolder endpoint
      const response = await axios.post(`${API_URL}/client-document-sharing/createFolder`, folderDTO, {
        withCredentials: true,
        withXSRFToken: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (navigationStack.length > 0 && navigationStack[navigationStack.length - 1].folderId === curFolder.folderId) {
        // Update the subFolders of the last folder in navigationStack
        let updatedNavigationStack = [...navigationStack];
        let lastFolder = updatedNavigationStack[updatedNavigationStack.length - 1];
        if (!lastFolder.contents.subFolders) {
          lastFolder.contents.subFolders = []; // Initialize subFolders if it doesn't exist
        }
        lastFolder.contents.subFolders.push(response.data);
        
        // Update the navigationStack state
        setNavigationStack(updatedNavigationStack);
  
        // Update the subFolders state if we're currently in the last folder
        setSubFolders(sortItems(lastFolder.contents.subFolders, sortingCriteria));
      } else {
        // If not, just add the folder to the subFolders list directly
        setSubFolders(sortItems([...subFolders, response.data], sortingCriteria));
      }

  
    } catch (error) {
      console.error("Error creating folder:", error);
      // Handle errors here, such as displaying a message to the user
    }
    
    setNewFolderName("");
    setOpenDialog(false);
  };

    // method "opens" a folder
  // 1 -(todo): stores parent folder id
  // 2 - gets content of selected folder
  const openFolder = async (folderId) => {
    try {
      const response = await axios.get(`${API_URL}/client-document-sharing/get-folderContent/${folderId}`, {
        withCredentials: true,
        withXSRFToken: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("Fetching folder content: ", response.data);
      // Update your documents and subFolders state with the new data
      setCurFolder(response.data);
      setDocuments(sortItems( response.data.documents , sortingCriteria) || []);
      setSubFolders(sortItems(response.data.subFolders, sortingCriteria) || []);

      // add the new folder to our stack
      if (navigationStack.length === 0 || navigationStack[navigationStack.length - 1].folderId !== folderId) {
        setNavigationStack([...navigationStack, { "folderId": folderId, "contents": response.data }]);
      }

    } catch (error) {
      console.error("Error fetching folder contents:", error);
    }
  };


  // this method will navigate back to the selected folder in our bread crumbs
  const handleNavigateToFolderInBreadcrumb = (selectedFolderId) => {
    // Find the index of the selected folder in the navigation stack
    const selectedIndex = navigationStack.findIndex(folder => folder.folderId === selectedFolderId);
  
    if (selectedIndex !== -1) {
      // Slice the stack up to and including the selected folder
      const newStack = navigationStack.slice(0, selectedIndex + 1);
      setNavigationStack(newStack);
  
      // Update the UI to show the contents of the selected folder
      const selectedFolderContents = newStack[selectedIndex].contents;
      setCurFolder(selectedFolderContents);
      setDocuments(sortItems(selectedFolderContents.documents, sortingCriteria) || []);
      setSubFolders(sortItems(selectedFolderContents.subFolders, sortingCriteria) || []);
    }
  };

  return (
    <Box m={2}>
      <Breadcrumbs aria-label="breadcrumb" marginBottom="20px">
        {navigationStack.map((folder, index) => {
          const isLast = index === navigationStack.length - 1;
          return isLast ? (
            <Typography color="textPrimary" key={folder.folderId} sx={{ fontSize: '1.25rem' }}>
              {index === 0 ? 'SharedDocs' : folder.contents.folderName}
            </Typography>
          ) : (
            <Link
              key={folder.folderId}
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                handleNavigateToFolderInBreadcrumb(folder.folderId);
              }}
              color="inherit"
              sx={{ fontSize: '1.25rem' }}
            >
              {index === 0 ? 'SharedDocs' : folder.contents.folderName}
            </Link>
          );
        })}
      </Breadcrumbs>

      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
        <Box display="flex" flexGrow={1} alignItems="center">
          <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={handleAddDocument} />
          <Card onClick={handleClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginRight: '1rem', padding: '8px 16px' }}>
            <AddIcon color="primary" />
            <Typography ml={1}>New</Typography>
          </Card>
        </Box>

        <Box display="flex" flexGrow={1} justifyContent="flex-end">
        <Box m={2}>
            <FormControl fullWidth sx={{ height: '25px', minWidth: '130px'}}>
              <InputLabel id="sort-label" >Sort</InputLabel>
              <Select
                labelId="sort-label"
                value={sortingCriteria}
                label="Sort"
                onChange={(e) => sortBothLists(e.target.value)}
                sx={{ height: '25px'}}
              >
                <MenuItem value="alpha" fontSize="small" sx={{ height: '25px'}}>Alphabetically</MenuItem>
                <MenuItem value="date" fontSize="small" sx={{ height: '25px'}}>By Date</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <List component="nav" sx={{ display: 'flex', width: 'auto' }}>
            <ListItem button selected={view === "Grid"} onClick={() => handleViewChange("Grid")} sx={{ minWidth: '30px' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}><GridViewIcon fontSize="small" /></ListItemIcon>
            </ListItem>
            <ListItem button selected={view === "List"} onClick={() => handleViewChange("List")} sx={{ minWidth: '30px' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}><ViewListIcon fontSize="small" /></ListItemIcon>
            </ListItem>
          </List>
        </Box>
      </Box>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuClick('addDocument')}>Add Document</MenuItem>
        <MenuItem onClick={() => handleMenuClick('createFolder')}>Create Folder</MenuItem>
      </Menu>

      {/* popup will ask for the name of the new folder*/}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create New Folder</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the name for the new folder.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="folderName"
              label="Folder Name"
              type="text"
              fullWidth
              variant="standard"
              sx={{ input: { fontSize: '16px' } }}
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleCreateFolder} disabled={!newFolderName.trim()}>Create</Button>
          </DialogActions>
          </form>
      </Dialog>

      {/* Displaying the files and folders */}
      {
        view === "List" ? (
          <List sx={{ width: '100%' }}>
            {subFolders.map((folder) => (
              <ListItem key={folder.folderId} sx={{p:0}}>
                <FolderItem folder={folder} onDelete={handleDeleteFolder}  onOpenFolder={openFolder} view={"List"} />
              </ListItem>
            ))}
            {documents.map((document) => (
              <ListItem key={document.documentSharingId} sx={{p:0}}>
                <DocumentFile file={document} onDelete={handleDelete} view={"List"}/>
              </ListItem>
            ))}
          </List>
        ) : (
          <>
      <Grid container spacing={2}>
        {subFolders.map((folder) => (
          <Grid item xs={12} sm={6} md={3} key={folder.folderId}>
            <FolderItem folder={folder} onDelete={handleDeleteFolder} onOpenFolder={openFolder} view={"Grid"} />
          </Grid>
        ))}
      </Grid>
      <div style={{ margin: '30px 0' }}></div>
      <Grid container spacing={2}>
        {documents.map((document) => (
          <Grid item xs={12} sm={6} md={3} key={document.documentSharingId}>
            <DocumentFile file={document} onDelete={handleDelete} view={"Grid"}/>
          </Grid>
        ))}
      </Grid>
    </>
        )
      }
      
    </Box>
  );
};

export default DocumentSharing;
