import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const API_URL = process.env.REACT_APP_API_URL;

    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const token = query.get('token');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/ForgotPassword/reset`, 
                {
                    "token": token,
                    "newPassword": newPassword
                },
                {
                    withCredentials: true,
                    headers: {
                    "Content-Type": "application/json",
                    'X-XSRF-TOKEN': Cookies.get("XSRF-TOKEN")
                    },
                }
            );
            setMessage(response.data);
        } catch (error) {
            setMessage('Error resetting password');
        }
        setNewPassword("");
        setConfirmPassword("");
    };

    const navigateToLogin = () =>{
        navigate("/");
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const renderPasswordAdornment = () => (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </InputAdornment>
    );

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 300, mx: "auto", my: 4 }}>
            <Typography variant="h5" mb={2}>Reset Password</Typography>
            <TextField
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{ endAdornment: renderPasswordAdornment() }}
                sx={{ input: { fontSize: '16px' } }}
            />
            <TextField
                label="Confirm Password"
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{ endAdornment: renderPasswordAdornment() }}
                sx={{ input: { fontSize: '16px' } }}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mb: 1 }} fullWidth>
                Reset Password
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 1 }} onClick={navigateToLogin} fullWidth>
                BACK TO LOGIN
            </Button>
            {message && <Typography color="textSecondary" mt={2}>{message}</Typography>}
        </Box>
    );
}

export default ResetPassword;