import React, { useState, useEffect, useInsertionEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  useTheme,
  Grid,
  useMediaQuery,
  Stack,
  Chip,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { tokens } from "../../theme";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

import GroupIcon from "@mui/icons-material/Group";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ConversationDialogBox from "./ConversationDialogBox"; // Assuming you've exported it correctly
import MemberDialog from "./MemberDialog";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

const InternalMessages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [clientName, setClientName] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;
  // using context
  const [project, setProject] = useState("");
  const employeeId = Cookies.get("userId");
  const [user, setUser] = useState([]);

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [bottom, setBottom] = useState(true);
  const [page, setPage] = useState(0);
  const employee = Cookies.get("userId");
  const messagesEndRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [searchTerm, setSearchTerm] = useState(""); // State to manage search term
  const [notificationCounts, setNotificationCounts] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState(false);
  const [conversationName, setConversationName] = useState("");

  const [showMembersDialog, setShowMembersDialog] = useState(false);
  const members =
    conversations.find((conv) => conv.conversationId === conversationId)
      ?.participantDTOs || [];

  const handleOpen = async() => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INTERNAL_MESSAGES)){
      alert("You are not authorized to create a new chat. Please contact Admin for help.");
      return;
    }
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const handleConfirm = async () => {
    // Get the current user's userId from cookies
    const currentUser = Cookies.get("userId"); // Make sure you have this cookie set

    // Map selectedEmployees to get only the userIds and include the current userId
    const userIds = [
      currentUser,
      ...selectedEmployees.map((emp) => emp.userId),
    ];

    const payload = {
      userIds: userIds,
      conversationName: conversationName,
    };

    try {
      const response = await fetch(`${API_URL}/messages/createInternalChat`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          Accept: "application/json",
        },
        body: JSON.stringify(payload), // Correctly formatted JSON body
      });

      if (response.ok && response.status === 200) {
        const chatData = await response.json();
        console.log("Success:", chatData);
        setConversations([...conversations, chatData]);
        handleClose(); // Close the dialog upon successful API call
      } else {
        // Handle different statuses or API errors
        if (response.status === 403) {
          alert("You are not authorized to create a new chat. Please contact Admin for help.");
        }
        console.error("HTTP Error:", response.statusText);
        alert("Failed to create chat: " + response.statusText);
      }
    } catch (error) {
      console.error("Network error:", error);
      alert("Network error: Could not connect to server");
    }
  };

  //Get Client User Info
  useEffect(() => {
    async function fetchData() {
      try {
        // Then use it in your fetch call:
        const response = await fetch(
          `${API_URL}/client/get-single-user?userId=${Cookies.get("userId")}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, []);

  const updateConversationMembers = (memberId, action) => {
    setConversations((prevConversations) =>
      prevConversations.map((conversation) => {
        if (conversation.conversationId === conversationId) {
          let updatedMembers;
          if (action === "add") {
            const newMember = employees.find((e) => e.userId === memberId);
            if (!newMember) {
              console.error("Failed to find the member in employees array.");
              return conversation;
            }
            // Ensure the structure matches your expected participantDTO structure
            updatedMembers = [
              ...conversation.participantDTOs,
              { userDTO: newMember },
            ];
          } else if (action === "remove") {
            updatedMembers = conversation.participantDTOs.filter(
              (member) => member.userDTO.userId !== memberId
            );
          }
          return {
            ...conversation,
            participantDTOs: updatedMembers,
          };
        }
        return conversation;
      })
    );
  };


  const handleOpenMembersDialog = async () => { 
    if(! await checkWriteAccess(ACCESS_CATEGORIES.INTERNAL_MESSAGES)){
      alert("You are not authorized to create a new chat. Please contact Admin for help.");
      return;
    }

    setShowMembersDialog(true);
  };

  

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/Employee/Get-All?businessId=${Cookies.get("businessId")}`,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
      setEmployees(response.data);
    } catch (error) {
      console.error("Failed to fetch employees:", error);
      setEmployees([]);
    }
  };


  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handlePagination = async () => {
    setBottom(false);
    setPage(page + 1);
    GetMessages(conversationId, page + 1);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const HandleSwitchConversation = (convId) => {
    if (convId !== conversationId) {
      setMessages([]);
      setPage(0);
      setConversationId(convId);
      // Check if there are notifications to clear for this conversation
      if (notificationCounts[convId] && notificationCounts[convId] > 0) {
        const newCounts = { ...notificationCounts, [convId]: 0 }; // Reset count for this conversation
        setNotificationCounts(newCounts); // Update the state to reflect this
      }
      GetMessages(convId, 0);
      findClientName();
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "" || selectedImage) {
      const messageDTO = {
        messageId: "",
        conversationId: conversationId,
        senderId: employee,
        messageText: newMessage,
        sentAt: new Date().toISOString(),
        isRead: false,
        sendersFirstName: user.firstName,
        sendersLastName: user.lastName,
      };

      //setSelectedImage(null);

      // check to see if employee is sending msg on the correct chat
      const specificConversation = conversations.find(
        (conversation) => conversation.conversationId === conversationId
      );

      if (!specificConversation) {
        return;
      }

      const userIds = specificConversation.participantDTOs.map(
        (participant) => participant.userDTO.userId
      );

      if (userIds.some((userId) => userId === employee)) {
        const sendResponse = await fetch(`${API_URL}/messages/send`, {
          method: "post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
          body: JSON.stringify(messageDTO),
        });

        if(sendResponse.ok && sendResponse.status === 200 ) {
          const sentMsgData = await sendResponse.json();

          setMessages([...messages, sentMsgData]);
          setNewMessage("");
          
          //todo: figure out if we need this to scroll to bottom since we also have a scroll to bottom method that we call later
          if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
          }
        } else {
          if (sendResponse.status === 403) {
            alert("You are not authorized to send team messages. Please contact Admin for help.");
          } else {
            alert("Failed to send the message.");
          }
          console.error("HTTP Error:", sendResponse.status);
        }
        
      } else {
        alert("You are not a member of this chat");
      }
    }
    scrollToBottom();
  };

  function findClientName() {
    if (conversations.length > 0) {
      // Find the conversation with conversationId
      const conversation = conversations.find(
        (conv) => conv.conversationId === conversationId
      );
      let name = " ";
      if (conversation && conversation.participantDTOs.length > 0) {
        const client = conversation.participantDTOs.find(
          (participant) => participant.userDTO.role === "client"
        );
        name = client
          ? `${client.userDTO.firstName} ${client.userDTO.lastName}`
          : " ";
        setClientName(name);
      }
    }
  }

  function msgUnion(newMsgList) {
    const combined = [...MessagesRef.current, ...newMsgList];
    const uniqueMsgMap = new Map();

    combined.forEach((msg) => uniqueMsgMap.set(msg.messageId, msg));

    return Array.from(uniqueMsgMap.values());
  }

  //gets all the messages for a given chat
  async function GetMessages(convId, page) {
    const msgResponse = await fetch(
      `${API_URL}/messages/getMessages?conversationId=${convId}&userId=${employee}&page=${page}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      }
    );

    // Check if the response status is 200-OK
    if (msgResponse.ok && msgResponse.status === 200) {
      const msgData = await msgResponse.json();

      const msgList = msgUnion(msgData.content);

      const sortedMessages = msgList.sort((a, b) => {
        return new Date(a.sentAt) - new Date(b.sentAt);
      });

      setMessages(sortedMessages);
    } else {
      if (msgResponse.status === 403) {
        alert("You are not authorized to read team chats. Please contact Admin for help.");
      } 
      console.error("HTTP Error:", msgResponse.status);
    }
  }

  // getting conversations, messages
  useEffect(() => {
    //todo: add api call to get projectOwner name

    async function fetchData() {
      // getting list of all conversations
      const allChatsResponse = await fetch(
        `${API_URL}/messages/AllInternalChats?employeeId=${employee}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        }
      );

      // check to see if we have chats for this employee
      if (allChatsResponse.ok && allChatsResponse.status === 200) {
        const chatsData = await allChatsResponse.json();

        if (chatsData.length > 0) {
          const sortedConvo = chatsData.sort((a, b) => {
            const dateA = a.lastMessageTimeStamp
              ? new Date(a.lastMessageTimeStamp)
              : new Date(0);
            const dateB = b.lastMessageTimeStamp
              ? new Date(b.lastMessageTimeStamp)
              : new Date(0);

            return dateB - dateA; // Descending order: newer dates first
          });

          setConversations(sortedConvo);
          setConversationId(sortedConvo[0].conversationId);

          // getting messages from a conversation
          const msgResponse = await fetch(
            `${API_URL}/messages/getMessages?conversationId=${chatsData[0].conversationId}&userId=${employee}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
              },
            }
          );
          if(msgResponse.ok && msgResponse.status === 200) {
            const msgData = await msgResponse.json();

            const sortedMessages = msgData.content.sort((a, b) => {
              return new Date(a.sentAt) - new Date(b.sentAt);
            });
            setMessages(sortedMessages);
            findClientName();

          } else {
            if (allChatsResponse.status === 403) {
              alert("You are not authorized to read team chats. Please contact Admin for help.");
            } 
          }
          
        }
      } else {
        if (allChatsResponse.status === 403) {
          alert("You are not authorized to read team chats. Please contact Admin for help.");
        } 
      }

    }

    fetchData();
  }, []);

  // Get Notification Info
  useEffect(() => {
    async function fetchNotificationData() {
      const response = await fetch(
        `${API_URL}/notification/get-notifications-for-user/${employee}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }

      const data = await response.json();
      const newCounts = {};

      data.forEach((notification) => {
        if (
          notification.eventType === "EVENT_TYPE_18" &&
          !notification.viewed
        ) {
          const { eventId } = notification; // eventId is the conversationId for these notifications
          newCounts[eventId] = (newCounts[eventId] || 0) + 1;
        }
      });

      setNotificationCounts(newCounts);
    }

    fetchNotificationData();
  }, [employee, API_URL]);

  const MessagesRef = useRef(messages);

  // Function to remove a member from a conversation
  const removeMemberFromConversation = async (employeeId, conversationId) => {
    try {
      // ?conversationId=${convId}&userId=${employee}&page=${page}
      const url = `${API_URL}/messages/removeEmployeeFromGroupChat?employeeId=${employeeId}&conversationId=${conversationId}&userId=${Cookies.get(
        "userId"
      )}`;
      // console.log(url);
      // url.searchParams.append("employeeId", employeeId);
      // url.searchParams.append("conversationId", conversationId);
      // url.searchParams.append("userId", Cookies.get("userId"));

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
        credentials: "include",
      });

      if (response.ok) {
        alert("Member removed successfully.");
        // Optionally update your local state or re-fetch data here
      } else {
        const errorData = await response.json(); // Handle non-OK responses
        if (response.status === 403) {
          alert("You are not authorized remove employee from group chat. Please contact Admin for help.");
        } else{
          alert("Failed to remove member: " + (errorData.message || "Unknown Error"));
        }
      }
    } catch (error) {
      console.error("Error removing member:", error);
      alert("Network error when trying to remove member.");
    }
  };

  const addMembersToConversation = async (employeeIds, conversationId) => {
    try {
      const url = `${API_URL}/messages/addEmployeesToGroupChat?conversationId=${conversationId}&businessId=${Cookies.get(
        "businessId"
      )}&userId=${Cookies.get("userId")}`;

      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
        body: JSON.stringify(employeeIds),
        credentials: "include",
      });

      if (response.ok) {
        // Ensure 'data' is defined by getting the JSON from the response
        const data = await response.json(); // Define 'data' here
        alert("Member(s) added successfully.");
        // Optionally update your local state or re-fetch data here
        // For example, you may want to update 'conversations' state to include new member details
      } else {
        
        const errorData = await response.json(); // Ensure you handle non-OK responses
        if (response.status === 403) {
          alert("You are not authorized add employee from group chat. Please contact Admin for help.");
        } else {
          alert("Failed to add member: " + (errorData.message || "Unknown Error"));
        }
       
      }
    } catch (error) {
      console.error("Error adding member:", error);
      alert("Network error when trying to add member.");
    }
  };

  useEffect(() => {
    MessagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    findClientName();
  }, [conversationId, conversations]);

  useInsertionEffect(() => {
    // Set an interval to fetch messages every 30 seconds
    const intervalId = setInterval(() => {
      GetMessages(conversationId, 0);
    }, 30000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [conversationId]);

  const scrollToBottom = () => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    if (bottom) {
      scrollToBottom();
    } else {
      setBottom(true);
    }
  }, [messages]);

  // // Filter users based on search term
  // const filteredUsers = users.filter((user) =>
  //   `${user.firstName} ${user.lastName}`
  //     .toLowerCase()
  //     .includes(searchTerm.toLowerCase())
  // );

  const filterConversationsByClientName = (conversations, searchTerm) => {
    const term = searchTerm.toLowerCase();
    return conversations.filter((conversation) =>
      conversation.participantDTOs.some((participant) =>
        `${participant.userDTO.firstName} ${participant.userDTO.lastName}`
          .toLowerCase()
          .includes(term)
      )
    );
  };

  const filterConversations = (conversations, searchTerm) => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return conversations.filter(
      (conversation) =>
        conversation.conversationName.toLowerCase().includes(lowerCaseTerm) ||
        conversation.participantDTOs.some((participant) =>
          `${participant.userDTO.firstName} ${participant.userDTO.lastName}`
            .toLowerCase()
            .includes(lowerCaseTerm)
        )
    );
  };

  const filteredConversations = filterConversations(conversations, searchTerm);

  function formatDateToNearestMinute(dateString) {
    let date = new Date(dateString);

    // Check if seconds are 30 or more to round up
    if (date.getSeconds() >= 30) {
      date = new Date(date.getTime() + (60 - date.getSeconds()) * 1000); // Add the necessary milliseconds to round up
    } else {
      date.setSeconds(0); // Just remove seconds if less than 30
      date.setMilliseconds(0); // Clean up milliseconds
    }

    // Locale string without seconds
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  // Handle case where conversation data is not yet loaded
  if (conversations === null) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      ></Box>
      {/* Search Input */}

      {/* {!isMobile && ( */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // marginRight="25px"
      >
        <TextField
          label="Search Messages"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ m: 1, width: "300px" }}
        />
        {/* <Typography variant="h4" fontWeight="600" mb="20px">
            Messages from {conversationName}
          </Typography> */}
        <React.Fragment>
          <Button variant="outlined" onClick={handleOpen} color="primary">
            <Box display="flex" alignItems="center">
              <AddIcon sx={{ mr: 1 }} />
              <Typography variant="h6">New Conversation</Typography>
            </Box>
          </Button>
          <ConversationDialogBox
            open={open}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            employees={employees}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            conversationName={conversationName}
            setConversationName={setConversationName}
          />
        </React.Fragment>
      </Box>

      <Grid container spacing={3}>
        {/* <Box display="flex" gap="10px" marginRight="25px"> */}
        {/* left box displays list of conversations */}
        <Grid item xs={12} md={2}>
          <Box
            sx={{
              p: "5px",
              borderRadius: "8px",
              backgroundColor: colors.primary[400],
              display: "flex",
              flexDirection: "column",
              columnGap: "5px",
              rowGap: "5px",
              height: isMobile ? "300px" : "68vh",
              minHeight: "300px",
              overflowY: "auto",
            }}
          >
            <List>
              {conversations.length > 0 ? (
                filteredConversations.map((conversation) => (
                  <ListItem
                    key={conversation.conversationId}
                    button
                    selected={conversation.conversationId === conversationId}
                    onClick={() =>
                      HandleSwitchConversation(conversation.conversationId)
                    }
                    sx={{
                      "&:hover": {
                        backgroundColor: colors.hoverColor, // Your hover color
                      },
                      backgroundColor:
                        conversation.conversationId === conversationId
                          ? colors.selectedColor // Your selected color
                          : "inherit",
                    }}
                  >
                    <ListItemText
                      primary={
                        conversation.conversationName
                        // conversation.participantDTOs[0].userDTO.role ===
                        // "client"
                        //   ? `${conversation.participantDTOs[0].userDTO.firstName}  ${conversation.participantDTOs[0].userDTO.lastName}`
                        //   : `${conversation.participantDTOs[0].userDTO.firstName}  ${conversation.participantDTOs[0].userDTO.lastName}`
                      }
                    />
                    {notificationCounts[conversation.conversationId] > 0 && (
                      <Box
                        component="span"
                        sx={{
                          display: "inline-block",
                          ml: 1,
                          width: 10,
                          height: 10,
                          bgcolor: "red",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </ListItem>
                ))
              ) : (
                <Typography variant="body1">No chats available.</Typography>
              )}
            </List>
          </Box>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="600" mb="0px">
              {conversationName}
            </Typography>
          </Grid>
        )}

        {/* Right box displays all message of given conversation */}
        <Grid item xs={12} md={10}>
          <Box
            sx={{
              position: "relative", // Make sure your box is relative if the icon is absolutely positioned
            }}
          >
            <IconButton
              onClick={() => handleOpenMembersDialog()}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                zIndex: 5,
                backgroundColor: "white",
              }}
            >
              <GroupIcon />
            </IconButton>
            <Box
              sx={{
                p: "20px",
                borderRadius: "8px",
                backgroundColor: colors.primary[400],
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: isMobile ? "300px" : "68vh",
                minHeight: "300px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                color="#3366CC"
                onClick={handlePagination}
              >
                click to load older messages.
              </Typography>
              {messages.length > 0 ? (
                messages.map((message) => (
                  <Box
                    key={message.messageId}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        message.senderId === employee
                          ? "flex-end"
                          : "flex-start",
                      maxWidth: "80%",
                      mb: 2,
                      marginLeft: message.senderId === employee ? "20%" : "0",
                    }}
                  >
                    <Typography
                      sx={{
                        mb: 1,
                        fontSize: "0.875rem",
                        color:
                          message.senderId === employee
                            ? colors.primary[700]
                            : colors.blueAccent[700],
                      }}
                    >
                      {message.sendersFirstName} {message.sendersLastName}
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor:
                          message.senderId === employee
                            ? colors.primary[300]
                            : colors.blueAccent[300],
                        borderRadius: "8px",
                        padding: "10px",
                        maxWidth: "100%",
                        wordWrap: "break-word",
                      }}
                    >
                      <Typography variant="body1" sx={{ color: "white" }}>
                        {message.messageText}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "white", fontSize: "0.75rem", mt: 1 }}
                      >
                        {new Date(message.sentAt).toLocaleString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </Typography>
                    </Box>
                    <div ref={messagesEndRef} />
                  </Box>
                ))
              ) : (
                <Typography variant="body1">No messages available.</Typography>
              )}
            </Box>
            <MemberDialog
              open={showMembersDialog}
              onClose={() => setShowMembersDialog(false)}
              members={members
                .filter((participant) => participant.userDTO)
                .map((participant) => ({
                  firstName: participant.userDTO.firstName,
                  lastName: participant.userDTO.lastName,
                  role: participant.userDTO.role,
                  userId: participant.userDTO.userId,
                }))}
              conversationId={conversationId}
              employees={employees}
              addMemberToConversation={addMembersToConversation}
              removeMemberFromConversation={removeMemberFromConversation}
              updateConversationMembers={updateConversationMembers}
            />
          </Box>
        </Grid>
        {/* </Box> */}
      </Grid>

      <Box display="flex" gap="10px" mt="20px" marginRight="25px">
        <TextField
          value={newMessage}
          onChange={handleNewMessageChange}
          label="New Message"
          variant="outlined"
          focused
          fullWidth
          multiline
          maxRows={4}
          inputProps={{
            style: {
              overflow: "auto",
              fontSize: "16px",
            },
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
              e.preventDefault();
            }
          }}
        />
        {/*
        <label htmlFor="image-upload">
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <IconButton
            color="primary"
            component="span"
            style={{ color: colors.blueAccent[500] }}
          >
            <PhotoCameraIcon />
          </IconButton>
        </label>
        */}
        <IconButton
          color="primary"
          onClick={handleSendMessage}
          style={{ color: colors.blueAccent[500] }}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default InternalMessages;
