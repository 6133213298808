import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  CardActions,
  Button,
  Box,
  Modal,
  TextField,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Menu,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Cookies from "js-cookie";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
//import { FaEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import SelectionPopupForm from "./SelectionForm";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";

const API_URL = process.env.REACT_APP_API_URL;
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};
const SelectionsComponent = ({ data, forRequests, onReload, isConfirmed }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalCard, setModalCard] = useState({});
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const [openAcceptConfirmModal, setOpenAcceptConfirmModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [isAccordionExpanded, setAccordionExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [moreEditData, setMoreEditData] = useState([]);
  const [openAccordionSections, setOpenAccordionSections] = useState({});

  const { projectId } = useParams();

  const handleAccordionToggle = (index) => {
    console.log(index);
    setAccordionExpanded(!isAccordionExpanded);
    setOpenAccordionSections((prevOpenSections) => ({
      ...prevOpenSections,
      [index]: !prevOpenSections[index],
    }));
  };

  const handleOpenForm = async (selectionData) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.SELECTIONS)){
      alert("You are not authorized to modify selections. Please contact Admin for help.");
      return;
    }

    handleMoreVertClose();
    setIsFormOpen(true);
    console.log(selectionData);
    setEditData(moreEditData);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setEditData(null);
    setMoreEditData([]);
    onReload();
  };

  const handleCardSelect = (id) => {
    console.log(id);
    setSelectedCard(selectedCard === id ? null : id);
  };
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const formatDate = (dateString) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: userTimezone,
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getImage = (fileId) => {
    const url = `${process.env.REACT_APP_API_URL}/${fileId}`;
    return url;
  };

  const handleImageError = (event) => {
    event.target.src = "https://placehold.co/600x400?text=No+Image";
  };

  const handleOpenCardModal = (card) => {
    setModalCard(card);
    setCardModalOpen(true);
  };

  const handleCloseCardModal = () => {
    setCardModalOpen(false);
  };

  const handleReject = async (event, categoryId, selectionId) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const body = {
      comment: formData.get("comments"),
      selectionCategoryId: categoryId,
      selectionId: selectionId,
    };

    try {
      const response = await axios.put(`${API_URL}/selections/reject`, body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
        params: {
          projectId: projectId,
        },
      });
      if (response.status === 200 || response.status === 201) {
        console.log("saved reject");
        onReload();
      }
    } catch (error) {
      console.log(error);
    }
    handleModalClose();
  };

  const handleAccept = async (categoryId, selectionId) => {
    try {
      const response = await axios.put(
        `${API_URL}/selections/confirm`,
        {},
        {
          withCredentials: true,
          headers: {
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
          params: {
            selectionCategoryId: categoryId,
            selectionId: selectionId,
            projectId: projectId,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        console.log("saved confirm");
        onReload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditData(null);
    setMoreEditData([]);
    handleReload();
  };

  const handleDeleteModalOpen = async(category) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.SELECTIONS)){
      alert("You are not authorized to create or modify internal notes. Please contact Admin for help.");
      return;
    }

    handleMoreVertClose();
    setDeleteCategory(moreEditData);
    setDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const categoryId = deleteCategory.id;
      const res = await axios.delete(`${API_URL}/selections/delete-all`, {
        withCredentials: true,
        headers: {
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
        params: {
          selectionCategoryId: categoryId,
          projectId: projectId,
        },
      });
      if (res.status === 200 || res.status === 201) {
        console.log("Deleted the selection");
        handleConfirmModalClose();
        handleDeleteModalClose();
        onReload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmModalOpen = async (modalName, selection) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.SELECTIONS)){
      alert("You are not authorized to approve or reject selections. Please contact Admin for help.");
      return;
    }

    console.log("selection", selection);
    setOpenAcceptConfirmModal(true);
    setSelectedOption(selection);
    if (modalName === "accept") setModalText("accept");
    else if (modalName === "reject") setModalText("reject");
    else if (modalName === "delete") setModalText("delete");
    setModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setOpenAcceptConfirmModal(false);
    setSelectedOption(null);
    setMoreEditData([]);
    setModalOpen(false);
  };

  const handleConfirmModalSubmit = async (event) => {
    const selectionCategoryId = selectedOption.id;
    const selectionId = selectedOption.selectionResponseDtoList.filter(
      (selection) => selection.selectionStatus === "NONE"
    )[0].id;
    console.log("ids", selectionCategoryId, selectionId);
    if (modalText === "accept")
      await handleAccept(selectionCategoryId, selectionId);
    else if (modalText === "reject")
      await handleReject(event, selectionCategoryId, selectionId);
    else if (modalText === "delete") console.log("delete");
  };

  const handleReload = async () => {
    console.log("reload");
    setIsFormOpen(false);
    setEditData(null);
    setMoreEditData([]);
    await onReload();
  };

  const handleMoreVertClick = (event, selection) => {
    console.log(selection);
    setMoreEditData(selection);
    if (selection.status === "WAIT_CLIENT_SELECTION") {
      setShowEdit(true);
    } else if (
      selection.status === "WAIT_CONTRACTOR_SELECTION" &&
      selection.selectionResponseDtoList.length === 0
    ) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMoreVertClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={2}>
      {data.map((category, index) => (
        <Grid
          item
          sm={12}
          key={category.id}
          sx={{
            //backgroundColor: "rgba(238, 238, 238, 0.803921568627451)",
            //backgroundColor: "#ffffff",
            boxShadow: 3,
            boarderRadius: 1,
            position: "relative",
            "@media screen and (max-width: 600px)": {
              width: "100%",
              paddingRight: "16px",
            },
          }}
          mb={3}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={{ xs: "row" }}
            alignItems="flex-start"
          >
            <Box
              display={"flex"}
              //justifyContent={"space-between"}
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems="flex-start"
              flexGrow={1}
            >
              <Box display={"flex"} flexDirection="column" flexGrow={1}>
                <Typography variant="h5" gutterBottom>
                  <strong>{category.title}</strong>
                </Typography>
                {category.room != "" ? (
                  <Typography variant="h5" gutterBottom>
                    <strong>Room: </strong>
                    {category.room}
                  </Typography>
                ) : null}
              </Box>

              <Box
                display="flex"
                alignItems={{ xs: "flex-start", sm: "flex-end" }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    marginRight: "1rem",
                  }}
                >
                  <strong>Due Date: </strong>
                  {category.dueDate}
                </Typography>
              </Box>
            </Box>
            {category.status === "WAIT_CLIENT_SELECTION" ||
            category.status === "WAIT_CONTRACTOR_SELECTION" ? (
              <>
                <MoreVertIcon
                  fontSize={"20px"}
                  style={{
                    marginLeft: "5px",
                    marginRight: "8px",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleMoreVertClick(e, category)}
                />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMoreVertClose}
                >
                  {showEdit && (
                    <MenuItem onClick={(e) => handleOpenForm(category)}>
                      <EditIcon
                        fontSize={"20px"}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      />{" "}
                      Edit
                    </MenuItem>
                  )}
                  <MenuItem onClick={(e) => handleDeleteModalOpen(category)}>
                    <DeleteIcon
                      style={{ cursor: "pointer", marginRight: "10px" }}
                    />{" "}
                    Delete
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </Box>
          <Grid
            container
            spacing={2}
            pb={2}
            justifyContent={
              category.status !== "WAIT_CLIENT_SELECTION" ? "space-between" : ""
            }
          >
            {category.status === "WAIT_CONTRACTOR_SELECTION" && (
              <Grid item xs={12} sm={6} md={4} lg={2.2} key={category.id}>
                <Card
                  sx={{
                    borderColor:
                      selectedCard === category.id
                        ? "primary.main"
                        : "grey.300",
                    ":hover": {
                      borderColor: "primary.main",
                      cursor: "pointer",
                      boxShadow: 5,
                    },
                  }}
                  onClick={() => handleCardSelect(category.id)}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    padding={1}
                    pl={2}
                  >
                    {"Client will request a selection"}
                  </Typography>
                  <CardMedia
                    sx={{ pr: 2, pl: 2, maxWidth: "300px", objectFit: "cover" }}
                    component="img"
                    height="140"
                    width="200"
                    image={"https://placehold.co/600x400?text=X"}
                  />
                  <CardContent>
                    <Box
                      display={"flex"}
                      justifyContent={"space-evenly"}
                      pb={1}
                    ></Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {category.selectionResponseDtoList &&
              category.selectionResponseDtoList
                .filter((f) => f.selectionStatus !== "REJECTED")
                .map((selection) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.2}
                      key={selection.id}
                    >
                      <Card
                        sx={{
                          borderColor:
                            selectedCard === selection.id
                              ? "primary.main"
                              : "grey.300",
                          ":hover": {
                            borderColor: "primary.main",
                            cursor: "pointer",
                            boxShadow: 6,
                          },
                          //height: "100%",
                          boxShadow: 4,
                          height: "270px",
                        }}
                        onClick={() => {
                          handleCardSelect(selection.id);
                          handleOpenCardModal(selection);
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          padding={1}
                          pl={2}
                        >
                          {selection.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center", // Center the image
                            alignItems: "center", // Vertically center
                            height: "140px", // Fixed height for image container
                            width: "auto", // Fixed width for image container
                            //maxHeight:"",
                            maxWidth: "300",
                            overflow: "hidden", // Hide overflow
                            margin: "auto", // Center the box in the Card
                            //border: 0.01,
                            //boarderColor: "#f3f3f3",
                            borderRadius: 1,
                            //px: 2,
                          }}
                        >
                          <CardMedia
                            component="img"
                            sx={{
                              px: 1,
                              height: "auto", // Auto height for aspect ratio
                              width: "auto", // Auto width for aspect ratio
                              maxHeight: "140px", // Max height for image
                              maxWidth: "200px", // Max width for image
                              objectFit: "contain", // Contain to maintain aspect ratio without cropping
                            }}
                            image={
                              getImage(selection.fileUrl) ||
                              "https://placehold.co/600x400?text=No+Image"
                            }
                            alt={selection.name}
                            onError={handleImageError}
                          />
                        </Box>
                        <CardContent>
                          <Box
                            display={"flex"}
                            justifyContent={"space-evenly"}
                            pb={1}
                          >
                            <Typography variant="body2" color="text.secondary">
                              {selection.costPrice &&
                                `$${selection.costPrice.toFixed(2)}`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {selection.retailPrice &&
                                `$${selection.retailPrice.toFixed(2)}`}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="body2"
                              color="text.secondary"
                            >
                              {selection.partNumber}
                            </Typography>
                          </Box>
                          <Typography
                            textAlign={"left"}
                            variant="body1"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {selection.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                ))}
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              margin={"16px 16px 0 0"}
              sx={{
                "@media screen and (max-width: 600px)": {
                  marginLeft: "16px",
                },
                alignSelf:
                  forRequests && category.status === "WAIT_CONTRACTOR_APPROVAL"
                    ? ""
                    : "end",
              }}
            >
              {forRequests && (
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={2}
                  sx={{
                    flexDirection: "column-reverse",
                    gap: "25px",
                    "@media screen and (max-width: 600px)": {
                      flexDirection: "row-reverse",
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleConfirmModalOpen("reject", category)}
                    size="large"
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#e53935",
                      "&:hover": { backgroundColor: "#e53935" },
                      padding: { xs: "7px 20px", sm: "7px 50px" }, // Responsive padding
                      width: "125px", // Allow width to adjust based on content
                      maxWidth: "100%", // Prevent overflow
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => handleConfirmModalOpen("accept", category)}
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#8bc34a",
                      "&:hover": { backgroundColor: "#8bc34a" },

                      padding: { xs: "7px 20px", sm: "7px 50px" }, // Responsive padding
                      width: "125px", // Allow width to adjust based on content
                      maxWidth: "100%", // Prevent overflow
                    }}
                  >
                    Accept
                  </Button>
                </Box>
              )}
              {!isConfirmed &&
                category.selectionResponseDtoList.find(
                  (f) => f.selectionStatus === "REJECTED"
                ) && (
                  <Button
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#ffffff",
                      color: "#0b1537",

                      border: 0.5,
                      alignSelf: "end",

                      "@media screen and (max-width: 600px)": {
                        marginTop: "10px",
                        padding: { xs: "7px 20px", sm: "7px 50px" }, // Responsive padding
                        width: "150px", // Allow width to adjust based on content
                        maxWidth: "100%", // Prevent overflow
                      },
                    }}
                    endIcon={<ArrowDownwardIcon />}
                    onClick={() => handleAccordionToggle(index)}
                  >
                    View Rejected
                  </Button>
                )}
              {isConfirmed && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "end",
                    }}
                  >
                    <strong>Confirmed on: </strong>
                    {formatDate(category.confirmedOn)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>

          {!isConfirmed &&
            category.selectionResponseDtoList.find(
              (f) => f.selectionStatus === "REJECTED"
            ) && (
              <Box width={"100%"}>
                {openAccordionSections[index] && (
                  <Accordion
                    expanded={isAccordionExpanded}
                    sx={{ boxShadow: "unset" }}
                  >
                    <AccordionSummary
                      aria-controls="panel1-content"
                      sx={{ display: "none" }}
                    ></AccordionSummary>

                    <AccordionDetails
                      sx={{
                        backgroundColor:
                          "rgba(238, 238, 238, 0.803921568627451)",
                        padding: "8px 16px 16px 0",
                      }}
                    >
                      {category.selectionResponseDtoList.length > 0 &&
                        category.selectionResponseDtoList
                          .filter(
                            (selection) =>
                              selection.selectionStatus === "REJECTED"
                          )
                          .map((selection, i) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={4}
                                key={selection.id}
                              >
                                <Box
                                  display={"flex"}
                                  gap={1}
                                  width={"100%"}
                                  maxWidth={"100%"}
                                  justifyContent={"space-around"}
                                  sx={{
                                    marginBottom: "1rem",
                                    "@media screen and (max-width: 600px)": {
                                      flexDirection: "column",
                                    },
                                  }}
                                >
                                  <Card
                                    sx={{
                                      borderColor:
                                        selectedCard === selection.id
                                          ? "primary.main"
                                          : "grey.300",
                                      ":hover": {
                                        borderColor: "primary.main",
                                        cursor: "pointer",
                                        boxShadow: 5,
                                      },
                                      flex: 1,
                                    }}
                                    onClick={() =>
                                      handleOpenCardModal(selection)
                                    }
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      component="div"
                                      padding={1}
                                      pl={2}
                                    >
                                      {selection.name}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center", // Center the image
                                        alignItems: "center", // Vertically center
                                        height: "140px", // Fixed height for image container
                                        width: "auto", // Fixed width for image container
                                        //maxHeight:"",
                                        maxWidth: "300",
                                        overflow: "hidden", // Hide overflow
                                        margin: "auto", // Center the box in the Card
                                        //border: 0.01,
                                        //boarderColor: "#f3f3f3",
                                        borderRadius: 1,
                                        //px: 2,
                                      }}
                                    >
                                      <CardMedia
                                        component="img"
                                        sx={{
                                          px: 1,
                                          height: "auto", // Auto height for aspect ratio
                                          width: "auto", // Auto width for aspect ratio
                                          maxHeight: "140px", // Max height for image
                                          maxWidth: "200px", // Max width for image
                                          objectFit: "contain", // Contain to maintain aspect ratio without cropping
                                        }}
                                        image={
                                          getImage(selection.fileUrl) ||
                                          "https://placehold.co/600x400?text=No+Image"
                                        }
                                        alt={selection.name}
                                        onError={handleImageError}
                                      />
                                    </Box>
                                    <CardContent>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"space-evenly"}
                                        pb={1}
                                      >
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          {selection.costPrice &&
                                            `$${selection.costPrice.toFixed(
                                              2
                                            )}`}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          {selection.retailPrice &&
                                            `$${selection.retailPrice.toFixed(
                                              2
                                            )}`}
                                        </Typography>
                                        <Typography
                                          gutterBottom
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          {selection.partNumber}
                                        </Typography>
                                      </Box>
                                      <Typography
                                        textAlign={"center"}
                                        variant="body1"
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          "@media screen and (max-width: 600px)":
                                            {
                                              width: "20rem",
                                            },
                                        }}
                                      >
                                        {selection.description}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                  <Box>
                                    <Typography
                                      color={"red"}
                                      variant="h5"
                                      marginBottom={"10px"}
                                    >
                                      Reason for rejection
                                    </Typography>
                                    <textarea
                                      style={{ height: "5rem", resize: "none" }}
                                      value={selection.comment}
                                      disabled
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            )}
          <Modal
            open={isModalOpen}
            onClose={handleConfirmModalClose}
            aria-labelledby="add-selection-form"
          >
            <Box
              data-id={category.id}
              sx={modalStyle}
              component="form"
              onSubmit={handleConfirmModalSubmit}
            >
              <Typography
                id="add-selection-form"
                variant="h4"
                component="h2"
                textAlign={"center"}
              >
                Are you sure you want to{" "}
                {modalText === "accept"
                  ? "accept"
                  : modalText === "reject"
                  ? "reject"
                  : "delete"}{" "}
                this selection ?
              </Typography>
              {modalText === "reject" && (
                <Box>
                  <label htmlFor="comments">Add reason:</label>
                  <textarea
                    id="comments"
                    name="comments"
                    rows="6"
                    cols="40"
                    style={{
                      resize: "none",
                      "@media screen and (max-width: 600px)": {
                        width: "100%",
                      },
                    }}
                  />
                </Box>
              )}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                gap={3}
                mt={2}
              >
                <Button
                  onClick={handleConfirmModalClose}
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "rgba(30, 152, 215, 1)",
                    "&:hover": { backgroundColor: "rgba(30, 152, 215, 1)" },
                    padding: "7px 50px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "rgba(30, 152, 215, 1)",
                    "&:hover": { backgroundColor: "rgba(30, 152, 215, 1)" },
                    padding: "7px 50px",
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Modal>
        </Grid>
      ))}
      <SelectionPopupForm
        isOpen={isFormOpen}
        setOpen={handleCloseForm}
        editData={editData}
        onReload={handleReload}
      />
      {/* Delete Modal */}
      <Dialog
        open={deleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          fontSize={"18px"}
          fontWeight={"500"}
        >
          Are you sure you want to delete this selection?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDeleteModalClose}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Modal  */}
      <Dialog open={cardModalOpen} onClose={handleCloseCardModal}>
        <DialogTitle sx={{ position: "relative" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseCardModal}
            sx={{ position: "absolute", top: 8, right: 15 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: "hidden", width: "100%" }}>
          <Box>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              padding={1}
              pl={2}
            >
              {modalCard.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Center the image
                alignItems: "center", // Vertically center
                height: "350px", // Fixed height for image container
                width: "auto", // Fixed width for image container
                //maxHeight:"",
                maxWidth: "400px",
                overflow: "hidden", // Hide overflow
                margin: "auto", // Center the box in the Card
                //border: 0.01,
                //boarderColor: "#f3f3f3",
                borderRadius: 1,
                //px: 2,
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  px: 1,
                  height: "auto", // Auto height for aspect ratio
                  width: "auto", // Auto width for aspect ratio
                  maxHeight: "350px", // Max height for image
                  maxWidth: "400px", // Max width for image
                  objectFit: "contain", // Contain to maintain aspect ratio without cropping
                }}
                image={
                  getImage(modalCard.fileUrl) ||
                  "https://placehold.co/600x400?text=No+Image"
                }
                alt={modalCard.name}
                onError={handleImageError}
              />
            </Box>
            <CardContent>
              <Box display={"flex"} justifyContent={"space-evenly"} pb={1}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "20px" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                      Cost Price
                    </Typography>
                    {modalCard.costPrice &&
                      `$${modalCard.costPrice.toFixed(2)}`}
                  </Box>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "20px" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                      Retail Cost
                    </Typography>
                    {modalCard.retailPrice &&
                      `$${modalCard.retailPrice.toFixed(2)}`}
                  </Box>
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "20px" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                      Part #
                    </Typography>
                    {modalCard.partNumber}
                  </Box>
                </Typography>
              </Box>
              <Box sx={{ maxHeight: 120, overflowY: 'auto', width: '100%' }}>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{ fontSize: "20px", width: "100%", wordWrap: "break-word" }}
              >
                {modalCard.description}
              </Typography>
              </Box>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{
                  fontSize: "20px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <a
                  href={modalCard.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {modalCard.url}
                </a>
              </Typography>
              {modalCard.comment && (
                <TextareaAutosize
                  textAlign={"center"}
                  variant="body1"
                  disabled
                  value={modalCard.comment}
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    height: "5rem",
                    fontSize: "13px",
                    resize: "none",
                  }}
                />
              )}
            </CardContent>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default SelectionsComponent;
