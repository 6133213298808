import { Box, Typography, IconButton, useTheme, Grid } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { useParams } from "react-router-dom";
import { mockTodoData } from "../../data/mockTodoData";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import axios from "axios"; // Step 1: Import axios
import Cookies from "js-cookie";

const ToDoPage = () => {
  // Extract projectId from the URL parameters
  const { projectId } = useParams();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Initial state for the to-do lists
  const [todoLists, setTodoLists] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;

  //Get any todo lists for specific project

  const fetchTODOData = async () => {
    // Fetch the to-do lists for the given client user ID
    try {
      const response = await fetch(
        `${API_URL}/client-todo-list/user-lists?projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      let updatedTodoLists = [];
      const data = await response.json();

      if (Array.isArray(data)) {
        updatedTodoLists = data.map((list) => ({
          ...list,
          editMode: false, // Add an editMode property to each list
          isLive: true, // Add an isLive property to each list
        }));
      }
      setTodoLists(updatedTodoLists); // If data is not an array, this will set an empty array
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation:",
        error.message
      );
    }
  };

  useEffect(() => {
    fetchTODOData();
  }, [projectId]);

  const handleToggleTask = async (listId, taskId) => {
    // Find the task first
    const list = todoLists.find((list) => list.toDoListId === listId);
    const task = list?.taskList.find((t) => t.taskId === taskId);

    if (!list || !task) {
      console.error("List or task not found");
      return;
    }

    if (task) {
      try {
        const response = await fetch(
          `${API_URL}/client-todo-list/confirm-task/${projectId}/${taskId}`,
          {
            method: "PUT",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },
          }
        );

        if (response.ok) {
          // Use a functional update to ensure we're working with the latest state
          setTodoLists((currentLists) => {
            return currentLists.map((list) => {
              if (list.toDoListId === listId) {
                return {
                  ...list,
                  tasks: list.taskList.map((t) =>
                    t.taskId === taskId ? { ...t, status: !t.status } : t
                  ),
                };
              }
              return list;
            });
          });
          fetchTODOData();
        } else {
          const errorDetails = await response.text();
          console.error("Failed to confirm task", errorDetails);
        }
      } catch (error) {
        console.error("Error confirming task:", error);
      }
    }
  };

  return (
    <Box m="20px">
      <Typography variant="h4" fontWeight="600" mb="20px">
        To-Do Lists
      </Typography>

      <Grid container spacing={2}>
        {todoLists.map((list) => (
          <Grid item xs={12} sm={6} md={4} key={list.toDoListId}>
            <Box p="20px" backgroundColor={colors.primary[400]} borderRadius={1}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="15px"
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" fontWeight="600" ml="10px">
                    {list.listName}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" gap="10px">
                {list.taskList.map((task) => (
                  <Box key={task.taskId} display="flex" alignItems={task.taskDescription.length > 50 ? "flex-start": "center"}>
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleToggleTask(list.toDoListId, task.taskId)
                      }
                    >
                      {task.status ? (
                        <CheckCircleIcon
                          sx={{ color: colors.MoonGreen[100] }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </IconButton>
                    <Typography variant="body1" width="70%">
                      {task.taskDescription}
                    </Typography>
                    <Typography
                      variant="body2"
                      ml="auto"
                    >
                      {task.taskDueDate}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ToDoPage;
