import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Cookies from "js-cookie";
import InputAdornment from '@mui/material/InputAdornment'; 
import IconButton from '@mui/material/IconButton';         
import Visibility from '@mui/icons-material/Visibility';  
import VisibilityOff from '@mui/icons-material/VisibilityOff'; 

function Reset() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const API_URL = process.env.REACT_APP_API_URL;

    const handleChangePassword = async (e) => {
        e.preventDefault();

        // Check if new password and confirm password match
        if (newPassword !== confirmPassword) {
            setMessage("New passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/password/reset`, 
                {
                    currentPassword,
                    newPassword
                },
                {
                    withCredentials: true,
                    headers: {
                      "Content-Type": "application/json",
                      'X-XSRF-TOKEN': Cookies.get("XSRF-TOKEN")
                    },
                }
            );
            setMessage(response.data);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
        } catch (error) {
            setMessage(error.response.data);
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '30ch' },
                mt:2,
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleChangePassword}
        >
            <Typography variant="h4" mb={2}>Change Password</Typography>
            <div>
                <TextField
                    label="Current Password"
                    type={showPassword ? 'text' : 'password'}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    sx={{ input: { fontSize: '16px' } }}
                    variant="outlined"
                />
            </div>
            <div>
                <TextField
                    label="New Password"
                    type={showPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    sx={{ input: { fontSize: '16px' } }}
                    variant="outlined"
                />
            </div>
            <div>
                <TextField
                    label="Confirm New Password"
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    variant="outlined"
                    sx={{ input: { fontSize: '16px' } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleTogglePasswordVisibility}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Button type="submit" variant="contained" color="primary">
                Change Password
            </Button>
            {message && <Typography color="textSecondary">{message}</Typography>}
        </Box>
    );
}

export default Reset;